.registration-page{
    min-height: 100vh;
    display: flex;
    background-color: var(--primary-gray);
    flex-direction: column;
}

.registration-content-container{
    padding: 60px 0px 90px;
    background-color: var(--primary-gray);
}

.registration-content{
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
}

.registration-form-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.registration-form-container-title{
    font-size: 24px;
    color: var(--primary-lightgreen);
    text-align: center;
    font-weight: 700;
    margin-bottom: 5px;
    max-width:  var(--desktop-col-6);
    word-break :keep-all;

}

.already-a-customer{
    text-align: center;
    font-size: 15px;
    font-family: Roboto,sans-serif;
    color: var(--questionnaire-select-placeholder);
    margin-bottom: 40px;
}


.already-a-customer > .login-here{
    color: var(--primary-lightgreen);
    cursor: pointer;
    font-family: Roboto,sans-serif;
    margin-left: 5px;
    text-decoration: none;
}

.already-a-customer > .login-here:hover{
    text-decoration: underline;
}

@media (max-width:1200px){
    .registration-content{
        padding: var(--mobile-container-padding);
    }
}

@media (max-width: 764px) {
    .registration-form-container-title{
       font-size: 18px;
       max-width: 100%;
    }

    .registration-content .sumbitButton-container svg{
        display: none !important;
    }

}