.modals-wrapper-background {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: fixed;
    z-index: 400;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: var(--tablet-container-padding);
}

@media (max-width:764px){
    .modals-wrapper-background{
        padding: 0px 10px !important;
    }
}