.renew-questionnaire-container{
    padding: 30px 30px 30px;
    overflow-y: auto;
}

.renew-questionnaire-container.height-limit{
    max-height: 90vh;
}

.renew-questionnaire-container::-webkit-scrollbar{
    width: 6px;
    height: 3px;
}

.renew-questionnaire-container::-webkit-scrollbar-thumb {
    background-color: var(--primary-lightgreen);
    border-radius: 10px;
  }

.renew-questionnaire-message{
    padding: 30px;
    background-color: var(--primary-blue);
    border: 1px solid #dbe1eb;
    border-radius: 6px;
    color: white;
    text-align: center;
}

.change-medication{
    padding: 10px;
    background-color: white;
    color: var(--primary-blue);
    text-align: center;
    border: none;
}

.renew-questionnaire-message p,.renew-questionnaire-message strong {
    margin-bottom: 16px;
    font-size: 14px;
    font-family: Roboto,sans-serif !important;
}

.renew-questionnaire-message p:first-of-type strong{
    font-size: 22px ;
    font-family: Roboto,sans-serif !important;
}



.renew-questionnaire-message .buttons-container{
    display: flex;
    flex-direction: column;
    
}

.renew-questionnaire-message .buttons-container > div {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 15px;
    text-align: center;
    position: relative;
}

.renew-questionnaire-button{
    text-align: center;
    font-size: 14px;
    border-radius: 3px;
    color: var(--primary-lightgreen);
    padding: 10px 40px;
    font-weight: 600;
    border: 2px solid var(--primary-lightgreen);
    margin-bottom: 15px;
}

.renew-questionnaire-button.const-width{
    width: 120px;
    margin-bottom: 0px;
}

.buttons-inner-container{
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: 20px !important;
    margin-bottom: 15px;
}


.renew-questionnaire-button:hover{
    background-color: var(--primary-lightgreen);
    color: white;
    transition: 350ms ease;
    cursor: pointer;
}

.renew-questionnaire-show-last-questionnaire{
    width: fit-content;
    align-self: center;
    font-size: 14px;
    color: var(--questionnaire-select-placeholder);
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 0 !important;
}

.renew-questionnaire-show-last-questionnaire:hover{
    text-decoration: none;
}

.renew-questionnaire-subscription-prescription{
    margin-top: 20px;
}

.renew-questionnaire-subscription-prescription .questionnaire-info-wrap::after,
.renew-questionnaire-subscription-prescription .questionnaire-info-wrap::before
{
    background-color: transparent !important;
}



/* OVERRIDE QUESTIONNAIRE PAGE STYLING */

.renew-questionnaire-container .emojiblock-container,
.renew-questionnaire-container .emojiblock-title,
.renew-questionnaire-container .emojiblock-title,
.renew-questionnaire-container .header-container,
.renew-questionnaire-container .header-links,
.renew-questionnaire-container .stepslist-container,
.renew-questionnaire-container .stepslist-container-mobile,
.renew-questionnaire-container .stepslist-progress-bar,
.renew-questionnaire-container .questionnaire-title,
.renew-questionnaire-container .footer-container,
.renew-questionnaire-container .emojiblock-discount-notification{
    display: none;
}

.renew-questionnaire-container .questionnaire-questions-group-headline{
    padding: 30px;
}

.renew-questionnaire-container .questionnaire-body-container{
    padding: 0px;
    background-color: transparent;
}

.renew-questionnaire-container .questionnaire-treatment-form-container{
    width: 100%;
    padding: 0;
}

.renew-questionnaire-container .questionnaire-questions-group-headline{
    padding: 20px 0px;
    font-size: 18px;
}

/*END OVERRIDE QUESTIONNAIRE PAGE STYLING */



@media (max-width: 1200px){
    .renew-questionnaire-container{
        padding: 0 20px 20px;
    }
    
    .renew-questionnaire-button{
        width: auto;
    }

    .renew-questionnaire-message{
        text-align: left;
        padding: 20px;
    }

    .renew-questionnaire-message .buttons-container > div{
        flex-direction: column;
        gap: 0;
    }

    .renew-questionnaire-show-last-questionnaire{
        width: 100%;
        font-size: 12px ;
        text-align: center;
    }
    
}