@import url('../../../../core.css');

.option-main-header-card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.option-main-header-card .option-content{
    margin: 0 !important;
} 

.payment_description {
    background-color: white;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    color: #656d79;
}

.phoneInput-container {
    width: 100%;
}

.payment-icon {
    max-width: 60px;
    height: 30px;
    object-fit: contain;
}



.payment-icons {
    display: flex;
    justify-content: right;
    flex-wrap: wrap-reverse;
    /* background-color: red; */
}

.payment-info-container .payment-icons > img{
    margin: 0 5px;
}

.long-icon-list img {
    max-width: 45px;
}


.pm-description-and-button {
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pm-description-and-button .inputfield-input {
    margin: 0;
}

.disabled {
    background-color: #eef1f6;
    color: #98a0af;
    border-color: #eef1f6;
    cursor :not-allowed;
}

.disabled .radio {
    background-color: #eef1f6;
    border-color: #98a0af;
}


.toggle-precaution-container {
    display: flex;
    gap: 15px;
}

.toggle-precaution-text,
.toggle-precaution-text > b
{
    font-size: 14px;
    font-family: "Roboto" !important;
    transition: color 100ms linear; /* Reduced duration */
}

#toggle-precaution-error {
    background-color: var(--primary-danger);
}