/* AsideBar */

.account-asidebar-container{
    /* background-color: var(--primary-gray); */
    display: block;
    padding: 0 10px;
    width: 30%;
    max-height: 1000px;
}

.asidebar-details{
    margin-bottom: 60px;
    font-family: Poppins,sans-serif;
}

.asidebar-details-name{
    color: var(--radiobutton-text-color);
    font-weight: 500;
    font-size: 13px;
}

.asidebar-details-id{
    color: var( --questionnaire-select-placeholder);
    font-size: 11px;
}

.asidebar-details-accordion{
    display: none;
}

.asidebar-links{
    list-style-type: none;
}

.asidebar-link-icon-active,
.asidebar-link-icon{
    margin-right: 10px;
}

.asidebar-link-icon{
    display: none;
}


.asidebar-link, .asidebar-link-active{
    padding: 12px 0px;
    display: flex;
    align-items: center;
}


.asidebar-link > a {
    font-size: 14px;
    color: var(--radiobutton-text-color);
    font-family: Roboto,sans-serif;
    margin-right: 10px;
}

.asidebar-link-active > a {
    font-size: 0.875rem;
    color: var(--primary-lightgreen);
    font-weight: 700;
    font-family: Roboto,sans-serif;
}



/*END OF AsideBar */


@media (max-width: 1200px){

    .account-asidebar-container{
        background-color: white;
        width: 100%;
        margin-bottom: 30px;
        padding: 20px;
        border-bottom-right-radius: 30px;
    }

    .asidebar-link-icon,.asidebar-link-icon-active{
        display: inline-block;
        aspect-ratio: 1;
        width: 20px;
    }

    .asidebar-link > a {
        color: var(--questionnaire-select-placeholder);
    }

    .asidebar-link-active > a {
        font-family: Roboto,sans-serif;
        color: var(--questionnaire-select-placeholder);
        font-weight: 400;
    }

    .asidebar-link-active > p{
        font-family: Roboto,sans-serif;
        color: var(--primary-lightgreen);
        font-weight: 400;
    }

    .asidebar-details{
        margin-bottom: 0;
        padding-bottom: 10px;
        
    }

    .asidebar-details-accordion{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    
    .asidebar-links {
        height: 460px;
        transition: height 300ms ease-in-out ;
        border-top: 1px solid #eef1f6;
        padding-top: 10px;
    }

    .asidebar-links-closed {
        height: 0px;
        transition: height 300ms ease-in-out ;
        overflow: hidden;
        border : none;
    }

    .asidebar-details-accordion .asidebar-link {
        font-size: 14px;
        font-family: Roboto,sans-serif;
        color: var(--primary-lightgreen);
        font-weight: 400;
    }
}



@media (max-width: 768){
    .asidebar-links {
        width: 100%;
    }

} 