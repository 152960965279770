.pharmacy-selection-modal-background{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(24, 39, 59, 0.70);
    position: fixed;
    z-index: 400;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: var(--tablet-container-padding);
}


.pharmacy-selection-modal-container {
    display: flex;
    flex-direction: column;
    width: 460px;
    position: relative;
    padding: 60px 40px;
    padding-bottom: 30px;
    background-color: white;
    border-radius: 6px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.pharmacy-selection-modal-container .close-btn{
    position: absolute;
    right: 25px;
    top: 25px;
    width: 20px;
    cursor: pointer;
}


.pharmacy-selection-modal-container > h6 {
    font-size: 18px;
    color: var(--primary-blue);
    text-align: left;
    font-weight: 400;
    margin-bottom: 15px;
    font-family: Roboto,sans-serif;
}

.pharmacy-selection-modal-container > ul > li {
    list-style: none;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    color: #616161;
}

.pharmacy-selection-modal-container .actions-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pharmacy-selection-modal-container .confirm-btn {
    background-color: var(--primary-lightgreen);
    color: white;
    padding: 10px 45px;
    border-radius: 3px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pharmacy-selection-modal-container .confirm-btn:hover{
    opacity: 0.85;
}

.pharmacy-selection-modal-container .cancel-btn {
    border: 2px solid var(--primary-lightgreen);
    color: var(--primary-lightgreen);
    padding: 10px 45px;
    border-radius: 3px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pharmacy-selection-modal-container .cancel-btn:hover{
    opacity: 0.80;
}

.pharmacy-selection-disclaimer {
    margin-top: 10px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color:     #616161;
}


@media (max-width:764px) {
    .pharmacy-selection-modal-background{
        padding: 0 15px;
    }

    .pharmacy-selection-modal-container {
        width: 100%;
    }

    .pharmacy-selection-modal-container .actions-container {
        flex-direction: column;
        gap: 10px;
    }

    .pharmacy-selection-modal-container .confirm-btn,
    .pharmacy-selection-modal-container .cancel-btn{
        padding: 15px 45px;
    }
}