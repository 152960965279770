.accordion {
    width: 100%;
  }
  
  .accordion-item {
    border-top: 1px solid #ccc;
  }
  
  .accordion-title {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    cursor: pointer;
  }
  
  .accordion-title.active {
    color: var(--primary-lightgreen);
  }
  
  .accordion-content {
    display: flex;
    flex-direction: row;
    max-height: 0;
    overflow: hidden;
    gap: 20px;
    transition: max-height 0.3s ease-out, padding-top 0.3s ease-out, padding-bottom 0.3s ease-out;
    padding: 0 50px 0 10px;
  }

  .accordion-content img{
    width: fit-content;
  }

  .accordion-content .text *{
    color: rgba(101, 109, 121, 1);
    font-size: 16px;
    font-family: Roboto, 'sans-serif';
  }
  
  .accordion-content.open {
    max-height: 500px; 
    padding: 0 50px 10px 10px;
  }


@media screen and (max-width: 768px) {
    .accordion-content {
        flex-direction: column;
        max-width: fit-content;
      }
}

  