.DrugChoicePanel > .right-panel {
 
  margin-top: 60px;
  background-color: white;
  border-radius: 10px;
  max-width: 450px;
  padding: 5px 40px 20px;
  padding-bottom: 20px;
}

.hideable {
  max-width: 450px;
}


@media (width < 1023px) {
  .DrugChoicePanel > .right-panel {
    margin-top: 0;
    background-color: white;
    padding: 5px 10px 5px 10px;
    max-width: 1000px;
    width: 100%;
    border-radius: 0;
  }

  .hideable {
    display: none;
  }
}
