
.prescription-details{
    background-color: white;
    padding: 10px 30px 30px 30px;
    border: 1px solid #eef1f6 ;
    margin-bottom: 30px;
}

.prescription-details-message{
    border-bottom: 1px solid #eef1f6 ;
    padding-top: 20px;
}

.prescription-details-message:last-child {
    border-bottom: none;
  }

.prescription-details-author{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.prescription-details-author span{
    font-size: 13px;
    font-family: Roboto, sans-serif;
    color: var(--questionnaire-select-placeholder);
    font-weight: 700;
}

.prescription-details-content{
    margin-bottom: 20px;
    
}

.prescription-details p{
    font-size: 15px;
    font-family: Roboto,sans-serif;
    color: var(--primary-blue);
}

.prescription-details-content a{
    font-size: 16px;
    color: var(--primary-lightgreen);
    cursor: pointer;
}

.order-prescription-details-answer-container{
    padding: 30px;
    background-color: var(--primary-green);
    margin: 0px -30px -30px;
}

.order-prescription-details-answer-container{
    padding: 30px;
    background-color: var(--primary-green);
    margin: 0px -30px -30px;
}


.order-prescription-details-answer-container label{
    font-family: Roboto,sans-serif;
    color: var(--questionnaire-select-placeholder);
    font-size: 12px;
}



.order-prescription-details-answer-container textarea{
    border-radius: 6px;
    border: 1px solid #dbe1eb;
    max-width: 100%;
    margin-top: 10px;
    width: 100%;
    height: 125px !important;
    font-size: 14px;
    padding: 20px;
    resize: none;
    overflow-y: scroll;
    color: var(--primary-blue);
    outline: none;
    -webkit-appearance:none
}


.upload-file-label{
    display: flex;
    align-items: center;
}
.upload-file-label img {
   width: 16px ;
   height: 16px ;
   margin-right: 10px;
}

.upload-file-label > span{
    cursor: pointer;
    color: var(--primary-lightgreen);
    font-weight: 500;
    font-size: 12px;
}

.upload-file-label > span:hover{
    text-decoration: underline;
}

.order-prescription-details-answer-bottom-row{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

#order-prescription-details-answer-submit{
    height: 46px !important;
    font-size: 14px !important;
    min-width: fit-content;
}


@media (max-width: 1200px){
   
    .prescription-details-author{
        flex-direction: column;
    }

    .prescription-details{
        padding: 10px 20px 20px 20px;
    }
    .order-prescription-details-answer-container{
        padding: 20px;
        margin: 0px -20px -20px;
    }

    .order-prescription-details-answer-bottom-row{
        flex-direction: column;
        gap: 15px;
    }

    #order-prescription-details-answer-submit{
        width: 100% !important;
    }
}
