@import url("../../../core.css");

.subscriptionModal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(24, 39, 59, 0.75);
  height: 100%;
  position: fixed;
  z-index: 400;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: var(--tablet-container-padding);
}

.subscriptionModal {
  position: relative;
  border-radius: 6px;
  padding: 90px 20px;
  z-index: 1001;
  display: flex;
  width:var(--desktop-col-10);
  justify-content: center;
  background-color: white;
  align-items: center;
  flex-direction: column;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
}

.subscriptionModal-title {
  font-size: 32px;
  color: var(--primary-blue);
  font-weight: 700;
  word-spacing: normal;
  margin: 5px;
  text-align: center;
}

.subscriptionModal-sub-title {
  color: var(--primary-blue);
  margin-bottom: 30px;
  font-weight: 500;
}

.subscription-modal-bullets-container{
    margin: 0px 10px 30px;
}


.subscription-modal-bullet {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  /* background-color: red; */
  margin-bottom: 10px;
}

.subscription-modal-bullet > img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  position: absolute;
  top: -13px;
}

.subscription-modal-bullet > p {
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  margin-left: 55px;
  text-align: left;
  color: var(--primary-blue);
}

.subscription-modal-discount-label {
  background-color: var(--primary-danger);
  border-radius: 3px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  padding: 2px 3px;
  margin: 0 3px;
}

.subscription-modal-condition{
    font-size: 12px;
    font-family: Roboto,sans-serif;
    color:var(--radiobutton-text-color);
    margin-top: 10px;
    text-align: center;
}



/* Mobile */
@media (max-width: 767px) {

    .subscription-modal-condition{
      text-align: left;
    }

    .subscriptionModal-background{
        padding: var(--mobile-container-padding);
    }

    .subscriptionModal{
        padding: 30px 20px;
    }

    .subscriptionModal-title{
        font-size: 24px;
    }

    .subscriptionModal-sub-title{
        font-size: 15px;
    }

    .sumbitButton-container{
        width: 100%;
    }

    .subscription-modal-bullet > p {
        margin-left: 20px;
    }

    .subscription-modal-bullet > img{
        left: -32px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    /* .subscriptionModal{
        padding: 90px 30px;
    } */
}