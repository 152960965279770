.editable-info-card-body-container{
    display: flex;
    flex-direction: column;
    background-color: var(--primary-green);
    margin: 15px 0px ;
    border-radius: 4px;
}


.editable-info-card{
    border-radius: 5px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editable-info-card-text-container{
    display: flex;
    flex-direction: column;
    font-weight: 700;
}

.editable-info-card-title{
    margin-bottom: 5px;
    color: var( --questionnaire-select-placeholder);
    font-size: 13px;
    font-family: Roboto,sans-serif;
}

.editable-info-card-value{
    font-family: Roboto,sans-serif;
    font-size: 14px;
    color: var(--primary-blue);
}
.renew-questionnaire-subscription-prescription-buttons{
    padding: 20px;
}


.administer{
    color: var(--primary-lightgreen);
    text-decoration: underline;
    font-size: 13px;
    cursor: pointer;
    font-weight: 500;
}

.administer:hover{
    text-decoration: none;
}


@media (max-width: 767px) {
    .editable-info-card{
        padding: 20px
    }   

   
}