.reset-password-page{
    min-height: 100vh;
    display: flex;
    background-color: var(--primary-white);
    flex-direction: column;
}

.reset-password-content-container{
    flex: 1;
    padding: 120px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reset-password-title{
    font-size: 32px;
    color: var(--primary-blue);
    margin-bottom: 40px;
    font-weight: 700;

}

.reset-password-container{
    width: var(--desktop-col-6);

}

.reset-password-page .footer-container{
    background-color: var(--primary-white) !important;
}


@media (max-width:768px){
    .reset-password-container{
        width: 100%;
    }

    .reset-password-content-container{
        padding: 90px 20px 120px 20px;

    }

    .reset-password-title{
        font-size: 24px;
        text-align: center;
    }
}