.drug-dosage-panel-container {
  background-color: white;
  width: 100%;
  margin-bottom: 15px;
}

.drug-dosage-container {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  flex-direction: column;
}

.packets-container > .orderWindow-container-title,
.drug-dosage-container > .orderWindow-container-title {
  align-self: flex-start;
  margin-bottom: 10px;
}

.drug-dosage {
  display: flex;
  justify-content: flex-start !important;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 15px;
}

.drug-dosage-col-layout {
  display: flex;
  flex-direction: column;
  list-style: none;
  text-align: left;
  /* background-color: red; */
}

.drug-dosage-col-layout .li-container {
  width: 100% !important;
  align-self: center !important;
}

.drug-dosage-col-layout .li-container {
  font-size: 14px;
}

.drug-dosage-item,
.drug-dosage-item-selected {
  position: relative;
  border: 1px solid #eef1f6;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  min-height: 60px;
  border-radius: 5px;
  padding: 18px 20px;
  color: gray;
  font-weight: 500;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  white-space: normal;
}

.drug-dosage-item-selected {
  border: 1px solid #eef1f6;
  background-color: #e7fcf7;
  border: 1px solid #11ddac;
  color: var(--primary-blue);
}

.li-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drug-dosage > .li-container {
  min-width: calc(33.3333% - 15px);
}

.packets-container {
  display: flex;
  flex-direction: column;
}

.drug-packs-choice-inner-container-col-layout .pack-item-selected,
.drug-packs-choice-inner-container-col-layout .pack-item,
.drug-packs-choice-inner-container-col-layout .pack-item-out-of-stock {
  width: 100% !important;
  align-items: flex-start;
  padding-left: 15px;
}

.drug-dosage-col-layout .drug-dosage-item-selected,
.drug-dosage-col-layout .drug-dosage-item {
  width: 100% !important;
  align-items: flex-start !important;
  padding-left: 15px;
  text-align: left !important;
}

.check-mark {
  position: absolute;
  z-index: 100;
  transform: scale(1.15);
  top: -15px;
  right: -18px;
  filter: drop-shadow(0px 10px 15px rgba(17, 221, 172, 0.3));
}


