@import url("../../core.css");

.questionnaire-updated-page{
flex-direction: column;
min-height: 100vh;
display: flex;
justify-content: center;
align-items: center;
}

.questionnaire-updated-image{
    width: 920px;
    height: auto;
}

.questionnaire-updated-image-container {
    display: flex;
    align-items: center;
    max-width: 730px;
    text-align: center;
    flex-direction: column;
    flex: 1;
  }

  .questionnaire-updated-title{
    font-size: 42px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
    color: var(--primary-blue);
  }

  .questionnaire-updated-subtitle{
    font-size: 16px;
    font-weight: 400;
    color: #656D79;
    font-family: 'Roboto', sans-serif;
  }

  .questionnaire-updated-page .footer-container{
    background-color: #fff;
  }

@media (max-width:1200px) {
    .questionnaire-updated-image{
        width: 708px;
        height: auto;
    }
}

@media (max-width:764px) {
    .questionnaire-updated-image{
        width: 100%;
        height: auto;
    }
    .questionnaire-updated-title{
        font-size: 28px;
      }
}