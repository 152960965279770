@import url('../../core.css');


.stepslist-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--primary-gray);
    /* background-color: aliceblue; */
    padding: 16px 0px 16px 24px;
    border-bottom: 1px solid #EEF1F6
    /* background-color: transparent; */
}


.stepslist-container-mobile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 25px 0px 25px
}


.step-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 60px;
}

.step-container-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.step-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Roboto,sans-serif;
    font-size: 14px;
    color: var(--radiobutton-text-color);
}

.step-logo{
    color: rgb(204, 205, 206);
    margin-left: 50px;
}

.stepslist-progress-bar{
    margin-top: 2px;
    height:2px;
    background-color: var(--primary-lightgreen);
}
