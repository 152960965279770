@import url("../../core.css");

.questionnaire-body-container {
  background-color: var(--primary-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0px 90px;
}

.questionnaire-title {
  font-family: Poppins, sans-serif;
  text-align: center;
  align-self: center;
  width: 540px;
  font-size: 32px;
  color: var(--primary-blue);
  margin: 30px 190px 30px;
}

.questionnaire-treatment-container {
  display: flex;
  align-self: center;
  width: 100%;
  /* background-color: purple; */
  flex-direction: column;
  justify-content: space-between;
}

.questionnaire-form-container {
  display: flex;
  width: 100%;
  align-self: center;
  flex-direction: column;
  justify-content: center;
}

.questionnaire-treatment-form-container {
  display: flex;
  flex-direction: column;
  width: var(--desktop-col-10);
}

/* ----------- MEDIA QUERIES ----------- */

/* Mobile */
@media (max-width: 767px) {
  .questionnaire-title {
    font-size: 24px;
    margin: 0px 0px 30px;
    width: 100%;
    max-width: 100%;
  }

  .questionnaire-treatment-form-container {
    width: 100%;
    padding: var(--mobile-container-padding);
    /* background-color: red; */
  }
  

  .questionnaire-question-item,
  .questionnaire-subquestion,
  .questionnaire-loginquestion-inputs-container,
  .textarea-placeholder-helpblock-container,
  .questionnaire-question-item-column {
    /* background-color: lightblue !important; */
    flex-direction: column !important;
    align-items: flex-start;
  }

  .questionnaire-loginquestion-inputs-container{
    gap: 0 !important;
  }

  .credentialinput-container {
    margin: 0px 0px -10px !important;
  }

  .credentialinput-container:nth-child(2){
    margin-bottom: 15px !important;
  }
  /* 
  .questionnaire-loginquestion-inputs-container{
    flex-direction: column 
  } */

  .questionnaire-text-and-helpblock-container,
  .questionnaire-question-item-text-container {
    width: 100% !important;
  }

  .questionnaire-questions-group-headline {
    font-size: 18px;
    padding: 30px 0px !important;
  }

  .questionnaire-answers-checkbox-list {
    align-self: flex-start;
  }

  /* cancel the margin left of row containers */
  .questionnaire-question-item > .questionnaire-answers-container {
    margin-left: 0px;
    justify-content: center;
    width: 100%;
  }

  .questionnaire-answers-container {
    margin: 10px 0px 10px 0px;
    /* justify-content: flex-start; */
    gap: 10px;
  }

  .questionnaire-answers-container:has(.questionnaire-textarea-question) {
    /* Override width for Firefox text area containers */
    width: 100% !important;
  }

  .questionnaire-select-dropdown-container {
    width: 99%;
    margin-left: 2px;
  }

  .questionnaire-answers-container .questionnaire-select-container,
  .questionnaire-answers-container .questionnaire-select-dropdown-container{
    width: 100%!important;
  }

  .questionnaire-select-error-container,
  .questionnaire-select-container,
  .questionnaire-select-options-container {
    width: 100%;
  }

  .radio-button-container,
  .radio-button-error-container {
    flex: 1;
    margin-left: 0px;
  }

  .questionnaire-subquestion-container {
    width: 100%;
  }

  .questionnaire-subquestion > .questionnaire-answers-container {
    flex-wrap: wrap;
    align-self: center;
    flex: 1;
  }

  .questionnaire-subquestion
    > .questionnaire-answers-container
    > .radio-button-container,
  .questionnaire-subquestion
    > .questionnaire-answers-container
    > .radio-button-error-container {
    min-width: 45%;
  }

  .questionnaire-photoDiagnose-container {
    flex-direction: column !important;
    width: 100% !important;
  }

  .questionnaire-photoDiagnose-image-container > img {
    object-fit: cover;
    width: fit-content;
    max-width: 100%;
  }

  .questionnaire-photoDiagnose-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .questionnaire-question-photoDiagnose-helpblock-container,
  .questionnaire-photoDiagnose-image-container,
  .questionnaire-photoDiganose-draganddrop-container {
    width: 100% !important;
    max-width: 100% !important;
  }

  .questionnaire-question-photoDiagnose-headline {
    font-size: 18px;
  }

  .questionnaire-info-scroll {
    width: 100% !important;
  }

  .questionnaire-answers-photoSelection-container {
    width: 100%;
    justify-content: center;
  }

  .questionnaire-photoSelection,
  .questionnaire-photoSelection-selected,
  .questionnaire-photoSelection-error {
    padding: 10px 5px 5px;
    margin: -5px;
  }

  .questionnaire-photoSelection-image {
    width: 115px;
    margin: 0 10px;
  }

  .questionnaire-submitButton {
    width: 100% !important;
  }

  .textarea-placeholder {
    margin-bottom: 10px;
  }

  .emojiblock-container {
    width: 108% !important;
    margin: -30px -15px 30px !important;
    padding: 30px 20px !important;
  }

  .emojiblock-discount-notification > .significance-container > div {
    align-items: center !important;
  }
}

/* ---------End of Mobile rules--------- */

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .questionnaire-treatment-form-container {
    width: var(--desktop-col-8);
    padding: var(--tablet-container-padding);
  }

  /* answers that have photoselection component */
  .questionnaire-answers-container:has(> .questionnaire-answers-photoSelection-container) {
    margin-left: 0;
  }

  .questionnaire-answers-photoSelection-container {
    align-self: center;
    justify-content: space-between;
    gap: 0px;
    width: 100%;
  }
  .questionnaire-photoSelection,
  .questionnaire-photoSelection-selected,
  .questionnaire-photoSelection-error {
    padding: 20px 15px 10px;
    margin: 0px 0px 25px;
  }

  .questionnaire-photoSelection-placeholder {
    font-size: 14px;
  }

  .questionnaire-photoSelection-image {
    width: 130px;
  }

  .questionnaire-info-scroll {
    width: 550px;
  }

  .questionnaire-submitButton {
    width: 100% !important;
  }

  .questionnaire-subquestion-container {
    width: 100%;
  }

  .questionnaire-photoDiagnose-container {
    flex-direction: column !important;
  }

  .questionnaire-question-photoDiagnose-headline {
    font-size: 18px;
  }

  .emojiblock-container {
    width: 100% !important;
  }
}
