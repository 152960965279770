.conversation-tabs-container {
    border-radius: 5px;
    flex: 1;
    width: 40%;
}

.conversation-tabs {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 2px solid var(--primary-gray);
}

.conversation-tab-item {
    font-size: 13px;
    font-family: Poppins, sans-serif ;
    color : var(--questionnaire-select-placeholder);
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.conversation-tab-item:hover{
    text-decoration: underline;
}

.conversation-tab-item img{
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.selected-tab-item {
    color :var(--primary-lightgreen);
}

.tab-mentions-container {
    padding: 20px;
    max-height: 350px;
    overflow-y:auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tab-mentions-container .clickable-mention{
    color: var(--questionnaire-select-placeholder);
    font-family: Roboto,sans-serif;
    font-size: 13px;
    padding: 5px 10px;
    border : 1px solid var(--sub-gray);
    text-decoration: none;
}

.tab-mentions-container .clickable-mention:hover{
    text-decoration: none;
}

.tab-mentions-info{
    font-family: Roboto,sans-serif;
    font-size: 12px;
    font-size: .75rem;
    color: var(--questionnaire-select-placeholder);
}


@media (max-width:1200px){
    .conversation-tabs-container{
        width: 100%;
    }

    .conversation-tabs{
        justify-content: center;
        gap: 15px;
        padding: 15px 10px;
        border: 2px solid var(--primary-gray);
        flex-wrap: wrap;

    }

    .tab-mentions-container{
        border-right: 2px solid var(--primary-gray);
        border-left: 2px solid var(--primary-gray);
        border-bottom: 2px solid var(--primary-gray);
        flex-direction: row; 
        flex-wrap: wrap;
    }

    .conversation-tabs-container{
        border: none;
        padding: 15px;
    }
}


@media (max-width:764px){
    .tab-mentions-container{
        padding: 20 10px;
    }
}