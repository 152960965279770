
#trustbadge-container, #minimized-trustbadge, #trustcard-container, #maximized-trustbadge{
    height: fit-content !important;
    top: auto !important;
     /* Hidden for now due to marketing's request */
}

/* #minimized-trustbadge-98e3dadd90eb493088abdc5597a70810{
    margin-bottom: 10px !important;
} */

@media screen and (min-width: 649px) {
    /* [title *= "Live chat button"] > div {
    bottom: 5px !important;
    padding: 100px;
} */

#trustbadge-container.bottom, #minimized-trustbadge,
#trustbadge-container.bottom, #maximized-trustbadge{
    bottom: 80px !important;
    transition: bottom .2s linear !important;
}
}