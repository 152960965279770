@import url("../../core.css");

.orderWindow-container {
  width: 100%;
}

.orderWindow-container-title {
  font-size: 13px;
  font-family: Roboto, sans-serif;
  color: var(--questionnaire-select-placeholder);
  font-weight: 600;
  margin-bottom: 15px;
}

.orderWindow-image-disclaimer{
  font-size: 10px;
  color: var(--questionnaire-select-placeholder);
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.orderWindow,
.orderwindow-fr {
  background-color: white;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-shadow: 0 60px 90px rgb(0 0 0 / 6%);
  border-radius: 6px;
}

.orderwindow-fr{
    padding: 15px 20px 20px ;
}

.orderWindow-header-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 20px;
}

.orderWindow-header-container > img{
  width: 150px;
  height: 150px;
}

.orderwindow-fr-image-text {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.orderwindow-fr-image-text > span{
    color: var(--primary-blue);
    font-weight: 700;
}

.orderwindow-fr-image-text > img {
  width: 80px;
  height: 80px;
}

.orderWindow-item-title,
.orderWindow-item-quantity {
  color: var(--radiobutton-text-color);
  font-weight: 600;
  font-size: 14px;
}

.orderWindow-item-quantity {
  font-weight: 400;
}

.orderWindow-price-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.orderWindow-price-row-with_discount > p,
.orderWindow-price-row > p {
  font-size: 12px;
  color: var(--radiobutton-text-color);
  font-family: Roboto, sans-serif;
}

.orderWindow-total-price-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}


.orderWindow-total-price-row > p {
  color: var(--primary-blue);
  font-weight: 600;
  font-family: Roboto, sans-serif;
}


.orderWindow-subscription-plans-container{
  border-radius: 6px;
  padding: 15px;
  margin-top: 20px;
  width: 100%;

  background-color: var(--primary-green);
}

.orderWindow-subscription-plans-header{
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.orderWindow-subscription-plans-title{
  font-family: Roboto,sans-serif;
  color: var(--primary-blue);
  font-weight: 600;
  font-size: 14px;
}

.orderWindow-subscription-plans-title>span{
  color: var(--primary-danger);
  font-family: Roboto,sans-serif;
  font-weight: 600;
}

.orderWindow-subscription-plans-checks{
  margin: 12px 0px;
}

.orderWindow-subscription-plan-check-row{
  display: flex;
  align-items: center;
  list-style-type: none;
  margin-bottom: 8px;
}

.orderWindow-subscription-plan-check-row > img{
  width: 12px;
  height: 12px;
  margin-right: 5px;

}


.orderWindow-subscription-plan-check-row > p {
  font-size: 12px;
  color: var(--radiobutton-text-color);
  font-family: Roboto,sans-serif;

}

.orderWindow-subscription-plans-intervals-container{
  display: flex;
  flex-direction: column;
  border-top: 1px solid #c6f4e9;
  margin-top: 12px;
}

.orderWindow-subscription-plans-intervals-title{
  font-size: 12px;
  font-family: Roboto,sans-serif;
  color: var(--primary-blue);
  font-weight: 700;
  margin: 12px 0px 8px 0px;
}


.orderWindow-subscription-plan,
.orderWindow-subscription-plan-picked{
  background-color: var(--primary-white);
  /* background-color: black; */
  border-radius: 6px;
  padding: 12px 15px;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  
}


.discount-label{
  background-color: var(--primary-danger);
  font-family: Roboto,sans-serif;
  margin: 0px 5px;
  padding: 2px 5px;
  border-radius: 3px;
  color: var(--primary-white);
  position: absolute;
  right: 5px;
  top: 8px;
}


.orderWindow-subscription-plan > p{
  font-size: 12px;
  color:var(--radiobutton-text-color);
}

.orderWindow-subscription-plan-picked > p{
  font-size: 12px;
  color: var(--primary-blue);
  font-weight: 500;
}

.orderWindow-open-subscription-modal{
  color: var(--primary-lightgreen);
  text-decoration: underline;
  font-size: 12px;
  margin: 8px 0px 10px;
  font-family: Roboto,sans-serif;
  cursor: pointer;
}

.orderWindow-open-subscription-modal:hover{
  text-decoration: none;
}


.orderwindow-old-treatmentfee-deleted,
.orderWindow-old-price-deleted{
  text-decoration:line-through;
  color: var(--primary-danger);
  font-size: 12px;
  font-family: Roboto,sans-serif;
  font-weight: 400;
  margin-right: 8px;
}

.line-thru-price {
  text-decoration:line-through;
  color: var(--primary-danger);
  font-size: 12px;
  font-family: Roboto,sans-serif;
  font-weight: 400;
}


.orderWindow-price-row-with_discount{
  display: flex;
  align-items: center;
  flex-direction: row;
}


.orderWindow-disclaimer{
  font-size: 12px;
  margin: 12px 0px;
  color: var(--radiobutton-text-color);
  font-family: 'Roboto', sans-serif;
  
}




