@import url('../../../core.css');


.dark-loading-container{
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 3;
    background-color: rgba(50, 50, 50, 0.3);
    backdrop-filter: blur(1px);
    width: 100%;
    height: 100%;
    animation: fadeIn 0.6s ease-in-out;
  }

  
  .dark-loading {
    width: 92px;
    height: 92px;
    margin: 0 auto;
    position: absolute;
    top: calc(50% - 46px);
    left: 0;
    right: 0;
  }
  
  .dark-loading-circle {
    border: 9px #cdcdcd solid;
    border-top: 9px #11DDAC solid;
    border-radius: 50%;
    -webkit-animation: spCircRot 0.8s infinite linear;
    animation: spCircRot 0.8s infinite linear;
  }
  
  @-webkit-keyframes spCircRot {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }

  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }


  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    1% {
      visibility: visible;
    }
    100% {
      opacity: 0.98;
      visibility: visible;
    }
  }