@import url('../../core.css');

.stickyNotification-uid {
    color: var(--primary-white);
    margin-top: 5px;
    font-weight: 700;
    font-size: 13px;
}

.stickyNotification-container-hidden{
    display: none;
}

.stickyNotification-container-fadeIn{
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 1000;
    width: 100% !important;
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 3px;
    border-bottom-left-radius: 30px;
    animation: fadeIn 1.5s forwards;
}


.stickyNotification-container-fadeOut{
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 1000;
    width: 100% !important;
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 3px;
    opacity: 0;
    border-bottom-left-radius: 30px;
    animation: fadeOut 1.5s forwards;
    pointer-events: none;
}


.stickyNotification-container-fadeIn:hover{
    opacity: 1 !important;
    cursor: pointer;
    box-shadow: 0 0 12px var(--primary-black)
}

.stickyNotification-content-container{
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.stickyNotification-title{
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: 24px;
    color: var(--primary-white);
    font-weight: 700;
}

.stickyNotification-content{
    color: var(--primary-white);
    font-size: 16px;
}


.stickyNotification-logo-info{
    margin-right: 10px;
    font-size: 24px;
    color: var(--primary-white);
}

.stickyNotification-logo-close{
    font-size: 22px;
    color: var(--primary-white);
    position: absolute;
    right: 30px;
    top: 30px;
    
}

.stickyNotification-logo-close:hover{
    color: var(--primary-black);
    cursor: pointer;
}




@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.85;
	}
}


@keyframes fadeOut {
	0% {
        opacity: 0.85;
	}
	100% {
		opacity: 0;
	}
}


@media (max-width:1200px) {
    .stickyNotification-container-fadeIn,
    .stickyNotification-container-fadeOut{
        padding: 20px;
    }

}