#start-consulation-header ul {
    list-style-type: none;
}

.popup-info p  {
    color: #656d79;
    font-size: 13px;
    font-size: 0.9375rem;
    margin: 0 0 30px;
}

.popup-container p:not(.popup-description) {
    color: #656d79;
    font-size: 15px;
    font-size: 0.9375rem;
    margin: 0 0 30px;
}

.check-bullets li .circle .icon{
    font-size:8px;
    position:relative;
    top:1px
}
.check-bullets.green li .circle{
    background-color:#11ddac
}


.backlink .icon{
    color:#11ddac;
    font-size:24px;
    margin-right:7px;
    position:relative;
    left:-5px
}
.backlink span{
    font-size:14px;
    font-size:.875rem;
    font-family:Roboto,sans-serif;
    color:#656d79;
    position:relative;
    left:-5px
}
.g-recaptcha{
    position:relative;
    z-index:25
}
.coming-soon{
    font-size:14px;
    font-size:.875rem
}
.coming-soon,.coming-soon-badge{
    color:#656d79;
    vertical-align:middle
}
.coming-soon-badge{
    font-size:10px;
    font-size:.625rem
}
.msg-badge{
    font-size:12px;
    font-size:.75rem;
    background:#ef4747;
    width:18px;
    height:18px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%
}
.badge-new,.msg-badge{
    font-family:Roboto,sans-serif;
    color:#fff;
    font-weight:700
}
.badge-new{
    padding:2px 12px 0;
    min-height:19px;
    background-color:#ef4747;
    border-radius:20px;
    font-size:11px;
    font-size:.6875rem;
    margin-left:10px
}
.right{
    display:flex;
    justify-content:flex-end
}
.doc-carousel{
    opacity:0;
    visibility:hidden;
    overflow:hidden;
    transition:opacity 1s ease;
    -webkit-transition:opacity 1s ease;
    max-height:476px
}

table:not(.frame-table) tbody tr td ul,ul.default-list{
    margin-bottom:30px
}
table:not(.frame-table) tbody tr td ul li,ul.default-list li{
    padding-left:15px;
    position:relative;
    color:#656d79;
    font-size:15px;
    font-size:.9375rem;
    font-family:Roboto,sans-serif
}
table:not(.frame-table) tbody tr td ul li b,table:not(.frame-table) tbody tr td ul li strong,ul.default-list li b,ul.default-list li strong{
    color:#656d79;
    font-family:Roboto,sans-serif
}
table:not(.frame-table) tbody tr td ul li a,ul.default-list li a{
    cursor:pointer;
    font-family:Roboto,sans-serif
}
table:not(.frame-table) tbody tr td ul li:before,ul.default-list li:before{
    content:"";
    width:6px;
    height:6px;
    border-radius:50%;
    background-color:#11ddac;
    position:absolute;
    left:0;
    top:8px
}
ul.blue-text-list{
    margin-bottom:30px
}
ul.blue-text-list li{
    padding-left:15px;
    position:relative;
    font-size:16px;
    font-size:1rem
}
ul.blue-text-list li,ul.blue-text-list li b,ul.blue-text-list li strong{
    color:#0d2c54;
    font-family:Roboto,sans-serif
}
ul.blue-text-list li a{
    cursor:pointer
}
ul.blue-text-list li:before{
    content:"";
    width:6px;
    height:6px;
    border-radius:50%;
    background-color:#0d2c54;
    position:absolute;
    left:0;
    top:8px
}
ul.long-rows-list{
    margin-bottom:30px
}
ul.long-rows-list li{
    padding-left:15px;
    margin-bottom:15px;
    position:relative;
    font-size:15px;
    font-size:.9375rem
}
ul.long-rows-list li,ul.long-rows-list li b,ul.long-rows-list li strong{
    color:#656d79;
    font-family:Roboto,sans-serif
}
ul.long-rows-list li a{
    cursor:pointer
}
ul.long-rows-list li:before{
    content:"";
    width:6px;
    height:6px;
    border-radius:50%;
    background-color:#11ddac;
    position:absolute;
    left:0;
    top:8px
}
ul.long-rows-list li:last-of-type{
    margin-bottom:0
}
.table_of_contents{
    margin:60px 0
}
.table_of_contents .title{
    position:relative;
    display:flex;
    font-weight:700;
    color:#11ddac;
    margin:0 0 15px;
    padding-left:34px
}
.table_of_contents .title:before{
    position:absolute;
    left:0;
    top:0;
    content:"";
    height:22px;
    font-family:icomoon;
    line-height:1;
    font-weight:500;
    transform:rotate(180deg);
    color:#11ddac;
    font-size:22px;
    font-size:1.375rem
}
.table_of_contents ul li{
    margin-bottom:18px
}
.table_of_contents ul li:last-of-type{
    margin-bottom:0
}
.table_of_contents ul li a{
    font-family:Roboto,sans-serif;
    display:inline-block;
    font-size:14px;
    font-size:.875rem;
    color:#0d2c54;
    text-decoration:underline
}
.table_of_contents ul li a:hover{
    text-decoration:none
}
.buyer-journey{
    margin:60px 0;
    background-color:#0d2c54;
    display:flex;
    align-items:center;
    padding:60px 30px;
    border-radius:12px;
    -moz-border-top-left-radius:60px;
    border-top-left-radius:60px;
    -moz-border-bottom-right-radius:60px;
    border-bottom-right-radius:60px;
    flex-direction:column
}
.buyer-journey .main-title{
    margin-bottom:45px;
    color:#11ddac;
    font-size:18px;
    font-size:1.125rem;
    font-weight:700;
    flex:1
}
.buyer-journey .journey-list{
    flex:1;
    width:100%
}
.buyer-journey .journey-list li a{
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:14px 0;
    border-bottom:1px solid hsla(0,0%,100%,.12);
    text-decoration:none;
    color:#fff;
    transition:all .25s ease-in-out
}
.buyer-journey .journey-list li a span{
    display:block;
    font-size:16px;
    font-size:1rem;
    font-weight:500
}
.buyer-journey .journey-list li a .icon.icon-arrow{
    position:relative;
    font-size:14px;
    color:rgba(17,221,172,.3);
    transition:all .25s ease-in-out
}

.popup-description{
    margin-bottom:15px;
    color:#656d79;
    font-size:15px;
    font-size:.9375rem
}
.popup-container{
    text-align:center;
    position:relative;
    background-color:#fff;
    padding:60px 30px;
    border-radius:6px;
    -moz-border-top-left-radius:30px;
    border-top-left-radius:30px;
    -moz-border-bottom-right-radius:30px;
    border-bottom-right-radius:30px
}
.popup-container h3,.popup-container h4{
    margin-bottom:5px
}
.popup-container h4.text-left{
    padding-left:8px
}
.popup-container p:not(.popup-description){
    color:#656d79;
    font-size:15px;
    font-size:.9375rem;
    margin:0 0 30px
}
.popup-container .form-group{
    text-align:left
}
.popup-container .form-group:last-of-type{
    margin-bottom:1px
}

.popup-image{
    margin:0 auto 40px
}
.popup-image.sprite-main-sended{
    margin-bottom:15px
}
.popup-navigation{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-bottom:30px
}
.popup-navigation .dot-green{
    display:block;
    width:12px;
    height:12px;
    margin-right:16px;
    border-radius:50%;
    background-color:#11ddac;
    opacity:.25;
    cursor:pointer
}
.popup-navigation .dot-green:last-child{
    margin-right:0
}
.popup-navigation .dot-green.active{
    opacity:1
}
.popup-close{
    cursor:pointer;
    position:absolute;
    top:25px;
    right:25px;
    height:14px
}
.popup-close .line{
    transition:all .25s ease-in-out;
    background-color:#656d79;
    width:14px;
    height:2px;
    display:block;
    border-radius:3px
}
.popup-close .line:first-child{
    transform:translateY(6px) rotate(45deg)
}
.popup-close .line:nth-child(2){
    transform:translateY(4px) rotate(-45deg)
}
.popup-close:hover .line{
    background-color:#dbe1eb
}
.popup-info,.popup-info p{
    text-align:left
}
.popup-info .name,.popup-info .reg{
    font-size:15px;
    font-size:.9375rem;
    color:#656d79
}
.popup-info .address{
    text-align:left;
    font-size:12px;
    font-size:.75rem;
    color:#656d79
}
.popup-info .title{
    display:inline-block;
    margin-bottom:5px;
    color:#0d2c54;
    font-size:15px;
    font-size:.9375rem
}
.popup-close-button{
    cursor:pointer;
    color:#98a0af;
    font-size:12px;
    font-size:.75rem;
    line-height:22px
}
#modal-doctors .popup-container{
    padding:30px 30px 0;
    overflow:hidden
}
#modal-doctors .popup-wrapper .content{
    margin-top:30px;
    max-height:325px;
    overflow-y:auto;
    margin-right:-20px;
    padding-right:10px
}
#modal-doctors .popup-wrapper .close{
    padding:30px 0;
    margin:0 -30px;
    background-color:#e7fcf7
}
#modal-doctors .popup-image{
    min-height:61px;
    margin-bottom:10px
}
#modal-doctors .popup-info{
    margin-bottom:30px
}
#modal-doctors .popup-info p{
    margin-bottom:0
}
#are-you-sure .close{
    margin-top:15px
}
#modal-limitations .popup-container,#privacy-modal .popup-container,#terms-modal .popup-container{
    padding:30px 30px 0;
    overflow:hidden
}
#modal-limitations .popup-wrapper,#privacy-modal .popup-wrapper,#terms-modal .popup-wrapper{
    max-width:708px
}
#modal-limitations .popup-wrapper .content,#privacy-modal .popup-wrapper .content,#terms-modal .popup-wrapper .content{
    margin-top:30px;
    max-height:415px;
    overflow-y:auto;
    margin-right:-20px;
    padding-right:10px
}
#modal-limitations .popup-wrapper .close,#privacy-modal .popup-wrapper .close,#terms-modal .popup-wrapper .close{
    padding:30px 0;
    margin:0 -30px;
    background-color:#e7fcf7
}
#modal-limitations .popup-image,#privacy-modal .popup-image,#terms-modal .popup-image{
    margin-bottom:10px
}
#modal-limitations .popup-info p,#privacy-modal .popup-info p,#terms-modal .popup-info p{
    font-size:12px;
    font-size:.75rem
}
#modal-limitations .popup-info .default-list,#modal-limitations .popup-info table:not(.frame-table) tbody tr td ul,#privacy-modal .popup-info .default-list,#privacy-modal .popup-info table:not(.frame-table) tbody tr td ul,#terms-modal .popup-info .default-list,#terms-modal .popup-info table:not(.frame-table) tbody tr td ul,table:not(.frame-table) tbody tr td #modal-limitations .popup-info ul,table:not(.frame-table) tbody tr td #privacy-modal .popup-info ul,table:not(.frame-table) tbody tr td #terms-modal .popup-info ul{
    margin-top:-20px
}
#modal-limitations .popup-info .default-list li,#modal-limitations .popup-info table:not(.frame-table) tbody tr td ul li,#privacy-modal .popup-info .default-list li,#privacy-modal .popup-info table:not(.frame-table) tbody tr td ul li,#terms-modal .popup-info .default-list li,#terms-modal .popup-info table:not(.frame-table) tbody tr td ul li,table:not(.frame-table) tbody tr td #modal-limitations .popup-info ul li,table:not(.frame-table) tbody tr td #privacy-modal .popup-info ul li,table:not(.frame-table) tbody tr td #terms-modal .popup-info ul li{
    font-size:12px;
    font-size:.75rem
}
#modal-limitations .popup-info .default-list li:before,#modal-limitations .popup-info table:not(.frame-table) tbody tr td ul li:before,#privacy-modal .popup-info .default-list li:before,#privacy-modal .popup-info table:not(.frame-table) tbody tr td ul li:before,#terms-modal .popup-info .default-list li:before,#terms-modal .popup-info table:not(.frame-table) tbody tr td ul li:before,table:not(.frame-table) tbody tr td #modal-limitations .popup-info ul li:before,table:not(.frame-table) tbody tr td #privacy-modal .popup-info ul li:before,table:not(.frame-table) tbody tr td #terms-modal .popup-info ul li:before{
    top:7px
}

#modal-pharmacies .popup-container{
    padding:30px 30px 0;
    overflow:hidden
}
#modal-pharmacies .popup-wrapper .content{
    margin-top:30px;
    max-height:325px;
    overflow-y:auto;
    margin-right:-20px;
    padding-right:10px
}
#modal-pharmacies .popup-wrapper .close{
    padding-bottom:60px
}
#modal-pharmacies .popup-image{
    margin-bottom:10px;
    min-height:61px
}
#modal-pharmacies .popup-info{
    margin-bottom:30px
}
#modal-pharmacies .popup-info p{
    margin-bottom:0
}

.start-consultation-widget{
    background-color:#eef1f6;
    border-radius:6px;
    -moz-border-top-left-radius:30px;
    border-top-left-radius:30px;
    -moz-border-bottom-right-radius:30px;
    border-bottom-right-radius:30px;
    margin:60px 0;
    padding:60px 30px;
    text-align:center
}
.start-consultation-widget .title,.start-consultation-widget h2{
    display:block;
    font-weight:700;
    font-size:18px;
    font-size:1.125rem;
    color:#0d2c54;
    margin-bottom:15px
}
.start-consultation-widget p{
    font-size:14px;
    font-size:.875rem;
    color:#0d2c54;
    max-width:570px;
    margin:0 auto 30px
}
.start-consultation-widget ul{
    flex-direction:column;
    align-items:flex-start;
    max-width:-webkit-max-content;
    max-width:-moz-max-content;
    max-width:max-content;
    margin:0 auto 30px
}
.start-consultation-widget ul li{
    font-size:14px;
    font-size:.875rem;
    color:#0d2c54;
    font-family:Roboto,sans-serif;
    position:relative;
    margin:0 0 14px;
    padding-left:40px;
    text-align:left
}
.start-consultation-widget ul li:last-of-type{
    margin-bottom:0
}
.start-consultation-widget ul li:before{
    font-family:icomoon;
    content:"";
    width:28px;
    height:28px;
    border-radius:50%;
    position:absolute;
    top:0;
    left:0;
    font-size:10px;
    font-weight:700;
    display:flex;
    align-items:center;
    justify-content:center;
    color:#fff;
    background-color:#11ddac;
    box-shadow:0 10px 15px rgba(17,221,172,.3)
}
.cookieAcceptBar .manage-preferences .start-consultation-widget span,.cookieAcceptBar .start-consultation-widget .btn-accept-cookies,.next-article .button-wrapper .start-consultation-widget .read-more,.start-consultation-widget .btn,.start-consultation-widget .cookieAcceptBar .btn-accept-cookies,.start-consultation-widget .cookieAcceptBar .manage-preferences span,.start-consultation-widget .next-article .button-wrapper .read-more{
    max-width:360px;
    padding:0 10px
}
.start-consultation-widget .cta-note{
    font-family:Roboto,sans-serif;
    font-size:11px;
    font-size:.6875rem;
    color:#0d2c54
}
.start-consultation-widget .cta-note.bottom{
    font-weight:400
}
.start-consultation-widget .cta-note strong{
    font-family:Roboto,sans-serif
}
