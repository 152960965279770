.auto-complete-options-container {
    position:absolute;
    bottom: 50px;
    left: 5px;
    margin-top: 18px;
    background: white;
    color: black;
    border: 1px solid #DDD;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    z-index: 11110 !important;
}

.auto-complete-options-container > .auto-complete-option:nth-child(n+6) {
    /* Showing only 5 auto complete options */
    display: none;
}


.auto-complete-option {
    border-bottom: 1px solid #DDD;
    color: black;
    font-family: Poppins,sans-serif;
    padding: 5px 10px;
    background-color: white;
    user-select: none;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
}

.auto-complete-option:hover {
    background-color: #3366FF;
    color: white;
}

.auto-complete-option:hover .prefix-input{
    color: white;
}


.prefix-input{
    color: #3366FF;
    font-weight: 600;
}