.header-cms-modal-background {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  animation: fadeOut 0.2s ease-out forwards;
}

.header-cms-modal-background-show {
  position: fixed;
  background-color: var(--primary-blue);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 100px;
  z-index: 998;
  width: 100%;
  animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  1% {
    visibility: visible;
  }

  100% {
    opacity: 0.98;
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0.98;
    visibility: visible;
  }

  99% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.header-cms-modal-background-show .treatments-modal {
  width: var(--desktop-col-12) !important;
  background-color: white;
  padding-block-end: 15px;
  display: list-item;
  border-radius: 30px 6px 30px 6px;
  height: fit-content;
}

.header-cms-modal-background-show .treatments-modal .trustpilot-widget {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 6px;
}

.header-cms-modal-background-show .treatments-container {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  padding: 60px 45px;
}

.header-cms-modal-background-show .treatments-dropdown-modal-active {
  background-color: transparent;
  color: var(--primary-white);
}

.header-cms-modal-background-show .root-category-pair {
  padding: 0 15px;
  text-wrap: nowrap;
}

.header-cms-modal-background-show .root-category {
  margin-bottom: 30px;
}

.header-cms-modal-background-show .root-category h4 {
  color: var(--primary-blue);
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
}

.header-cms-modal-background-show .root-category ul {
  list-style-type: none;
}

.root-category ul li {
  margin-bottom: 15px;
}

.header-cms-modal-background-show .treatment-category-link {
  color: var(--radiobutton-text-color) !important;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 15px;
}

.header-cms-modal-background-show .treatment-category-link>*:first-child {
  text-decoration: underline;
}

.header-cms-modal-background-show .treatment-category-link .has-menu-sticker {
  text-decoration-color: var(--primary-darkgreen) !important;
  text-decoration-thickness: 2px !important;
}

.categories_menu_sticker {
  background-color: var(--primary-darkgreen);
  font-size: 11px;
  border-radius: 3px;
  color: white;
  padding: 1px 7px;
  font-weight: 600;
  margin-inline-start: 0.5em;
}

.header-cms-modal-background-show .search-container {
  width: fit-content;
  display: flex;
  justify-content: center;
  height: fit-content;
}

.header-cms-modal-background-show .cancel-icon {
  z-index: 2000;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 20px;
  top: 20px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .header-cms-modal-background-show {
    padding: 100px 20px;
  }

  .header-cms-modal-background-show .search-container {
    width: 100%;
  }

  .header-cms-modal-background-show .treatments-modal {
    width: 100%;
    overflow-y: scroll;
    max-height: 80vh;
  }

  .header-cms-modal-background-show .treatments-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 764px) {
  .header-cms-modal-background-show {
    padding: 80px 20px;
  }

  .header-cms-modal-background-show .treatments-container {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    padding: 30px;
  }

  .header-cms-modal-background-show .cancel-icon {
    left: 10px;
  }
}