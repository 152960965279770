.shipping-page-body {
  display: flex;
  justify-content: center;
  background-color: var(--primary-gray);
  min-height: 100vh;
  width: 100%;
  padding: 60px 0px 90px;
  position: relative;
}


.shipping-page-container{
  width: var(--desktop-col-12);
  display: flex;
  flex-direction: column;
  align-items: center;
}


.shipping-page-panels-container{
  display: flex;
  width: 100%;
  gap: 50px;
  justify-content: center;
}

.shipping-page-main-title{
  font-size: 32px;
  color: var(--primary-blue);
  font-weight: 700;
  margin-bottom: 60px;
}

.responsive-order-window {
  display: none;
}

.shipping-instruction-title {
  font-size: 13px;
  color: #98a0af;
  margin-bottom: 15px;
  font-family: Roboto,sans-serif;
  font-weight: 700;
}

.shipping-page-container .left-panel{
  padding: 0;
  align-items: flex-start;
  width: var(--desktop-col-6);
}


.shipping-page-panels-container .right-panel{
  margin-top: 0;
  background-color: transparent;
  width: 375px;
  position: sticky; 
  height: fit-content;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shipping-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}


.payment-info-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  gap: 20px;
  width: 100%;
}

#shipping-sticky-submit-container {
  width: 100%;
  display: flex;
  background: white;
  box-shadow: 0 -10px 70px var(--primary-gray); 
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  right: 0;
  z-index: 99;
  padding: 1rem 0; 
}


#shipping-sticky-submit {
  width: 970px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}


.payment-info-container > .shipping-instruction-title{
  margin-bottom:0 !important;
}

.countryLayout-orderWindow{
  display: none;
  width: 100%;
  margin: 20px 0px;
  position: sticky;
}

.countryLayout-orderWindow > .orderWindow-container{
  padding: 0px;
} 



@media (max-width:520px) {
  .payment-info-container .payment-icons{
    margin-top: 10px;
    width: fit-content;
  }
}


@media (max-width: 767px) {

  .shipping-page-body{
    padding: 30px 0px 90px;
  }

  .shipping-page-main-title{
    font-size: 26px;
  }

  .details-form{
    display: flex;
    flex-direction: column;
  }
  
  .details-form > div{
    display: flex;
    flex-direction: column;
  }
  
  .shipping-page-container{
    width: 100%;
    padding: var(--mobile-container-padding);
  }

  .shipping-page-panels-container{
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .countryLayout-orderWindow{
    display: inline-block;
  }
  
  .countryLayout-orderWindow > .orderWindow-container{
    padding: 0 !important;
  }

  .shipping-page-panels-container .right-panel{
    display: none;
  }

  .left-panel,
  .shipping-container,
  .delivery-panel-outer-container{
    width: 100% !important;
  }


  .orderWindow-subscription-plans-header{
    justify-content: space-between;
  }

  .coupon-container{
    padding: 0 !important;
  }

  .orderWindow{
    padding: 0 20px 20px;
  }

  #shipping-sticky-submit {
    flex-direction: column;
    width: 100% !important;
    padding: 0px 15px;
    gap: 10px;
  }
  
  .details-form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100% !important;
  }

  .phone-icon-and-field{
    display: flex;
    flex-direction: column !important;
    width: 100%;
    gap: 0 !important;
  }

  .payment-information > div{
    display: flex;
    flex-direction: column !important;
  }



}


/* ---------End of Mobile rules--------- */

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {

  #shipping-sticky-submit{
    padding: 0px 15px;
    width: 100% !important;
  }

  .shipping-page-container{
    width: var(--desktop-col-10);
    padding: var(--tablet-container-padding);
  }

  .shipping-page-panels-container{
    flex-direction: column;
    align-items: center;
    width: var(--desktop-col-8);
    justify-content: space-between;
  }

  .countryLayout-orderWindow{
    display: inline-block;
  }

  .shipping-page-panels-container .right-panel{
    display: none;
  }

  .left-panel,
  .shipping-container,
  .delivery-panel-outer-container{
    width: 100% !important;
  }


  .orderWindow-subscription-plans-header{
    justify-content: space-between;
  }

  .coupon-container{
    padding: 0 !important;
  }


  .details-form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100% !important;
  }

  .phone-icon-and-field{
    display: flex;
    flex-direction: column !important;
    width: 100%;
    gap: 0 !important;
  }


  
}
