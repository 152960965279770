.order-otc-addons-container {
  padding: 15px;
  margin-top: -5px;
  background-color: white;
}

.order-otc-addons-container .otc-addons-product-rows {
  width: 100%;
  border-radius: 6px;
}

.order-otc-addons-title {
  color: var(--primary-blue);
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  margin-top: 10px;
  height: 36px;
}

.order-otc-addons-container .otc-addons-row-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.order-otc-addons-container .otc-addons-row {
  flex-direction: column;
}

.order-otc-addons-container .product-item-details-price-deleted {
  display: none;
}

.order-otc-addons-container .product-item-details-price {
  display: flex;
  flex-direction: column;
}

.order-otc-addons-container .otc-product-image {
  margin-right: 15px !important;
  width: 100px !important;
  height: 100px !important;
}

.order-otc-addons-container .otc-product-image-and-details {
  margin-right: 0 !important;
  margin-bottom: 10px;
}

.order-otc-addons-container .quantity-control-wrapper {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.order-otc-addons-container .quantity-control-wrapper .bin-icon {
  display: inline;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.order-otc-addons-container .quantity-control-wrapper .bin-icon:hover {
  opacity:0.8;
  transition: opacity 350ms ease-in-out;
  cursor: pointer;
}


.order-otc-addons-container .quantity-control-wrapper .bottom-row {
  margin-top: 0;
}

.order-otc-addons-container .product-add-to-order-button-mobile {
  display: block;
}

.order-otc-addons-container .product-add-to-order-button {
  display: none !important;
}

.order-otc-addons-container .product-add-to-order-button-mobile-enabled {
  display: flex;
}


.order-otc-addons-container .information-text {
  font-size: 14px;
}

.order-otc-addons-container .quantity-control-wrapper {
  width: 100%;
  justify-content: flex-end;
}

.order-otc-addons-container .product-item-details-price {
  margin-top: 0px;
}


.open-otc-addons-modal-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* Button */
.open-otc-addons-modal{
  margin: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  text-align: center;
  padding: 10px;
  outline: none;
  border: 1px solid var(--primary-lightgreen);
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-lightgreen);
  cursor: pointer;
  height: 36px;
  border-radius: 3px;

}




@media (max-width: 764px) {
  .order-otc-addons-container .product-item-details-price {
    flex-direction: row !important;
    align-items: center;
    gap: 5px;
  }
}