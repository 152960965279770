.questionnaire-photoSelection,
.questionnaire-photoSelection-error,
.questionnaire-photoSelection-selected{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--primary-white);
    margin-right: 15px;
    border: 1px solid var(--sub-gray);
    border-radius: 6px;
    padding: 27px 15px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .25s ease-in-out;
}

.questionnaire-photoSelection-selected{
    border: 1px solid var(--primary-lightgreen);
    box-shadow: 0 43px 70px rgb(152 149 169 / 15%);
}

.questionnaire-photoSelection-error{
    border: 1px solid var(--primary-danger);
}



.questionnaire-photoSelection-image{
    margin: 0px 21px;
}


.questionnaire-photoSelection-input{
    width:17px;
    height: 17px;
    background-color: var(--primary-white);
    outline: 2.5px solid var(--sub-gray);
    border-radius: 50%;
    margin-right: 10px;

}

.questionnaire-photoSelection-input-selected{
    width: 17px;
    height: 17px;
    background-color: var(--primary-lightgreen);
    border: 3px solid var(--primary-white);
    outline: 2.5px solid var(--primary-lightgreen);
    border-radius: 50%;
    margin-right: 10px;
}

.questionnaire-photoSelection-input-label-container{
    display: flex;
    margin-top: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.questionnaire-photoSelection-placeholder{
    font-family: Roboto,sans-serif;
    font-size: 16px;
    color: var(--primary-blue);
}