@import url("../../core.css");

.email-change-page{
flex-direction: column;
min-height: 100vh;
display: flex;
}

.email-change-image{
    width: 920px;
    height: auto;
}

.email-change-image-container {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    flex: 1;
  }

  .email-change-title{
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 200px;
    color: var(--primary-blue);
  }

  .email-change-page .footer-container{
    background-color: #fff;
  }

@media (max-width:1200px) {
    .email-change-image{
        width: 708px;
        height: auto;
    }
}

@media (max-width:764px) {
    .email-change-image{
        width: 100%;
        height: auto;
    }
    .email-change-title{
        font-size: 18px;
      }
}