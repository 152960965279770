.otc-addons-modal-container {
  padding: 60px 10px 10px 10px;
  background-color: white;
  border-radius: 6px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: var(--desktop-col-8);
  position: relative;
  max-height: 650px;
}



.otc-addons-modal-header{
  padding-top: 60px;
  padding-bottom: 15px;
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  position: sticky;
  background-color: white;
  top: 0;
}


.otc-addons-modal-product-rows{
  max-height: 650px;
  overflow-y: scroll;
  margin: 15px 0px;
  padding: 0px 15px;

}


.otc-addons-modal-product-rows::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

.otc-addons-modal-product-rows::-webkit-scrollbar-thumb {
  background-color: var(--primary-lightgreen);
  border-radius: 10px;
}

/* X ICON */
.otc-addons-modal-container > img {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 20px;
  text-align: center;
}

.otc-addons-modal-container h1 {
  color: var(--primary-blue);
}

.otc-addons-modal-container .otc-addons-row-container:last-of-type {
  border-bottom: none;
}


.otc-addons-modal-footer{
  width: 100%;
  padding: 10px;
}

.otc-addons-modal-submit{
  position: sticky;
  bottom: 0;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--primary-lightgreen);
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 6px;
  color: white;
  font-weight: 700;
  cursor: pointer;
}


.otc-addons-modal-submit:hover{
    background-color: #00ca94 !important ;
    transition: 0.35s ease;
}

@media (max-width: 764px) {
  .otc-addons-modal-container {
    padding: 60px 15px 15px 15px;
    width: var(--desktop-col-5);
  }

  .otc-addons-modal-container h1 {
    font-size: 26px;
    margin: 0;
    text-align: center;
  }

  /* X ICON */
  .otc-addons-modal-container > img {
    right: 15px;
  }
}

@media (max-width: 420px) {
  .otc-addons-modal-container {
    padding: 60px 10px 10px 10px;
  }


  /* X ICON */
  .otc-addons-modal-container > img {
    right: 10px;
  }
}
