.reorder-product-card {
  border: 1px solid #b4bfce;
  border-radius: 6px;
  width: var(--desktop-col-8);
  padding: 5px 30px;
  background-color: var(--primary-white);
  display: flex;
  flex-direction: row;
  filter: drop-shadow(0 5px 8px rgba(0, 0, 0, 0.06));
  gap: 30px;
}

.reorder-product-card .img-container {
  height: 100%;
  width: 250px;
}

.reorder-product-card .img-container > img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.reorder-product-card h3 {
  color: var(--primary-blue);
  font-size: 22px;
  font-weight: 700;
  margin-bottom: -5px;
}

.reorder-product-card .reorder-product-card-quantity {
  color: var(--primary-blue);
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.reorder-product-card .texts-container {
  margin-top: 20px;
  height: 130px;
  width: 100%;
  position: relative;
}

.reorder-product-card .total-price-row {
  bottom: 0px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 75%;
}

.reorder-product-card .total-price-row span {
  font-family: Roboto, sans-serif;
  color: var(--primary-darkblue, #0d2c54);
  font-size: 16px;
  font-weight: 700;
}

@media (max-width:764px) {

    .reorder-product-card{
        position: relative;
        padding-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .reorder-product-card h3{
        font-size: 16px
    }

    .reorder-product-card .texts-container{
        position: static;
    }

    .reorder-product-card .total-price-row{
        width: 100%;
        left: 0;
        padding: 0 20px;
        bottom: 10px;
    }

    .reorder-product-card .texts-container {
        height: fit-content;
    }

    .reorder-product-card .total-price-row span { 
        font-size: 12px !important;
        font-weight: 500 ;
        color: #747474;

    }
   
}