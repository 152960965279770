.option-content .radio .radio-selected {
    width: 30px;
    height: 30px;
}

.option-main-header-card {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #0d2c54;
    font-family: Poppins, sans-serif;
}


