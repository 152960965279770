@import url('../../../core.css');


.loading-container{
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
  }
  
  .loading {
    width: 92px;
    height: 92px;
    margin: 0 auto;
    position: absolute;
    top: calc(50% - 46px);
    left: 0;
    right: 0;
  }
  
  .loading-circle {
    border: 9px #cdcdcd solid;
    border-top: 9px #11DDAC solid;
    border-radius: 50%;
    -webkit-animation: spCircRot 0.8s infinite linear;
    animation: spCircRot 0.8s infinite linear;
  }
  
  @-webkit-keyframes spCircRot {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }

  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }