@import url("../../core.css");

/* addressValidation banner */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 44, 84, 0.36);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addressValidationModal {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-white) !important;
    border-radius: 6px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0px 60px 120px rgba(0, 0, 0, 0.15);
    opacity: 1 !important;
    padding: 30px;
    margin: auto;
    max-width: 540px;
    min-height: 276px;
    /* top: 50%; */
    bottom: auto !important;
    transform: translateY(-50%);
    z-index: 100000 !important;
  }

  #addressValidationModal-close-button{
    font-size: 16px;
    color: #99a0af;
    align-self: flex-end;
  }

  #addressValidationModal-close-button:hover{
    cursor: pointer;
  }

  .addressValidationModal.white {
    background-color: #fff !important;
    top: auto;
    transform: none;
    bottom: 300px !important;
    overflow: hidden;
    max-width: 700px;
    min-height: auto;
    overflow-y: hidden !important;
  }
  
  
  .addressValidationModal .agreement-info .title {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-blue);
    margin-bottom: 10px;
  }
  
  .addressValidationModal .agreement-info p {
    font-family: Roboto, sans-serif;
    font-size: 13px !important;
    line-height: 1.5 !important;
    color: var(--primary-white);
  }

  .addressValidationModal .agreement-info a:hover {
    text-decoration: none;
  }
  
  .addressValidationModal .buttons>* {
    width: 100%;
    min-width: auto;
    max-width: inherit !important;
  }

  #suggested-address-container {
    width: 100%;
    background-color: var(--primary-gray);
    min-height: 40px;
    padding: 20px;
    margin: 20px 0px 20px 0px;
    border-radius: 4px;
    align-items: center;
    display: flex;
  }

  #suggested-address {
    font-family: Roboto, 'sans-serif';
    color: var(--radiobutton-text-color);
    font-weight: 700;
  }

  .addressValidationModal .btn-addressValidationModal:hover {
    background-color: var(--primary-lightgreen);
    color: white;
    cursor: pointer;
  }

  .addressValidationModal .btn-addressValidationModal {
    margin: 0;
    width: 300px;
    font-size: 14px;
    height: 55px;
    padding: 0 20px;
    background-color: white;
    color: var(--primary-lightgreen);
    border-radius: 3px;
    border-width: 2px; 
    border-style: solid;
    border-color: var(--primary-lightgreen);
    font-weight: 700;
    margin: 0px 10px 0px 10px;
    transition-duration: 0.4s;
  }

  .btn-container-addressValidationModal {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  
  .addressValidationModal.white .agreement-info {
    font-size: 14px !important;
    font-family: Roboto, sans-serif;
    line-height: 1.5 !important;
    color: var(--radiobutton-text-color) !important;
    padding-right: 50px;
    justify-self: end;
  }



  @media only screen and (max-width: 767px) {
    .addressValidationModal .row {
      flex-direction: column;
    }
    .addressValidationModal.white {
      max-width: 350px;
      box-shadow: 0px 30px 120px rgba(2, 2, 2, 0.27);
      max-height: 95vh;
      overflow-y: auto;
      align-items: flex-start;
    }

    .addressValidationModal.white .agreement-info {
      margin-bottom: 15px;
      margin-right: 0;
      text-shadow: none;
      font-size: 12px;
      /* line-height: 1.5; */
      padding-right: 0px;
    }
    .addressValidationModal.white .agreement-info .title {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 5px;
    }

    .addressValidationModal .btn-addressValidationModal {
      margin: 0;
      width: 290px;
      font-size: 14px;
      margin-top: 10px;
      transition-duration: 0.4s;
    }
  
    .btn-container-addressValidationModal {
      align-items: center;
      justify-content: center;
      display: block;
    }

    #suggested-address-container {
      margin: 20px 0px 10px 0px;
    }
  }
  
