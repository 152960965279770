@import url('../../core.css');

.footer-container{
    display: flex;
    width: 100%;
    padding: 30px 0px;
    background-color: var(--primary-gray);
    justify-content: center;
    border-top: 1px solid #eef1f6;
}


.footer{
    width: var(--desktop-col-12) ;
    padding: 0px 30px;
    display: flex;
    align-items: center;
}


.footer-discount_disclaimer{
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    color: var(--radiobutton-text-color);
    max-width: 350px;
}

.footer-copyright{
    font-weight: 500;
    font-size: 14px;
    color: var(--questionnaire-select-placeholder);
}


.footer-return {
    color: var(--primary-lightgreen);
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.footer-return:hover{
    text-decoration: none;
}

.footer-text-container{
    display: flex;
    flex-direction: column;
    max-width: var(--desktop-col-6);
}


@media (max-width: 767px) {
    .footer{
        flex-direction: column;
    }

    .footer-return{
        margin-bottom: 10px;
    }

    .footer-discount_disclaimer,
    .footer-copyright,
    .footer-text-container{
        text-align: center !important;
    }
}