@import url("../../core.css");

.checkout-evaluation-body {
  background-color: var(--primary-gray);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 60px 0px 90px;
}

.checkout-evaluation-container {
  display: flex;
  width: var(--desktop-col-10);
  flex-direction: row;
  justify-content: space-between;
  gap: 45px;
}

.checkout-evaluation-mainTitle {
  color: var(--primary-blue);
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 50px;
  overflow-y: initial;
}

.checkout-evaluation-doctor-description > p {
  text-align: right;
  font-family: Roboto, sans-serif !important;
}

.checkout-evaluation-content-container > img {
  width: 72px;
  height: 72px;
}

.checkout-evaluation-doctor-img {
  width: 300px;
  height: 390px;
  object-fit: cover;
}

.checkout-evaluation-alert-container > img {
  width: 72px;
  height: 72px;
}

.checkout-evaluation-content-text {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: var(--radiobutton-text-color);
}

.checkout-evaluation-alert-container {
  display: flex;
  padding: 20px;
  margin: 30px 0px;
  flex-direction: row;
  background-color: var(--sub-danger);
  align-items: center;
}

/* Mobile */
@media (max-width: 767px) {
  .checkout-evaluation-body {
    height: fit-content;
  }

  .checkout-evaluation-container {
    flex-direction: column;
    padding: var(--mobile-container-padding);
    width: 100%;
    height: fit-content;
  }

  .checkout-evaluation-mainTitle {
    font-size: 24px;
    text-align: center;
  }

  .checkout-evaluation-alert-container {
    flex-direction: column;
    width: 100%;
  }

  .checkout-evaluation-content-text {
    min-width: 220px;
  }

  .checkout-evaluation-doctor-img {
    align-self: center;
    margin-bottom: 20px;
  }
}

/* ---------End of Mobile rules--------- */

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {

    
  .checkout-evaluation-body {
    height: fit-content;
  }
  .checkout-evaluation-container {
    flex-direction: column;
    display: flex;
    padding: var(--tablet-container-padding);
    width: 100%;
  }

  .checkout-evaluation-content-container {
    display: flex;
    flex-direction: column;
  }
  .checkout-evaluation-content-text {
    word-spacing: 100%;
  }

  .checkout-evaluation-alert-container {
    flex-direction: column;
    width: var(--desktop-col-8);
    align-self: center;
  }

  .checkout-evaluation-doctor-img {
    align-self: flex-end;
    margin-right: 55px;
  }
}
