.login-page{
    min-height: 100vh;
    display: flex;
    background-color: var(--primary-gray);
    flex-direction: column;
}

.login-content-container{
    flex: 1;
    padding: 120px 0px;
    background-color: var(--primary-white);
}

.login-content{
    display: flex;
    width: var(--desktop-col-12);
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
}

.login-form-container{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: var(--desktop-col-5);
}

.login-form-container-title{
    margin-bottom: 15px;
    color: var(--primary-blue);
    font-size: 32px;
    font-weight: 700;
    width: var(--desktop-col-6);
}

.login-form-container .forgot-password-button{
    margin: 20px 0px;
    color: var(--questionnaire-select-placeholder);
    font-weight: 500;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
}

.login-form-container .forgot-password-button:hover{
    text-decoration: none;
}


.login-form-container .new-to-doktorabc{
    font-size: 16px;
    font-family: Roboto,sans-serif;
    color: var(--primary-blue);
}

.login-form-container .register-text{
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    font-family: Roboto,sans-serif;
    color: var(--primary-lightgreen);
}

.login-form-container .register-text:hover{
    text-decoration: none;
}

.login-info{
    display: flex;
    flex-direction: column;
    width: var(--desktop-col-6);
    margin-left: 70px;
    padding: 15px;
    align-items: center;
}

.login-info-title{
    color: var(--primary-blue);
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
    max-width: 330px;
}

.login-info-cards-container{
    position: relative;
    padding: 0px 60px ;
    width: 100%;
}

.login-info-cards-container::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 60px;
    background-color: var(--primary-green);
    border-radius: 12px;
    -moz-border-top-left-radius: 60px;
    border-top-left-radius: 60px;
    -moz-border-bottom-right-radius: 60px;
    border-bottom-right-radius: 60px
}

.login-info-card{
    background-color: white;
    padding: 20px;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    gap: 20px;
    box-shadow: 0 20px 35px rgb(11 159 124 / 15%);
    border-radius: 12px;
    align-items: center;
}

#login-info-card-icon{
    width: 22px;
    height: 22px;
}


.login-info-card-content-title{
    color: var(--primary-lightgreen);
    font-family: Roboto,sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.login-info-card-content-content{
    font-size: 13px;
    font-family: Roboto,sans-serif;
    color: var(--radiobutton-text-color);
}

.login-page .footer-container{
    background-color: var(--primary-white);
}


@media (max-width:1200px){
    .login-content-container{
        padding: 90px 0px 60px;
    }

    .login-content{
        /* background-color: red; */
        flex-direction: column;
        padding: 0 15px;
        width: 100%;
    
    }

    .login-content .login-form-container{
        width: var(--desktop-col-8);
        margin-bottom: 60px;
    }

    .forgot-password-button{
        margin-top:15px ;
        margin-bottom: 30px;
        text-align: center;
    }

    .new-to-doktorabc{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        
    }

    .login-info{
        width: var(--desktop-col-8);
        margin-left: 0px;
        padding: 0;
    }

    .login-info-title{
        margin-bottom: 30px;
    }

    .login-info-cards-container::before{
        content: "";
        position: absolute;
        left: 30px;
        right: 30px;
        top: 0;
        bottom: 0;
        background-color: #e7fcf7;
        border-radius: 12px;
        -moz-border-top-left-radius: 60px;
        border-top-left-radius: 60px;
        -moz-border-bottom-right-radius: 60px;
        border-bottom-right-radius: 60px;
    }

    .login-info-cards-container{
        padding: 30px 0;
    }

}

@media (max-width:770px){

    .login-content-container{
        padding-top: 60px;
    }

    .login-content .login-form-container,.login-content .login-info{
        width: 100%
    }

    .login-info-card-content-content{
        display: none;
    }

    .login-form-container-title{
        white-space:inherit;
        font-size: 24px;
        align-self: center;
        text-align: center;
        max-width: 250px;
    }
}