.dosages-and-variants-section {
  padding: 30px ;
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: var(--desktop-col-5) !important;
  flex-direction: column;
  align-items: center;
}

.dosages-and-variants-section-hideable {
  border-radius: 5px;
  padding: 30px;
  background-color: white;
  margin-left: 5%;
  width: 600px;
  /* height: 700px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  /* justify-content: center; */
}

.select-title {
  text-align: left;
  font-size: 13px;
  color: #98a0af;
  font-weight: 700;
  margin-left: 10px;
  width: 100%;
  padding: 10px;
}

.mini-title {
  color: var(--primary-blue);
  font-size: 24px;
  font-family: Poppins, sans-serif;
  padding: 10px;
  padding: 0px 0px 30px;
}

.packets-container {
  width: 100%;
  margin-bottom: 20px;
  /* min-height: 250px; */
  background-color: white;
}

@media (width < 1023px) {
  .hideable {
    display: none;
  }

  .dosages-and-variants-section {
    max-height: 9999px;
    border-radius: 0;
    margin-left: 0;
    transition: max-height 0.2s ease-in;
    width: 100% !important;
  }

  .mini-title {
    display: none;
  }
}
