@import url("../../core.css");

.server-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 30px 0px 90px;
  width: 100%;
}


.server-error-uid {
  color:var(--questionnaire-select-placeholder);
  font-size: 14px;
}

.server-error-header{
  font-size: 18px;
  font-weight: 400;
  color: var(--radiobutton-text-color);
}


.server-error-message{
  color: var(--primary-blue);
  max-width: 75%;
}

#server-error-submit{
  border-radius: 6px;
  margin-top: 10px;
}

.server-error-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.server-error-container > img { 
  width: 492px;
  height: 261px;
}


@media (max-width: 767px) {
  .server-error-container {
    padding: var(--mobile-container-padding);
  }

  .server-error-container > img {
    width: 335px;
    object-fit: contain;
  }

  .server-error-text-container{
    width: 100%;
  }

  .server-error-message{
    color: var(--primary-blue);
    max-width: 100%;
    font-size: 22px;
  }
  



  #server-error-submit{ 
    width: 100% !important;
  }

}

@media (min-width: 768px) and (max-width: 1023px) {
  .server-error-container {
    padding: var(--tablet-container-padding);
  }

  .server-error-text-container{
    width: 100%;
  }

}
