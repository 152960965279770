.account-subscription-order-card{
    margin-bottom: 30px;
    border-radius: 6px;
    border: 1px solid #eef1f6 ;
}

.account-subscription-order-card-head{
    background-color: white;
    display: flex;
    align-items: flex-start;
    padding: 20px;
    position: relative;
    border-radius: 6px;
}

.account-subscription-order-card-head .account-subscription-order-card-img-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex:1;
    position: relative;
    word-break: break-all;
}

.order-subscription-prices{
    text-align: right;
}

.order-subscription-price{
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-blue);
}

.order-regular-price{
    font-size: 14px;
    text-decoration: line-through;
    color: var(--primary-blue);
}


.account-subscription-order-card-head .order-title {
    font-size: 15px;
    font-weight: 700;
    color: var(--primary-blue);
}


.account-subscription-order-card-head  .product-img {
    margin-right: 20px;
}

.account-subscription-order-card-head  .product-img > img{
    aspect-ratio: 1px;
    width: 100px;
    object-fit: cover;
}

.account-subscription-order-card-bottom{
    background-color: white;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eef1f6;
    gap: 5px;
}

.account-subscription-order-card-bottom ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
}

.account-subscription-order-card-bottom ul li{
    text-align: left;
    margin-right: 30px;
}


.account-subscription-order-card-bottom .adminster-container{
    display: flex;
    align-items: center;
    gap: 10px;
}


.account-subscription-order-card-bottom .adminster-container > a{
    font-size: 13px;
    color: var(--primary-lightgreen);
    text-decoration: underline;
}

.account-subscription-order-card-bottom .adminster-container > a:hover{
    text-decoration: none;
    cursor: pointer;
}

.account-subscription-order-card-bottom .adminster-container > img{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.account-subscription-order-card-bottom .order-info-field{
    margin-bottom: 5px;
    white-space: nowrap;
}

.account-subscription-order-card-bottom .order-info-field-value{
    white-space: break-all;
}





@media (max-width: 1200px){

    .order-subscription-prices{
        position: absolute;
        right: 20px;
        word-break: break-all;
    }

    .account-order-card-head{
        justify-content: space-between;
    }
    
  
    /* .account-order-card-head .account-order-card-head-container{
        flex-direction: column;
    } */

    .order-prices{
        position: absolute;
        right: 0;
        top: 0;
        word-break: break-all;
        left: 120px;
    }

    .adminster-container{
        top: 20px;
        right: 20px;
        position: absolute;
    }

    .account-subscription-order-card-bottom ul {
        flex-direction: column;
        max-width: 300px;
    }

    .account-subscription-order-card-bottom ul li{
        margin-bottom: 15px;
    }
}


@media (max-width: 600px){

    .account-subscription-order-card-head .account-subscription-order-card-img-title{
        flex-direction: column;
    }

}