   /* @tailwind base; */
   @tailwind components;
   @tailwind utilities;

   ::-webkit-scrollbar {
       width: 5px;
       background-color: rgba(17, 221, 172, 0.2);
       border-radius: 5px;
   }

   ::-webkit-scrollbar-thumb {
       border-radius: 3px;
       background-color: #11ddac !important;
       color: #11ddac !important;
   }

   .grow-anim {
       animation: scroll_down 8s forwards;
   }

   @keyframes scroll_down {
       0% {
           max-height: 0;
       }

       100% {
           max-height: 9999px;
       }
   }