.registration-form{
    display: flex;
    flex-direction: column;
    width: var(--desktop-col-6)
}

.registration-form > .binarySelect-container{
    margin-bottom: 25px !important;
}

.registration-form-row{
    display: flex;
    gap: 15px;
    margin: -5px 0px;
}

.registration-emailInput{
    grid-column-start: 1;
    grid-column-end: 3;
}

.registration-password-info{
    color: var(--questionnaire-select-placeholder);
    font-size: 12px;
    font-family: Roboto,sans-serif;
}

.registration-birthday-title,.registration-gender-title{
    font-size: 13px;
    font-family: Roboto,sans-serif;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 25px;
    color: var(--questionnaire-select-placeholder);
}


.form-mandatory-astrix{
    color: var(--primary-danger);
}

.registration-agreement-checkbox-container{
    display: flex;
    justify-content: flex-start;
    margin: 15px 0px;
}


.registration-agreement-checkbox-container > span {
    font-size: 12px;
    font-family: Roboto,sans-serif;
    color: var(--radiobutton-text-color);
}

.registration-agreement{
    font-family: Roboto,sans-serif;
    font-size: 12px;
    color: var(--questionnaire-select-placeholder);
    margin-bottom: 30px;
    max-height: 120px;
    overflow-y: scroll;
}

.registration-agreement::-webkit-scrollbar{
    width: 6px;
    height: 3px;
}

.registration-agreement::-webkit-scrollbar-thumb{
    background-color: var(--primary-lightgreen);
    border-radius: 10px;
}

.registration-agreement > u > a{
    color: var(--primary-lightgreen);
    font-size: 12px;
    text-decoration: none;
}

.registration-agreement > u:hover{
    text-decoration: none;
}


.registration-agreement-checkbox-container{
    display: flex;
    margin-left: -5px;
}

.registration-agreement-checkbox-container > span > a {
    color: var(--primary-lightgreen);
    font-size: 12px;
}

.registration-agreement-checkbox-container > span > a:hover{
    text-decoration: none;
}

@media screen and (max-width: 1023px) {
    .registration-gender-title{
        margin-top: 0px;
        margin-bottom: 5px;
    }
    .registration-birthday-title{
        margin-top: 0px;
        margin-bottom: 5px;
    }
}











