.pharmacy-request-modal-background{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(24, 39, 59, 0.70);
  position: fixed;
  z-index: 400;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: var(--tablet-container-padding);
}

a {
  color: #11ddac;
}

.pharmacy-request-modal-container {
  display: flex;
  flex-direction: column;
  width: 498px;
  position: relative;
  padding: 60px 40px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 6px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  gap: 15px;
}

.pharmacy-request-text-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}


.pharmacy-request-modal-container .close-btn{
  position: absolute;
  right: 25px;
  top: 25px;
  width: 20px;
  cursor: pointer;
}


.pharmacy-request-modal-container > h6 {
  font-family: Roboto,sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0D2C54;
}

.pharmacy-request-note {
  
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #616161;
}



.pharmacy-request-modal-container .confirm-btn {
  background-color: var(--primary-lightgreen);
  color: white;
  padding: 10px 45px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pharmacy-request-modal-container .confirm-btn:hover{
  opacity: 0.85;
}

.pharmacy-info {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #616161;

}



@media (max-width:764px) {
  .pharmacy-selection-modal-background{
      padding: 0 15px;
  }

  .pharmacy-request-modal-container {
      width: 100%;
  }

  .pharmacy-request-modal-container .confirm-btn {
      padding: 15px 45px;
  }

}