.account-page {
    min-height: 100vh;
    display: flex;
    background-color: var(--primary-gray);
    flex-direction: column;
    overflow: hidden;
}

.account-container {
    min-height: 500px;
    flex: 1;
    overflow-x: hidden;
    padding: 90px 0px;
    display: flex;
    justify-content: center;
}

.account-asidebar-and-content {
    display: flex;
    width: var(--desktop-col-12);
    background-color: var(--primary-gray);
    height: 100%;
}

.account-asidebar-and-content a {
    text-decoration: none;
}

.account-asidebar-and-content a:hover {
    text-decoration: underline;
}

.account-content {
    padding: 0 15px;
    display: flex;
    width: 100%;
    flex-direction: column;
}

/* .account-content .prescription-row-container:first-of-type{
    margin-top: 20px !important;
} */


.account-content #icon-trigger {
    text-decoration: underline;
    user-select: none;
    color: #7D4646 !important;
}

.account-content #icon-trigger:hover {
    text-decoration: none;
    cursor: pointer
}


/* warning disclaimer link in ask a doctore  */
.account-content #warning-disclaimer-link {
    color: #867150 !important;
    text-decoration: underline;
}

.account-content #warning-disclaimer:visited {
    color: #867150  !important;
}

.account-content #warning-disclaimer-link:hover , #warning-disclaimer:visited:hover{
    text-decoration: none;
    cursor: pointer
}


/* popup disclaimer link in ask a doctore  */
.account-content #popup-disclaimer-link {
    color: #7D4646 !important;
    text-decoration: underline;
}

.account-content #popup-disclaimer:visited {
    color: #7D4646 !important;
}

.account-content #popup-disclaimer-link:hover , #popup-disclaimer:visited:hover{
    text-decoration: none;
    cursor: pointer
}


@media (max-width: 1200px) {

    .account-container {
        padding-top: 0 !important;

    }

    .account-asidebar-and-content {
        flex-direction: column;
        width: var(--desktop-col-8);
    }

    .account-other-orders {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .other-orders-country {
        margin-top: 5px;
    }
}


@media (max-width: 768px) {

    .account-asidebar-and-content {
        width: 100%
    }

    .account-content {
        padding: 0px 15px 0px 15px;
    }

}