@import url("../../../../core.css");

.questionnaire-textarea-question {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.questionnaire-textarea-question textarea {
  background-color: white !important;
}

.questionnaire-question-item-container {
  padding: 30px 0px;
  width: 100%;
  border-top: 1px solid #dbe1eb;
  font-size: 15px;
}

.questionnaire-question-item-column,
.questionnaire-question-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.questionnaire-question-item-column {
  align-items: flex-start;
  flex-direction: column;
}

.questionnaire-question-item-column
  .questionnaire-answers-container:has(
    .questionnaire-photoDiganose-draganddrop-container
  ) {
  width: 100%;
}

.questionnaire-photoDiganose-draganddrop {
  padding: 40px;
}

.questionnaire-questions-group-headline {
  padding: 90px 0px 30px;
  border-top: 1px solid #dbe1eb;
  color: var(--primary-blue);
}

.questionnaire-question-item-text-container {
  font-family: Roboto, sans-serif;
  max-width: 630px;
  font-size: 15px;
  color: var(--primary-blue);
  text-overflow: initial;
}

.questionnaire-question-item-helpblock-container {
  font-family: Roboto, sans-serif;
  max-width: 600px;
  font-size: 14px;
  color: var(--radiobutton-text-color);
}

.questionnaire-question-item-helpblock-container:empty {
  display: none;
}

.questionnaire-question-item-text-container > p {
  font-family: Roboto, sans-serif;
  color: var(--primary-blue);
  margin-bottom: 15px;
}

.questionnaire-question-item-text-container > p > a {
  font-family: Roboto, sans-serif;
  color: var(--radiobutton-text-color);
  font-size: 12px;
  margin-bottom: 15px;
  text-decoration: underline;
}

.questionnaire-question-item-text-container > p > a:hover {
  text-decoration: none;
}

.questionnaire-question-item-text-container > ul {
  padding-left: 14px;
}

.questionnaire-question-item-text-container > ul > li {
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  max-width: 550px;

  font-weight: 400;
  color: var(--radiobutton-text-color);
}

.questionnaire-info-wrap {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--sub-gray);
  font-family: sans-serif;
  font-size: 13px;
  color: var(--radiobutton-text-color);
}

.questionnaire-info-wrap::before {
  content: "";
  width: calc(100% - 20px);
  height: 20px;
  position: absolute;
  left: 1px;
  background-color: #f7f7f7;
  z-index: 1;
  pointer-events: none;
}

.questionnaire-info-wrap::after {
  content: "";
  width: calc(100% - 20px);
  height: 20px;
  position: absolute;
  left: 1px;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 1;
  pointer-events: none;
}

.questionnaire-info-scroll {
  padding: 20px;
  width: fit-content;
  max-height: 100px;
  overflow-y: scroll;
}

.questionnaire-info > p > a {
  font-family: sans-serif;
  font-size: 13px;
  color: var(--radiobutton-text-color);
}

.questionnaire-info > p > a:hover {
  text-decoration: none;
}

.questionnaire-info > p {
  font-family: sans-serif;
  font-size: 13px;
  color: var(--radiobutton-text-color);
}

.questionnaire-info-scroll::-webkit-scrollbar {
  width: 4.5px;
  margin-right: 55px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  /* background: var(--radiobutton-text-color); */
  background-color: var(--questionnaire-scrollbar-gray);
  border-radius: 10px;
}

.questionnaire-question-item-text-container > a {
  color: var(--primary-blue);
  font-family: Roboto, sans-serif;
}

.questionnaire-question-item-text-container > a:hover {
  text-decoration: none;
}

.questionnaire-answers-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-width: fit-content;
}

.questionnaire-answers-container:has(.questionnaire-textarea-question) {
  width: 100%;
}

/* only for row question item containers */
.questionnaire-question-item > .questionnaire-answers-container {
}

.questionnaire-answers-checkbox-list {
  margin-top: 10px;
}

.questionnaire-subquestion-container {
  background-color: var(--primary-white);
  border-radius: 12px;
  width: 100%;
  align-self: center;
  padding: 30px;
  margin-top: -10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.questionnaire-subquestion-container:empty {
  display: none;
}

.questionnaire-answers-photoSelection-container {
  display: flex;
  gap: 20px;
  align-self: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 30px;
}

.questionnaire-subquestion-container .questionnaire-select-dropdown-container{

  width: 40% !important;
}

@media (max-width: 764px) {
  .questionnaire-subquestion-container .questionnaire-select-dropdown-container{
    width: 100% !important;
  }

  .questionnaire-answers-container {
    width: 100%;
    flex-direction: column;
  }

  .radio-button-container{
    width: 100% !important;
  }

  .questionnaire-subquestion-container{
    padding: 30px 15px !important;
  }
}

