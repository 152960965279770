.chat-and-input-margin-off .chat-history::-webkit-scrollbar {
    border-radius: 10px;
    height: 10px;
    width: 6px;
    background: rgba(152, 160, 175, .2)
}

.chat-and-input-margin-off .chat-history::-webkit-scrollbar-thumb {
    background: #98a0af;
    border-radius: 10px
}


.conversation-container {
    border: 1px solid var(--primary-lightgreen);
    background-color: white;
    border-radius: 5px;
    display: flex;
}

.chat-and-input {
    width: 100%;
    margin: 0px 20px;
}

.chat-and-input-margin-off {
    width: 60%;
    border-right: 1px solid #eef1f6;
}

.chat-and-input-margin-off .chat-history {
    padding: 30px;
    font-size: 15px;
    color: var(--primary-blue);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 365px;
    overflow-y: auto;
}

.chat-and-input-margin-off .ask-doctor-outer-bar {
    border-top: 1px solid #eef1f6;
    flex: 1;
}

.historical-conversation-accordion {
    background-color: white;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    border: 1px solid #eef1f6;
    border-radius: 6px;
    cursor: pointer;
}

.historical-conversation-accordion .conversation-container {
    border: none;
}

.historical-conversation-title {
    padding: 20px;
    display: flex;
    border-bottom: 1px solid #eef1f6;
    justify-content: space-between;
    align-items: center;
}

.left-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.right-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.issue {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #0D2C54;
    font-weight: 600;
}

.time {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #98A0AF;
    font-weight: 400;
    line-height: 22.5px;
}

.historical-conversation-title img {
    width: 22px;
    height: 22px;
}

.subject-title-filled {
    border-bottom: 1px solid #eef1f6;
    width: 100%;
    padding: 10px;
    color: #0D2C54;
    font-weight: 600;
}


@media (max-width:1200px) {
    .conversation-container {
        flex-direction: column;
    }

    .chat-and-input-margin-off {
        width: 100%;
    }

    .chat-history {
        padding: 20px !important;
    }

    .chat-and-input {
        margin: 0;
    }

    .issue {
        font-family: Roboto, sans-serif;
        font-size: 15px;
        color: #98A0AF;
        font-weight: 600;
        line-height: 22.5px;
    }

    .time {
        font-family: Roboto, sans-serif;
        font-size: 12px;
        color: #98A0AF;
        font-weight: 400;
        line-height: 18px;
    }

}