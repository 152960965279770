/* SubscriptionDisclaimer */
.subscription-disclaimer{
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 6px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: var(--primary-blue);

}

.subscription-disclaimer-head{
    display: flex;
    margin-bottom: 20px;
}

.subscription-disclaimer-title-and-description{
    color: white;
    margin-right: 30px;
    flex: 1;
}

.subscription-disclaimer-title{
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: 700;
}

.subscription-disclaimer-description{
    font-family: Roboto,sans-serif;
    font-size: 14px;
    margin-right: 30px;
}


.banner-container{
    display: block;
    width: fit-content;
}


.banner{
    display: inline-block;
    padding: 5px 10px;
    background-color: var(--primary-danger);
    color: #fff;
    border-radius: 100px;
    font-family: Roboto,sans-serif;
    letter-spacing: .03em;
    font-weight: 700;
    font-size: 12px;
    font-size: .75rem;
    text-transform: uppercase;
    margin: 0 0 10px;
}


.subscription-disclaimer-bottom-title{
    display: flex;
    align-items: center;
    color: white;
    font-weight: 700;
    justify-content: flex-start;
    width: fit-content;
    cursor: pointer;
    user-select: none;
}

.chevron,.chevron-up,.chevron-down{
    margin: 0px 5px 2px;
}

.chevron-up{
    transition: all 250ms ease-in-out;
    transform: rotate(180deg);
}

.chevron-down{
    transition: all 250ms ease-in-out;
}


.subscription-disclaimer-bottom-description{
    font-size: 14px;
    font-family: Roboto,sans-serif;
    color: white;
    margin: 5px 0px 10px;
}


.disclaimer-show-subscription-products{
    user-select: none;
    color: white;
    text-decoration: underline;
    cursor: pointer;
    font-family: Roboto,sans-serif;
    font-size: 14px;
 }

.disclaimer-show-subscription-products:hover{
    text-decoration: none;
 }

 /* for innerhtml rendering styling */
 .subscription-disclaimer-bottom-description > div > div { 
    font-family: Roboto,sans-serif !important;
 }

 
@media(max-width: 1200px){
    .subscription-disclaimer{
        padding: 20px;
        border-radius: 6px !important;
    }

    .subscription-disclaimer-description,
    .subscription-disclaimer-title-and-description{
        margin: 0;
    }

    .subscription-disclaimer-title{
        font-size: 18px;
    }
}


@media (max-width: 768px){

    .subscription-disclaimer-head{
        flex-direction: column-reverse;
    }
    

    .subscription-disclaimer-bottom-title{
        font-size: 14px;
    }

    .subscription-disclaimer-bottom-title .chevron-up,
    .subscription-disclaimer-bottom-title .chevron-down{
        width: 20px;
        height: 20px;
    }

}





/* End of SubscriptionDisclaimer */