.drug-packs-choice-inner-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 25px;
  gap: 10px;
  flex-wrap: wrap;
}

.drug-packs-choice-inner-container-col-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 25px;
  gap: 10px;
  flex-wrap: wrap;
}

.drug-packs-choice-inner-container-col-layout .pack-item,
.drug-packs-choice-inner-container-col-layout .pack-item-selected {
  width: 100%;
  height: 60px;
}

.col-layout-custom-quantity {
  font: 14px Poppins, sans-serif;
  font-weight: 500;
}

.custom-quantity {
  font-size: 12px;
  margin-bottom: 10px;
  color: #98a0af;
}

.packet-price {
  font-weight: 500;
  font-size: 12px;
}

.pack-item,
.pack-item-selected {
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 115px;
  height: 115px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 1px solid #eef1f6;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  color: gray;
}

.out-of-stock {
  background-color: rgb(240, 237, 237) !important;
  cursor: auto;
}

.spared {
  font-size: 11px;
  font-family: Poppins, sans-serif;
  color: #656d79;
}

.pack-item-selected {
  color: var(--primary-blue) !important;
  background-color: #e7fcf7;
  border: 1px solid #11ddac;
}

.package-item-container {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

.col-layout-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
}

.row-product {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px;
}

.row-product .packet-price {
  font-size: 14px;
}

.check-mark-pack {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--primary-lightgreen);
  filter: drop-shadow(0px 10px 15px rgba(17, 221, 172, 0.3));

  font-size: 32px;
}

.quantity-type {
  font-size: 12px;
}

.quantity {
  font-size: 18px;
  font-weight: 500;
}

@media (width < 1023px) {
  .pack-item,
  .pack-item-selected {
    width: 115px !important;
    border-radius: 5px;
  }
}

@media (width < 768px) {
  .pack-item,
  .pack-item-selected {
    width: 110px !important;
    height: 110px !important;
    border-radius: 5px;
  }
}
