.change-password-form {
    background-color: white;
    padding: 30px;
    border: 1px solid #eef1f6;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.change-password-form .inputs-container {
    display: flex;
    margin-bottom: 20px;
    width: 100%;
}

.change-password-form .inputs-container {
    gap: 15px;
}

#change-password-submit {
    align-self: flex-end !important;
}

.change-password-form .inputfield-visibility-icon {
    display: none;
}

@media (max-width: 1200px) {
    .change-password-form {
        padding: 20px;
    }

    .change-password-form .inputs-container {
        flex-direction: column;
    }

    .change-password-form .inputfield-container{
        margin: 0;
    }

    #change-password-submit {
        width: 100% !important;
    }
}


@media (min-width: 1201px){
    .change-password-form  .inputfield-container .inputfield-placeholder{
        max-width: 250px !important;
        text-overflow: ellipsis !important;
    }
}
