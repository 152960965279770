.thankyou-title {
  font-size: 32px;
  color: var(--primary-blue);
  font-weight: 700;
}

.body-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.thankyou-container{
    width: var(--desktop-col-10);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

#trustbadgeCustomCheckoutContainer{
  margin-top: 60px;
}

.thankyou-titles-container{
    padding: 15px;
    margin-bottom: 10px;
}

.thankyou-subtitle{
    color: var(--primary-blue);
    font-size: 16px;
    font-family: Roboto,sans-serif;
}

.thankyou-steps-container{
    display: flex;
    flex-direction: column;
    padding: 60px 55px;
    background-color: var(--primary-green);
    border-top-left-radius: 60px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 60px;
    width: var(--desktop-col-12);
    border-bottom-left-radius: 6px;
}

.thankyou-steps-title{
    color: var(--primary-lightgreen);
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
}




.thankyou-steps{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 60px;
}

.thankyou-step{
    margin-right: 30px;
    flex-direction: column;
    display: flex;
    width: 350px;
    align-items: center;
    justify-content: center;
    height: 100%;
    justify-content: flex-start;
    align-items: flex;
}



.thankyou-step-circle{
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    color: var(--primary-white);
    font-weight: 600;
    border-radius: 100%;
    margin-bottom: 15px;
    background-color: var(--primary-blue);
}


.thankyou-step-title{
    font-family: Roboto,sans-serif;
    font-size: 15px;
    color: var(--primary-blue);
    font-weight: 600;
    margin: 0px 0px 5px 0px

}

.thankyou-step-content{
    font-family: Roboto,sans-serif;
    font-size: 14px;
    max-width: 100%;
    color: var(--radiobutton-text-color);
}

@media (max-width: 767px) {

    
    .body-container {
        padding: 10px 0;
      }
    
      .thankyou-container {
        width: 100%;
      }
    
      .thankyou-steps-container {
        width: 100%;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        padding: 60px 25px;
      }
      

      .thankyou-steps{
        width: 100%;
        flex-direction: column;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      .thankyou-step {
        width: 100%;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        margin: 15px 0px;
      }

}