@import url('../../core.css');

.sumbitButton-container-loading,
.sumbitButton-container{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 56px;
    padding: 0 40px;
    border-radius: 3px;
    border: 0;
    text-align: center;
    cursor: pointer;
    min-width: 250px;
    word-break:normal;
    background-color: var(--primary-lightgreen);

}

.sumbitButton-container-loading{
    background-color: rgba(17,221,172,.3) !important
}

.sumbitButton-container-loading >span,
.sumbitButton-container > span{
    display: flex;
    align-items: center;
}

.button-placeholder{
    font-size: 16px;
    font-family: Poppins,sans-serif;
    font-weight: 700;
    color: white;
}

.sumbitButton-container:hover{
    background-color: #00ca94 !important ;
    transition: 0.35s ease;
}

.sumbitButton-container-loading:hover{
    cursor:not-allowed;
}

.submit-button-disabled,
.submit-button-disabled:hover{
    background-color: rgba(17,221,172,.8)!important;
    cursor: not-allowed;
    opacity: 0.6;
}

/* .sumbitButton-container:active,
.sumbitButton-container:target{
    background-color: #bfffee !important;
    transition: 0.1s ease !important;
} */

@media (max-width:640px){
    .sumbitButton-container{
        min-width: 100% !important;
    }
}


