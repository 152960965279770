

.numberInput-container{
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color: #0d2c54;
    border-color: #98a0af;
    height: 42px;
    border-radius: 3px;
    padding: 10px 15px;
    background-color: transparent;
    width: auto;
    border: 1px solid var(--questionnaire-select-placeholder);
    outline: none;
    overflow: none;
    transition: all .25s ease-in-out;
    -moz-appearance: textfield;
}


.numberInput-container::-webkit-outer-spin-button,
.numberInput-container::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.numberInput-error-container{
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    border-color: #98a0af;
    color: var(--primary-danger);
    border: 1px solid var(--primary-danger);
    background-color: var(--sub-danger);
    height: 42px;
    border-radius: 3px;
    padding: 10px 15px;
    outline: none;
    overflow: none;
    transition: all .25s ease-in-out;
    -moz-appearance: textfield;

}

.numberInput-error-container::selection{
    color: var(--primary-danger)
}

.numberInput-error-container::-webkit-outer-spin-button,
.numberInput-error-container::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
}
