@import url('../../../../core.css');



.questionnaire-subquestion{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
    gap: 5px;
}


.questionnaire-answers-container:empty{
    display: none;
}


.questionnaire-subquestion-text{
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins,sans-serif;
    color: var(--radiobutton-text-color);
    margin-bottom: 15px;
}