@import url("../../../core.css");

.forgotPasswordModal-background {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--primary-blue);
  opacity: 0.98;
  display: flex;
  align-items: center;
  justify-content: center;

}

.forgotPasswordModal {
  position: relative;
  border-radius: 6px;
  padding: 30px;
  width: 460px;
  z-index: 1001;
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
  flex-direction: column;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
}

.forgotPasswordModal-title {
  
  text-align: center;
  font-size: 28px;
  color: var(--primary-blue);
  font-weight: 700;
  margin-bottom: 15px;
}

.forgotPasswordModal-title-sent {
  color: #11ddac;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}
.forgotPasswordModal-text {
    font-size: 15px;
    color: var(--radiobutton-text-color);
    font-family: Roboto, 'sans-serif';
    /* font-weight: 300; */
    margin-bottom: 15px;
    text-align: center;
}

.forgotPasswordModal-logo{
  margin-bottom: 30px;
  width: 48px;
  height: 48px;
}

.forgotPasswordModal > a {
  text-align: center;
}

.forgotPasswordModal-x{
  width: 20px;
  height: 20px;
  font-size: 10px;
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
}

@media (max-width: 767px){
  .forgotPasswordModal-title {
    font-size: 20px;
  }
}
