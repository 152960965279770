.skip-subscription-modal-background-white {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    z-index: 400;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: var(--tablet-container-padding);
}

.skip-subscription-modal-background-blue{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(13, 44, 84, 0.9);
    position: fixed;
    z-index: 400;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: var(--tablet-container-padding);
}

.skip-subscription-white-modal-popup-container{
    display: flex;
    flex-direction: column;
    width: 540px;
    position: relative;
    padding: 50px;
    background-color: white;
    text-align: center;
    border-radius: 6px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.skip-subscription-white-modal-popup-container .close-btn{
    position: absolute;
    right: 25px;
    top: 25px;
    width: 20px;
    cursor: pointer;
}

.skip-subscription-white-modal-popup-container > h4 {
    font-size: 24px;
    font-weight: 700;
    color: var(--primary-blue);
    margin-bottom: 15px;
    text-align: left;
}

.skip-subscription-white-modal-popup-container > p {
    font-weight: 400;
    font-size: 14px;
    color: var(--primary-blue);
    font-family: Roboto,sans-serif;
    text-align: left;
}

.skip-subscription-modal-popup-container{
    display: flex;
    flex-direction: column;
    width: 540px;
    position: relative;
    padding: 30px;
    background-color: var(--primary-blue);
    text-align: center;
    border-radius: 6px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.skip-subscription-modal-popup-container .close-btn{
    position: absolute;
    right: 25px;
    top: 25px;
    width: 20px;
    cursor: pointer;
}

.skip-subscription-modal-popup-container > h4 {
    font-size: 24px;
    font-weight: 700;
    color: white;
    margin-bottom: 20px;
    text-align: left;
}

.skip-subscription-modal-popup-container > p {
    font-weight: 400;
    font-size: 14px;
    color: white;
    font-family: Roboto,sans-serif;
    margin-bottom: 10px;
    text-align: left;
}

.skip-subscription-modal-button-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.skip-subscription-modal-popup-container .sumbitButton-container{
    min-width: 230px;
    border-color: var(--primary-lightgreen);
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;
    background-color: var(--primary-blue);
}

.skip-subscription-modal-popup-container .sumbitButton-container:hover{
    background-color: var(--primary-lightgreen);
}

.skip-subscription-modal-popup-container .sumbitButton-container .button-placeholder{
    color: var(--primary-lightgreen);
}

.skip-subscription-modal-popup-container .sumbitButton-container:hover .button-placeholder{
    color: white;
}

.skip-subscription-white-modal-popup-container #subscription-delivery-address-submit{
    min-width: 440px;
}

.skip-subscription-cancel-subscription{
    align-self: center;
    text-decoration-line: underline;
    margin-top: 20px;
    color: var(--radiobutton-text-color) !important;
}

.skip-subscription-cancel-subscription:hover{
    cursor: pointer;
}

@media (max-width: 767px) {
    .skip-subscription-modal-button-container{
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .skip-subscription-white-modal-popup-container #subscription-delivery-address-submit{
        min-width: 150px !important;
        height: fit-content !important;
        min-height: 45px;
    }

    .skip-subscription-modal-background-white {
        padding: var(--mobile-container-padding);
    }
    
    .skip-subscription-modal-background-blue{
        padding: var(--mobile-container-padding);
    }
}