.otc-addons-row-container {
  border-bottom: 1px solid var(--sub-gray);
  padding: 10px 0px;
  width: 100%;
}

.otc-addons-row {
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
}

.otc-addons-row .otc-product-image-and-details {
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 100%;
}

/* ProductItem image */
.otc-addons-row .otc-product-image {
  width: 142px;
  height: 142px;
  margin-right: 33px;
}

.otc-product-item-details {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  position: relative;
  width: 100%;
}

.product-item-details-title {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-blue);
}

.product-item-details-quantity {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  color: var(--radiobutton-text-color);
}

.product-item-details-price-deleted,
.product-item-details-price {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  font-weight: 700;
  margin-top: 15px;
  color: var(--primary-blue);
}

.product-item-details-price-discount {
  font-size: 12px;
  color: #0A9281;
}

.product-item-details-price-deleted {
  color: var(--radiobutton-text-color);
  text-decoration: line-through;
  margin-top: 0 !important;
  font-size: 14px;
}

.quantity-control-wrapper {
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
}

.quantity-control-wrapper .product-information-button,
.quantity-control-wrapper .product-information-button-enabled {
  background-color: transparent;
  color: var(--primary-lightgreen);
  outline: none;
  border: 1px solid var(--primary-lightgreen);
  font-weight: 700;
  border-radius: 3px;
  text-align: center !important;
  cursor: pointer;
  font-size: 14px;
  height: 37px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* I ICON  */
.product-information-button img,
.product-information-button-enabled img {
  width: 30px;
  height: 30px;
}

/* information button enabled */
.quantity-control-wrapper .product-information-button-enabled {
  background-color: var(--primary-lightgreen);
  color: var(--primary-white);
  align-items: center;
}

.product-add-to-order-button {
  width: 148px;
}

/* for Active icon inside the bu */
.product-add-to-order-button-mobile-enabled img,
.product-add-to-order-button img {
  width: 50px;
  height: 50px;
  margin-top: 5px;
  margin-right: -5px;
  margin-left: -10px;
}

.information-text-container {
  width: 100%;
  width: 100%;
  max-height: 140px;
  background-color: var(--primary-gray);
  text-align: left;
  border-radius: 3px;
  padding: 15px;
  overflow-y: scroll;
}

.information-text {
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: var(--radiobutton-text-color);
}

.information-text-container::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

.information-text-container::-webkit-scrollbar-thumb {
  background-color: var(--primary-lightgreen);
  border-radius: 10px;
}

.product-add-to-order-button-mobile {
  display: none;
  width: 100%;
  font-size: 16px;
  padding: 10px;
  height: fit-content;
}

@media (min-width: 1024px) {
  /* some different design not inside the modal (basically underneath OrderWindow)*/
  .shipping-page-panels-container .product-item-details-price,
  .payment-main-content .product-item-details-price {
    margin-top: 0px;
    position: absolute;
    right: -60px;
    top: 5px;
  }

  .shipping-page-panels-container .otc-product-item-details,
  .payment-main-content .otc-product-item-details {
    margin-right: 60px;
  }
}

@media (max-width: 764px) {
  .otc-addons-row-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .otc-addons-row {
    flex-direction: column;
  }

  .otc-product-image {
    margin-right: 15px !important;
    width: 100px !important;
    height: 100px !important;
  }

  .otc-product-image-and-details {
    margin-right: 0 !important;
    margin-bottom: 10px;
    width: 100%;
  }

  .quantity-control-wrapper {
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .quantity-control-wrapper .bin-icon {
    display: inline;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .quantity-control-wrapper .bottom-row {
    margin-top: 0;
  }

  .product-add-to-order-button-mobile {
    display: block;
  }

  .product-add-to-order-button {
    display: none !important;
  }

  .product-add-to-order-button-mobile-enabled {
    display: flex;
  }

  .information-text {
    padding: 15px;
    font-size: 14px;
  }

  .quantity-control-wrapper {
    width: 100%;
    justify-content: flex-end;
  }

  .product-item-details-price {
    margin-top: 0px;
  }
}
