@import url('../../../../core.css');

.textarea-error,
.textarea{
    padding: 15px;
    width: 500px;
    height:100px;
    min-height: 60px;
    resize: vertical;
    color: var(--primary-blue);
    background-color: white;
    border: 1px solid var(--sub-gray);
    border-radius: 6px;
    width: 100%;
}

.textarea-error{
    background-color: var(--sub-danger);
    border-color: var(--primary-danger);
    caret-color: var(--primary-danger);
}


.textarea-error:focus{
    border:1px solid var(--primary-danger);
    outline:none;
    box-shadow: none;
}


.textarea:focus{
    border: 1px solid var(--sub-gray);
    outline: none;
    box-shadow: none;

}

.textarea-placeholder-helpblock-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.textarea-placeholder{
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins,sans-serif;
    color: var(--radiobutton-text-color);
    margin-bottom: 25px;
    margin-left: -2px;
}

.textarea-helpblock{
    font-size: 12px;
    font-family: Roboto,sans-serif;
    color: var(--radiobutton-text-color);
    max-width: 500px;
    height:90px;
    font-weight: 400;
}

.textarea-helpblock:empty{
    display: none;
}