.react-datepicker__input-container{
    height: 44px !important;
}

.react-datepicker__input-container > input{
    height: 100%;
    padding: 6px 10px 5px;
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid var(--questionnaire-select-placeholder);
    outline: none;
    max-width: 190px;
    background-color: transparent;
    font-weight: 500;
    color: var(--primary-blue);
}

.react-datepicker__input-container input:focus {
    border: 1px solid var(--questionnaire-select-placeholder);
} 

.react-datepicker__input-container input::placeholder{
    font-size: 12px;
    font-weight: 400;
}


.date-picker-header{
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-picker-arrow{
    font-size: 24px;
    padding: 5px;
    outline: none;
    background-color: transparent;
    border: none;
    color: #216ba5;
    cursor: pointer;
    font-weight: 500;
    user-select: none;
}

.date-picker-arrow:hover{
    opacity: 0.7;
    transition: opacity 350ms ease-in-out;
}
  

.date-picker-header > select {
    margin: 0px 2px;
    padding: 2px 5px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid var(--questionnaire-select-placeholder);
    outline: none;
}

.date-picker-header > select::-webkit-scrollbar{
    width: 6px;
}

.date-picker-header > select::-webkit-scrollbar-thumb {
    border-radius: 3px;
}

/* For error  */
.date-picker-error {
    border: 1px solid var(--primary-danger) !important;
    background-color: var(--sub-danger) !important;
}

.date-picker-error::placeholder{
    color: var(--primary-danger) !important;
    
}