.delivery-address-forms-container {
    background-color: white;
    padding: 30px;
    border: 1px solid #eef1f6;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}

.delivery-address-disclaimer-container {
    height: 100px;
    overflow: auto;
    margin-top: 30px;
    background-color: white;
    padding: 10px 10px 10px 30px;
    border: 1px solid #eef1f6;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    color: #98a0af;
    font-size: 12px;
    font-family: Poppins, sans-serif;
}

.delivery-address-disclaimer-container::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #11ddac;
    color: #11ddac;
}

.delivery-address-disclaimer-container::-webkit-scrollbar {
    width: 5px;
    background-color: rgba(17, 221, 172, 0.2);
    border-radius: 5px;
}

.delivery-address-disclaimer-container a {
    font-size: 12px;
    color: var(--primary-lightgreen);
}


.delivery-address-forms-container .questionnaire-select-placeholder{
    color: var(--primary-blue);
    font-weight: 500;
    font-size: 14px;
}

.delivery-address-forms-container .questionnaire-select-logo-down,
.delivery-address-forms-container .questionnaire-select-logo-up
{
    font-size: 24px;
}

.delivery-address-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    column-gap: 15px;
}


.delivery-address-form .inputfield-container,
.delivery-address-form .inputfield-container-disabled{
    margin:0px !important;
    margin-top: 15px !important;
}


.delivery-address-form .two-in-row-1{
    display: flex;
    gap: 15px;
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 4;
}

.delivery-address-form .two-in-row-2{
    display: flex;
    gap: 15px;
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 4;
}

.delivery-address-form .two-in-row-4{
    display: flex;
    gap: 15px;
    grid-row: 4;
    grid-column-start: 1;
    grid-column-end: 4;
}

#packstation_personal_phone,
#personal-address-container{
    grid-column-start: 1;
    grid-column-end: 4;
}

.delivery-phone-helpblock{
    color: var(--questionnaire-select-placeholder);
    font-size: 12px;
    margin: 5px 0 15px;
    font-family: Roboto,sans-serif;
    white-space: nowrap;
}


#delivery-address-submit{
    align-self: flex-end !important;
}



@media (max-width:1200px){
    .delivery-address-forms-container{
        padding: 20px;
    }

    .delivery-address-form {
        display: flex;
        flex-direction: column;
    }

    .two-in-row-1,.two-in-row-2,.two-in-row-3,.two-in-row-4{
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }

    .two-in-row-1 .inputfield-container,
    .two-in-row-2 .inputfield-container,
    .two-in-row-3 .inputfield-container,
    .two-in-row-4 .inputfield-container {
        margin-top: 0px !important;
    }
    
    .delivery-phone-helpblock{
        white-space:normal;
        
    }

}