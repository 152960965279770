
.noPreferenceInfo-container{
    display: flex;
    padding-bottom: 10px;
    background-color: white;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.noPreferenceInfo-texts {
    width: 100%;
    padding: 30px 30px 125px;
    text-align: left;
}


.noPreferenceInfo-title{
    color: var(--primary-blue);
    font-size: 18px;
    font-family: Roboto,sans-serif;
    margin-bottom: 15px;
    font-weight: 700;
}

.noPreferenceInfo-description{
    color: var(--radiobutton-text-color);
    font-family: Roboto,sans-serif;
    font-size: 16px;

}