.quantity-control-container {
  border: 1px solid var(--sub-gray);
  border-radius: 3px;
  height: fit-content;
  display: flex;
}

.quantity-control-button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--primary-blue);
  text-align: center;
  height: 37px;
  width: 40px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}

.quantity-control-quantity {
  color: var(--primary-blue);
  font-size: 18px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 67px;
  border-right: 1px solid var(--sub-gray);
  border-left: 1px solid var(--sub-gray);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  user-select: none;
  flex: 1;
}


.unclickable{
  cursor: not-allowed !important;
}
