.order-summary-container{
    padding: 15px 15px;
    background-color: white;
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.order-summary-title{
   font-family: Roboto,sans-serif; 
   color: var(--primary-blue);
   font-weight: 500;
   margin-bottom: 5px;
}

.order-summary-container-total-row,
.order-summary-container-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    

}

.order-summary-container-row span {
    font-family: Roboto,sans-serif !important;
    font-size: 13px;
    color: var(--radiobutton-text-color);
}


.order-summary-container-total-row span{
    font-family: Roboto,sans-serif !important;
    font-size: 13px;
    color: var(--radiobutton-text-color);
    font-weight: 700;
}

.order-summary-container-total-row #total-price {
    color: var(--primary-blue);
}


.order-summary-otc-disclaimers-container{
    padding: 15px 15px 15px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.order-summary-otc-disclaimer {
    color: var(--radiobutton-text-color);
    font-family: Roboto,sans-serif !important;
    font-size: 12px;
    text-align: left;
}