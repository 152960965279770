.leave-feedback h2{
  font-size: 28px;
  color: #0D2C54;
}

.leave-feedback p{
  color: #0D2C54;
  font-size: 16px;
}

.leave-feedback textarea{  
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  min-height: 177px !important;
}

.stars{
  height: 40px;
  display: flex;
}
.star{
  height: 40px;
  width: 40px;
  cursor: pointer;
  color: #9D9D9D;
}
.selected-star{
  color: #F49809;
}


.feedback{
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  resize: none;

}