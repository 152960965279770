.emojiblock-container{
    display: flex;
    flex-direction: column;
    padding: 60px;
    align-self: center;
    border-radius: 6px;
    width: 920px;
    margin-bottom: 30px;
    background-color: var(--primary-white);
}

.emojiblock-container > .emojiblock-title{
    font-size: 18px;
    text-align: center;
    font-family: Roboto,sans-serif;
    align-self: center;
    color: var(--questionnaire-select-placeholder);
}

.emojiblock-subTitle{
    font-family: Roboto,sans-serif;
    color: var(--radiobutton-text-color);
    margin: 15px 0px;
}

.emojiblock-container > ul > li {
    list-style-type: none;
    margin-bottom: 10px;
}

.emojiblock-container > ul > li{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
   
}
.emojiblock-container > ul > li > p,
.emojiblock-container > ul > li > span{
    font-family: Roboto,sans-serif;
    color: var(--radiobutton-text-color);
}



.emojiblock-container > ul > li > .icon{
    margin-right: 12px;
    font-size: 18px;
}   

.only-doc{
    margin: 30px 0px 15px;
    font-size: 15px;
    font-family: Roboto,sans-serif;
    color: var(--questionnaire-select-placeholder);
}




