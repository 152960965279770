.edit-subscription-panel .info-cells-row{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.edit-subscription-panel{
    background-color: white;
    padding: 20px;
    border-radius: 6px;
}

.cancel-subscription{
    text-decoration: underline;
    font-size: 12px;
    color: var(--questionnaire-select-placeholder);
    cursor: pointer;
}

.cancel-subscription:hover{
    text-decoration: none;
}

.skip-subscription-container{
    display: flex;
    background-color: var(--sub-success);
    margin-top: 30px;
    min-height: 80px;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 5px;
}

.skip-subscription-text{
    color: var(--primary-success);
    font-size: 14px;
    font-family: roboto, 'sans-serif';
    margin-right: 30px;
}

.skip-subscription-title{
    color: var(--primary-success);
    font-size: 16px;
    font-weight: 600;
    font-family: roboto, 'sans-serif';
    margin-right: 30px;
}

.skip-subscription-info-icon{
    min-width: 20px;
    height: auto;
    color: var(--primary-success);
    margin-right: 10px
}

.skip-subscription-text-icon-container{
    display: flex;
    flex-direction: row;
    padding: 10px 0px;

}

.skip-subscription-text-container{
    display: flex;
    flex-direction: column;
}

.skip-disabled{
    background-color: rgba(247, 247, 247, 1);
    color: var(--primary-blue);
    margin-right: 5px;
}

#subscription-skip-submit-disabled{
    max-width: 230px;
    min-width: 0px;
    filter: grayscale();
}

#subscription-skip-submit-disabled:hover {
    cursor: not-allowed;
}

#subscription-delivery-address-submit{
    margin-top: 10px;
}


@media (max-width: 1200px){
    .edit-subscription-panel .info-cells-row {
        flex-direction: column;
    }

}

@media (max-width: 767px){
#subscription-delivery-address-submit{
    min-width: 240px !important;
}

#subscription-skip-submit-disabled{
    min-width: 240px !important;
    margin-top: 10px;
}

.skip-subscription-container{
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cancel-subscription{
    margin-top: 30px;
    text-align: center;
}
}


