.account-order-card{
    margin-bottom: 30px;
    border-radius: 6px;
    border: 1px solid #eef1f6 ;
}

.account-order-card-head{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    border-radius: 6px;
}
.account-order-card-head .significance-container{
    margin: 0px;
}

.account-order-card-head  .product-img {
    margin-right: 20px;
}

.account-order-card-head  .product-img > img{
    aspect-ratio: 1px;
    width: 100px;
    object-fit: cover;
}

.account-order-card-head .order-title{
    font-size: 15px;
    margin-bottom: 15px;
    color: var(--primary-blue);
    word-break: break-word;
    font-weight: 700;
    padding-right: 35px;
    font-family: Roboto,sans-serif;
}


.account-order-card-head  .order-info {
    flex: 1;
    padding-top: 10px;
}

.account-order-card-head  .order-info > ul {
    display: flex;
    list-style-type: none;
}

.account-order-card-head  .order-info li {
    padding-right: 30px;
}

.order-info-field{
    font-size: 13px;
    font-family: Roboto,sans-serif;
    color: var(--questionnaire-select-placeholder);
    white-space: nowrap;
}

.order-info-field-value{
    font-size: 13px;
    font-family: Roboto,sans-serif;
    color: var(--radiobutton-text-color);
}

.order-prices{
    text-align: right;
    width: max-content;
}

.order-price-without-treatmentfee{
    white-space: nowrap;
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-blue);
}

.order-treatmentfee{
    font-size: 13px;
    font-weight: 700;
    color: var(--primary-blue);
    font-family: Roboto,sans-serif;
}

.account-order-card-bottom{
    padding: 15px 20px;
    background-color: white;
    border-top: 1px solid #eef1f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
}

.account-order-card-bottom #account-order-card-reorder{
    height: 45px !important;
    font-size: 14px !important;
}

.account-order-card-links{
    display: flex;
    gap: 20px;
}

.account-order-card-link{
    display: flex;
}
.account-order-card-link > a{
    color: var(--primary-lightgreen);
    font-size: 12px;
}



.account-order-card-head .account-order-card-head-container{
    display: flex;
    flex-direction: row;
    flex:1;
    position: relative;
    word-break: break-all;

}

@media (max-width: 1200px){

    .account-order-card-head{
        justify-content: space-between;
    }
  
    .account-order-card-head .account-order-card-head-container{
        flex-direction: column;
    }

    .order-prices{
        width: auto;
        position: absolute;
        right: 0;
        top: 0;
        word-break: break-all;
        left: 120px;
    }

    .order-info{
        width: 100%;
    }

    .order-info ul{
        flex-direction: column;
    }

    .order-info ul li{
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 0;
    }

    .account-order-card-bottom{
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
    }

    .account-order-card-bottom #account-order-card-reorder{
        width: 100% !important;
        margin-top: 20px;
    }

    .account-order-card-links{
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }

    #detail-row-pay-now{
        margin-top: 20px;
    }
}






