.treatment-options {
    display: flex;
    /* gap: 25px; */
    flex-direction: column;
    margin-top: 60px;
    background-color: #f7f7f7;
    text-align: left;
    width: 410px;
    max-width: 100%;
    padding:10px;
    /* height: 550px; */
    /* margin-left: 10px; */
}

.SelectTreatmentLandingPage-inner-container .treatment-options{
    margin-top: 0px;
}

.coupon {
    display: flex;
    /* margin: 10px; */
    width: 100%;
    font-size: 15px;
    background-color: #e8fbec;
    padding: 15px 20px;
    border-radius: 5px;
    
    color:#19d845;
}

.coupon-message {
    font-size: 15px;
    font-family: Roboto,sans-serif;
}

.coupon-message-container {
    /* margin-right: 20px; */
}


.gift-icon {
    font-size: 18px;
    margin: 10px;

}

.dr-img {
    width:120px;
}


.doctor-role {
    color: gray;
    font-size: 12px;
    font-family: Roboto,sans-serif;
}



.doctor-name {
    font-size: 15px;
    font-family: Roboto,sans-serif;
    color : var(--primary-blue);
    font-weight: 600;
}

.doctor-info {
    margin-left: 10px;
}

.img-container {
    display: flex;
    align-items: flex-end;
}

.article-quote {
    margin: 20px 0 20px 0;
    font-size: 13px;
    line-height: 20px;
    font-family: Roboto,sans-serif;
    color: var(--primary-blue);
}

.upward-quote {
    font-size: 20px;
}

.downward-quote {
    font-size: 20px;
    transform: rotate(180deg);
}


@media (width < 1023px) {
    .treatment-options {
        margin-top: 60px;
        background-color: #f7f7f7;
        text-align: left;
        max-width: 740px;
        width: 100%;
        margin-left: 10px;
    }
}