.personal-data-form {
    background-color: white;
    padding: 30px;
    border: 1px solid #eef1f6;
    border-radius: 6px;
    row-gap: 15px;
    column-gap: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.personal-data-form .binarySelect-locked-container{
    width: 75%;
}

.info-cells-row{
    display: flex;
    padding-top: 20px;
    position: relative;
    grid-column-start: 1;
    grid-column-end: 4;
    gap: 15px;
}

.info-cells-row-2{
    display: flex;
    position: relative;
    grid-column-start: 1;
    padding-top: 20px;
    grid-column-end: 3;
}

.info-cells-row-3{
    display: flex;
    position: relative;
    grid-column-start: 1;
    grid-column-end: 4;
    gap: 15px;
}

.personal-data-form > .info-cells-row > .info-cell-title,
.personal-data-form > .info-cells-row-2 > .info-cell-title{
    position: absolute;
    top: 0;
}

.personal-data-form #email-field-container{
    grid-column-start: 1;
    grid-column-end: 4;
}

.personal-data-form .inputfield-container,
.personal-data-form .inputfield-container-disabled{
    margin:0px !important;
    margin-top: 15px !important;
}

.personal-data-form-container #account-significance{
    margin-top: 0px !important;
}

#delivery-address-submit{
    min-width: 190px !important;
}

.deactivate-account{
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: var(--primary-blue);
    cursor: pointer;
}

.deactivate-account:hover{
    text-decoration: underline;
}

.delivery-address-last-row{
    display: flex;
    grid-column: 1/-1;
    justify-content: space-between;
    align-items: center;
}



@media (max-width:1200px){
    .personal-data-form{
        padding: 20px;
    }

    .personal-data-form .binarySelect-locked-container{
        width: 100% !important;
    }

    .personal-data-form .info-cells-row{
        display: flex;
        flex-direction: column;
    }

    .personal-data-form .info-cells-row-2{
        grid-column-end: 4;
    }

    .personal-data-form .info-cells-row-3{
        flex-direction: column;
    }

    
    .delivery-phone-helpblock{
        white-space:normal;
    }

    .delivery-address-last-row{
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 15px;
    }

    .delivery-address-last-row #delivery-address-submit {
        width: 100% !important;
    }


}