@import url('../../../../core.css');

.questionnaire-loginquestion-title{
    font-size: 13px;
    align-self: flex-start;
    font-weight: 700;
    font-family: Poppins,sans-serif;
    color: var(--primary-blue);
    margin-bottom: 15px;
}


.questionnaire-loginquestion-inputs-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
    justify-content: space-between;
}


.loginquestion-forgot-password{
    color: var(--primary-lightgreen);
    cursor: pointer;
    font-size: 12px;
    font-family: Poppins,sans-serif;
    margin-top: 2px;


}


.loginquestion-forgot-password:hover{
    text-decoration: none;
}


