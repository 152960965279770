.pharmacy-request-modal-background{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(24, 39, 59, 0.70);
  position: fixed;
  z-index: 400;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: var(--tablet-container-padding);
}


.pharmacy-request-modal-container {
  display: flex;
  flex-direction: column;
  width: 550px;
  position: relative;
  padding: 60px 40px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 6px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  gap: 15px;
}

.pharmacy-request-modal-container .close-btn{
  position: absolute;
  right: 25px;
  top: 25px;
  width: 20px;
  cursor: pointer;
}


.pharmacy-request-modal-container > h6 {
  font-family: Roboto,sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0D2C54;
}

.pharmacy-by-customer-disclaimer {
  
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color:     #B77208;
}

input {
  color: #0D2C54 !important;
}

button {
  border-radius: 6px !important;
}

@media (max-width:764px) {
  .pharmacy-request-modal-background{
      padding: 0 15px;
  }

  .pharmacy-request-modal-container {
      width: 100%;
  }

}