@import url('../../../../core.css');



.disabled {
    background-color: #eef1f6;
    color: #98a0af !important;
    border-color: #eef1f6;
    cursor :not-allowed;
}

.disabled .radio {
    background-color: #eef1f6;
    border-color: #98a0af;
}