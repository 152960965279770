.medical-disclaimer-container {
  width: var(--desktop-col-4);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.medical-disclaimer-container:first-of-type p {
  color: var(--radiobutton-text-color);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
}

.medical-disclaimer-container p,
h3 {
  color: var(--primary-blue);
}

.medical-disclaimer-container h3 {
  margin-bottom: 20px;
  text-align: center;
}

.medical-disclaimer-container button {
  background: none;
  border-radius: 3px;
  border: 1px solid var(--questionnaire-select-placeholder);
  padding: 10px 30px;
  width: 100%;
  cursor: pointer;
  margin: 5px 0px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: var(--questionnaire-select-placeholder);
}

.medical-disclaimer-container button:hover {
  background-color: var(--primary-lightgreen);
  color: white;
  transition: all 150ms ease;
  border: 1px solid var(--primary-lightgreen);
}

.medical-disclaimer-container .view-previous-questionnaire-link {
  margin-top: 15px;
  margin-bottom: 25px;
  color: var(--radiobutton-text-color);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration: underline;
}

.medical-disclaimer-container .view-previous-questionnaire-link:hover {
  text-decoration: none;
  cursor: pointer;
}

.medical-disclaimer-container .view-previous-questionnaire-link:visited {
  color: var(--radiobutton-text-color);
}

.reorder-questionnaire-container .header-container,
.reorder-questionnaire-container .stepslist-container,
.reorder-questionnaire-container .stepslist-container-mobile,
.reorder-questionnaire-container .emojiblock-container,
.reorder-questionnaire-container .stepslist-progress-bar {
  display: none;
}

.reorder-questionnaire-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reorder-questionnaire-container .questionnaire-body-container {
  padding-top: 0;
}

.reorder-questionnaire-container .questionnaire-treatment-form-container{
  padding: 0;
}


#reorder-questionnaire-significance{
  width: var(--desktop-col-10);
}

@media (max-width: 400px) {
  .medical-disclaimer-container {
    width: 100%;
  }

  .view-previous-questionnaire-link {
    text-align: center;
  }
}

@media (max-width: 764px) {
  .reorder-questionnaire-container .questionnaire-treatment-form-container{
    padding-top: 30px;
  }

}


@media (max-width:1200px){
  #reorder-questionnaire-significance{
    width: 100%;
  }
}