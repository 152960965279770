.customer-message-container {
  background-color: transparent;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.customer-message-container .message-content{
    background-color: var(--primary-gray);
}

.doctor-message-container {
    background-color: transparent;
    width: 100%;
    margin-bottom: 30px;
    align-items: flex-start;
    flex-direction: column;
}

.doctor-message-container .message-content{
    background-color: var(--primary-green);
}



.message-content{
    border-radius: 12px;
    border-bottom-right-radius: 0px;
    padding: 10px 15px;
    width: fit-content;
}

.message-text-customer{
    color: var(--primary-blue);
    font-family: Roboto,sans-serif !important;
    word-break: break-all;
    text-align: right;
}

.message-text-doctor {
    font-family: Roboto,sans-serif !important;
}

.message-text-customer{
    color: var(--primary-blue);
    font-family: Roboto,sans-serif !important;
    word-break: break-word;
    text-align: left;
}


.message-content span{
    font-family: Roboto,sans-serif;
}

.clickable-mention {
    border: 1px solid var(--primary-blue);
    padding: 5px 10px;
    width: fit-content;
    border-radius: 6px;
    cursor: pointer;
    font-size: 13px;
    font-family: Roboto,sans-serif;
    color: var(--primary-blue);
}

.clickable-mention:hover{
    text-decoration: none !important;
}

.timestamp {
  font-size: 12px;
  font-family: Roboto, sans-serif;
  margin: 5px 0;
  color: var(--questionnaire-select-placeholder);
}




