.info-cell-container{
    width: 100%;
}
.info-cell-field{
    position:relative;
    height: 56px;
    border:1px solid #dbe1eb;
    background-color: var(--primary-gray);
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    align-items: center;
    padding: 0 25px 0 20px;
    font-weight: 500;
    cursor: not-allowed !important;
    user-select: none;
    white-space: nowrap;
}

.info-cell-field .discount-label{
    position: relative;
    background-color: var(--questionnaire-select-placeholder);
    color: var(--primary-white);
    top: 0;
}


.info-cell-title{
    font-size: 13px;
    font-weight: 700;
    font-family: Roboto,sans-serif;
    margin-bottom: 5px;
    color: var(--questionnaire-select-placeholder);

}

.info-cell-value{
    font-size: 14px;
    color: var(--questionnaire-select-placeholder);
}

.info-cell-field > img {
    width: 22px;
    height: 22px;
}