.cancel-subscription-modal-popup-container{
    display: flex;
    flex-direction: column;
    width: 540px;
    position: relative;
    padding: 60px;
    background-color: white;
    text-align: center;
    border-radius: 6px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.cancel-subscription-modal-popup-container .close-btn{
    position: absolute;
    right: 25px;
    top: 25px;
    width: 20px;
    cursor: pointer;
}

.cancel-subscription-modal-popup-container > h4 {
    font-size: 20px;
    color: var(--primary-blue);
    margin-bottom: 30px;
}

.cancel-subscription-modal-popup-container > p {
    font-size: 15px;
    color: var(--radiobutton-text-color);
    font-family: Roboto,sans-serif;
    margin-bottom: 30px;
    text-align: left;
}

.cancel-subscription-modal-popup-container .last-cancel-option-textarea,
.cancel-subscription-modal-popup-container .last-cancel-option-textarea-error
{
    padding: 10px;
    min-height: 92px;
    resize: none;
    border-radius: 6px;
    border: 1px solid #dbe1eb;
    max-width: 100%;
    color: var(--primary-blue);
    font-size: 12px;
    font-family: Roboto,sans-serif;
    outline: none;
}

.cancel-subscription-modal-popup-container .last-cancel-option-textarea-error{
    border-color: var(--primary-danger);
}

.cancel-subscription-modal-option-row{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    text-align: left;
    cursor: pointer;
    
}


.cancel-subscription-modal-option-row span{
    font-size: 12px;
    color: var(--primary-blue);
    font-weight: 500;
}

.cancel-subscription-modal-popup-container .close {
    font-size: 12px;
    color: var(--primary-lightgreen);
    cursor: pointer;
}

.cancel-subscription-modal-popup-container .close:hover{
    text-decoration: underline;
}

.cancel-subscription-modal-popup-container::-webkit-scrollbar{
    width: 6px;
}

.cancel-subscription-modal-popup-container::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #11ddac;
    color: #11ddac;
}


@media (max-width : 640px) {
    .cancel-subscription-modal-popup-container{
        width: 100%;
        padding: 20px;
    }

    .cancel-subscription-modal-popup-container > h4{
        margin-top: 25px;
    }
    .cancel-subscription-modal-popup-container .close-btn{
        right: 10px;
    }
}