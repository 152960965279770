.option-card, .option-card-selected {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #eef1f6;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  width: 100%;
  cursor: pointer;
  user-select: none;
}
.option-card-selected {
  background-color: #e7fcf7;
  border: 1px solid #11ddac;
}

.option-card-header{
  display: flex;
  align-items: center;
}

.option-card-text {
  font-family: Poppins, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  color: #0d2c54;
}

.option-card-description{
  font-size: 13px;
  font-family: Roboto,sans-serif;
  padding-left: 27px;
  font-weight: 400;
  margin-top: 5px;
  color:var(--radiobutton-text-color);
}

.option-card-badge {
  color: white;
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: #59CE3B;
  width: fit-content;
  padding: 2px 10px 2px 7px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 12px;
  position: absolute;
  top:-22px;
  right: 30px;
}



.disabled {
  background-color: #eef1f6;
  color: #98a0af;
  border-color: #eef1f6;
  cursor :not-allowed;
}

.disabled .radio {
  background-color: #eef1f6;
  border-color: #98a0af;
}

.payment-icon {
  /* width: 80px; */
  height: 30px;
  object-fit: contain;
  margin-left:75px;
}




