@import url('../../core.css');

.questionnaire-checkbox-container{
    display: flex;
    align-items: center;
    position: relative;
}


.checkbox-label{
    display: flex;
    align-items: center;
    padding: 5px 0px;
    justify-content: flex-start;
    margin-bottom: 10px;
    width:fit-content;
    cursor: pointer;
}


.questionnaire-checkbox-placeholder{
    font-family: Roboto,sans-serif;
    font-size: 13px;
    margin-left: 5px;
    color: var(--radiobutton-text-color);
}

input[type="checkbox"]{
    display: none;
}

.checkbox-checked-logo{
    position: absolute;
    left: -3px;
    color: var(--primary-lightgreen);
    font-size: 20px;
    top: -3px;
    cursor: pointer;
    /* transition: iconGrow 1s ease-in-out; */
    
}

.checkbox-error-logo,
.checkbox-unchecked-logo{
    position: absolute;
    left: -3px;
    top: -3px;
    color: var(--sub-gray);
    font-size: 20px;
    cursor: pointer;
    /* transition: iconGrow 1s ease-in-out; */
    
}

.checkbox-error-logo{
    color: var(--primary-danger);
}



.checkbox-checked-background-container{
    position: relative;
    background-color: white;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-left: 5px;
}

.checkbox-unchecked-background-container{
    position: relative;
    background-color: transparent;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-left: 5px;
}
