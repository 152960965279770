@import url("../../../core.css");


.selectTreatment-body-container {
    background-color: var(--primary-gray);
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100%;
    height:100%;
    min-height: 100vh;
    padding: 60px 0 90px;
}

.selectTreatment-title {
    color:var(--primary-blue);
    font-size: 42px;
    font-family:Poppins, sans-serif;
}


.selectTreatment-subtitle {
    color:var(--radiobutton-text-color);
    font-family: Roboto,sans-serif;
    text-align: center;
    font-size: 14px;
    margin: 0px 95px 60px ;
}

.selectTreatment-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    width:var(--desktop-col-12);
}

.panels-container {
    display: flex;
    justify-content: space-between;
    width:var(--desktop-col-10);
}


.treatment-options{
    margin: 25px 0px 15px !important;
}


.checkout {
    width:90%;
    margin:20px;
    padding:15px;
    /* height:900px; */
    background-color: #11ddac;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.rights {
    /* position: absolute; */
    display: flex;
    justify-content: center;
    width: 100%;
    bottom:0px;
    /* height: 150px; */
    /* background-color: red; */
    /* background-color: #f7f7f7; */
    
}

.rights  > p {
    background-color: green;
    background-color: #f7f7f7;
    font-size: 13px;
    color:#98A0AF;
    max-width: 450px;
}






/* Mobile */
@media (width < 768px) {

    .selectTreatment-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: 100%;
        margin-top: 40px;
        background-color: #f7f7f7;
        text-align: center;
        padding: var(--tablet-container-padding);
        height: 100%;
    }

    .selectTreatment-subtitle {
        margin: 0px 20px 25px ;
    }

    .panels-container {
        width: 100%;
    }


    .selectTreatment-title {
        color:var(--primary-blue);
        font-size: 28px;
        width: 100% !important;
        font-family:Poppins, sans-serif;
    }


    .selectTreatment-body-container {
        background-color: #f7f7f7;
        display: flex;
        /* align-items: center; */
        text-align: center;
        flex-direction: column;
        width: 100%;
        height:100%;
        padding: 30px 0px 60px;
    }
    
}


/* ---------End of Mobile rules--------- */

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {

    .selectTreatment-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: 100%;
        margin-top: 40px;
        background-color: #f7f7f7;
        text-align: center;
        padding: var(--tablet-container-padding);
        height: 100%;
    }

    .panels-container {
        width: var(--desktop-col-8) !important;
    }


    .selectTreatment-body-container {
        display: flex;
        text-align: center;
        flex-direction: column;
        width: 100%;
        height:100%;
    }
    
}