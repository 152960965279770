.subscription-delivery-address-forms-container{
    padding: 30px;
    background-color: var(--sub-succsess);
    border-top: 1px solid #c6f4e9;
    display: flex;
    justify-content: center;
    flex-direction: column;
}



.subscription-delivery-address-forms-option-row{
    display: flex;
    width: fit-content;
    cursor: pointer;
    align-items: center;
    margin-bottom: 10px;
    color: var(--primary-blue);
    font-size: 14px;
    font-weight: 500;
}


.subscription-delivery-address-forms-option-row .radio-select-disabled,
.subscription-delivery-address-forms-option-row .radio-select{
    background-color: transparent !important;

}

.subscription-delivery-address-forms-personal-form,.subscription-delivery-address-forms-dhl-packstation-form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    margin-bottom: 15px;
    
}

.subscription-delivery-address-forms-dhl-packstation-form:last-of-type .inputfield-container-disabled {
    grid-column: 1/-1 !important; /* Spanning two columns */
}




/* INCASE WE WANT 1 INPUT TO TAKE 2 COLUMNS */
/* .subscription-delivery-address-forms-personal-form #personal_company { */ 
    /* grid-column: 1/-1; Spanning two columns */
/* } */


@media screen and (max-width: 1200px) {
    .subscription-delivery-address-forms-personal-form,.subscription-delivery-address-forms-dhl-packstation-form{
        grid-template-columns: 1fr;
        display: block;
    }

    .subscription-delivery-address-forms-container{
        padding: 20px;
    }


}

