@import url('../../../core.css');

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 20px;
    background-color: white;
  }

.header-help-modal{
    color: var(--primary-lightgreen);
    font-size: 14px;
    text-decoration: underline;
    font-weight: 700;
}

.header-help-modal:hover{
    text-decoration: none;
}

.header-logo{
    height: 28px;
    width: 171px;
    justify-self: center;
}

.header-mobileLogo{
    width: 24px;
    height: 24px;
    align-self: center;
}

.header-links{
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header-return-container{
   display: flex;
   align-items: center;
   justify-self: flex-start;
   position: absolute;
   left: 0;
}

.header-return-container > img{
    height: 16px;
    width: 16px;

}

.header-return-text{
    font-family: Roboto,sans-serif;
    color: var(--primary-blue);
    line-height: 150%;
    font-size: 14px;
    margin: 0;
    margin-left: 3px;
    padding: 0;
}

