.coupon-container {
  margin-top: 15px;
}

.coupon-field,
.coupon-field-confirmed,
.coupon-field-error {
  position: relative;
  display: flex;
  background-color: var(--primary-white);
  height: 56px;
  width: 100%;
  border-radius: 6px;
  margin-top: 10px;
  padding: 0 95px 0 20px;
  transition: all 0.25s;
  align-items: center;
  justify-content: space-between;
}

.coupon-field-confirmed{
    align-items: center;
    display: flex;
    font-size: 13px;
    cursor: default;
    padding: 20px;
    font-weight: 500;
    color: var(--primary-lightgreen);
    background-color: transparent;
    border: 1px solid var(--primary-lightgreen);
}

.coupon-field-error{
    border: 1px solid var(--primary-danger);
}

.coupon-input {
  font-size: 13px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  overflow: hidden;
}

.coupon-button {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 67px;
  border: 2px solid var(--primary-lightgreen);
  color: var(--primary-lightgreen);
  background-color: transparent;
  height: 36px;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 700;
  padding: 10px 10px;
  right: 10px;
  top: 10px;
}

.coupon-confirmed-vCheck{
    height: 13px;
    width: 13px;
}

.coupon-error-message{
    font-size: 12px;
    color: var(--primary-danger);
    font-weight: 500;
}

.coupon-confirmed-change-coupon{
    color: var(--primary-lightgreen);
    font-size: 12px;
    font-weight: 500;
    margin: 10px 0px;
    cursor: pointer;
    text-align: end;
    text-decoration: underline;
}

.coupon-confirmed-change-coupon:hover{
    text-decoration: none;
}

