.erecipe-body-container {
    background-color: var(--primary-gray);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0px 90px;
  }

.margin-container{
    width: 955px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.erecipe-body-container .card{
    background-color: white;
    border-radius: 37px 7px 37px 7px;
    min-height: 100px;
    margin-top: 25px;
    width: 100%;
}

.erecipe-body-container .questionnaire-title{
    margin: -17px 190px 30px;
}

.erecipe-body-container .description-and-uploader{
    display: flex;
    flex-direction: row;
    gap: 70px;
    padding: 33px 60px;
}

.erecipe-body-container .description{
    /* padding: 5px 0; */
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.erecipe-body-container .description h3{
    font-size: 30px;
    font-weight: 700;
}

.erecipe-body-container .description p{
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    color: var(--radiobutton-text-color);
}

.erecipe-body-container .category-select{
    display: flex;
    align-items: center;
}

.erecipe-body-container .category-select button{
    border: var(--primary-lightgreen) solid 1px;
    border-radius: 6px;
    padding: 16px 20px;
    width: 373px;
    height: 56px;
    color: var(--primary-lightgreen);
    background-color: white;
}

.erecipe-body-container .category-select button:hover{
    cursor: pointer;
    text-decoration: underline;
}

.erecipe-body-container .category-select .selected{
    border: none;
    color: white;
    background-color: var(--primary-lightgreen);
}

.erecipe-body-container .category-select .selected:hover{
    background-color: var(--primary-lightgreen);
    opacity: 80%;
}

.erecipe-body-container .questionnaire-photoDiagnose-container{
    margin: 0;
}

.erecipe-body-container .header-cms-modal-background-show{
    padding: 55px;
}

.erecipe-body-container .treatments-container{
    border-radius: 30px 6px 30px 6px;
}

.erecipe-body-container .questionnaire-question-item-container{
    margin-top: 40px;
}


.erecipe-body-container .questionnaire-submitButton{
    margin-top: 25px !important;
}

.erecipe-body-container #dont-have-e-recipe span{
    font-size: 16px;
    font-family: Roboto, 'sans-serif';
}

.erecipe-body-container a{
    color: var(--primary-lightgreen);
    text-decoration: underline;
}

.erecipe-body-container a:hover{
    opacity: 90%;
    cursor: pointer;
}

#faq-title{
    margin-top: 90px;
    margin-bottom: 30px;
}

@media screen and (max-width: 990px) {

    .erecipe-body-container .questionnaire-title{
        margin: 0;
    }

    .margin-container{
        width: auto;
    }

    .erecipe-body-container .questionnaire-submitButton {
        margin-top: 30px !important;
    }

    .erecipe-body-container {
        padding: 30px 10px 90px;
    }

    .erecipe-body-container h3{
        font-size: 24px;
    }
    .erecipe-body-container p{
        font-size: 16px;
        text-align: center;
    }

    .erecipe-body-container .description-and-uploader{
        flex-direction: column;
        gap: 0;
        padding: 30px 30px;
    }

    .erecipe-body-container .description{
        padding: 12px 0;
    }

    .erecipe-body-container .description h3{
        font-size: 19px;
        text-align: center;
    }

    .erecipe-body-container .description p{
        font-size: 12px;
        margin-top: 10px;
        color: var(--radiobutton-text-color);
    }

    .erecipe-body-container .card{
        width: -webkit-fill-available;
    }

    .erecipe-body-container .questionnaire-photoDiganose-draganddrop{
        background-color: white;
        border: none;
        padding: 0;
    }

    .erecipe-body-container .questionnaire-photoDiagnose-subtext{
        font-size: 12px;
    }

    .erecipe-body-container .category-select button{
        width: -webkit-fill-available;
    }

    .erecipe-body-container .questionnaire-question-item-container{
        width: -webkit-fill-available;
    }
    
    .erecipe-body-container .questionnaire-subquestion-container{
        width: -webkit-fill-available;
    }
}