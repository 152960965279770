@import url("../../core.css");

.inputfield-container,
.inputfield-container-disabled {
  margin: 15px 0px;
  position: relative;
  width: 100%;
}

.inputfield-container > img,
.inputfield-container-disabled > img{
  position: absolute;
  width: 20px;
  height: 20px;
  right: 6%;
  top: 20px;
}

.inputfield-label-placeholder {
  position: relative;
}

.inputfield-placeholder{
  font-size: 14px;
  cursor: default;
  pointer-events: none;
  display: inline;
  position: absolute;
  bottom: -50%;
  left: 0;
  z-index: 2;
  padding-left: 20px;
  padding-right: 10px;
  margin: 0;
  line-height: 56px;
  text-align: left;
  color: var(--questionnaire-select-placeholder);
  transition: 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
}

.inputfield-placeholder-focused {
  font-size: 11px;
  cursor: default;
  pointer-events: none;
  display: inline;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding-left: 20px;
  padding-right: 10px;
  margin: 0;
  line-height: 56px;
  text-align: left;
  color: var(--questionnaire-select-placeholder);
  transition: 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
}

.inputfield-placeholder-animate {
  animation: inputFieldPlaceholderAnimation 0.3s ease-out forwards;
}

@keyframes inputFieldPlaceholderAnimation {
  from {
    font-size: 11px;
    bottom: 0;
  }
  to {
    font-size: 9px;
    bottom: 20px;
  }
}

.inputfield-input, .inputfield-input-disabled {
  border-radius: 6px;
  border: 1px solid var(--sub-gray);
  background-color: #fff;
  padding: 10px 20px 0;
  height: 56px;
  transition: all 0.25s ease-in-out;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}

.inputfield-input-disabled{
  background-color: var(--primary-gray);
  cursor: not-allowed!important;
  color: var(--questionnaire-select-placeholder);
}



.inputfield-input:focus {
  outline: none;
}

.inputfield-input-error {
  border-radius: 6px;
  border: 1px solid #dbe1eb;
  background-color: #fff;
  padding: 10px 20px 0;
  height: 56px;
  transition: all 0.25s ease-in-out;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  border-color: var(--primary-danger);
}

.inputfield-input-error:focus {
  outline: none;
  border: 1px solid var(--primary-danger);
}

.inputfield-error-message {
  color: var(--primary-danger);
  font-size: 11px;
  font-weight: 500;
  font-size: 0.6875rem;
  margin: 5px 0 0;
}

.credential-input-mandatory-astrix {
  color: var(--primary-danger);
}

.inputfield-visibility-icon{
  cursor: pointer;
  opacity: 0.4;
}

.inputfield-input::placeholder{
  opacity: 0.5;
}

.google-api-suggestions-active{
   background-color: red;
   cursor: 'pointer' 
}

.google-api-suggestions-not-active{
  background-color: '#fafafa';
  cursor: 'pointer' 
}

.google-api-suggestions-container{
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
  position: absolute;
  z-index: 10;
  width: 100%;
}

.google-api-suggestion{
  border-bottom: 1px solid #DDD;
  color: black;
  font-family: Poppins,sans-serif;
  padding: 5px 10px;
  background-color: white;
  user-select: none;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
}

.google-api-suggestion:hover{
  background-color: #3366FF;
  color: white;
  border-radius: 1px;
}

.google-api-suggestion--active{
  background-color: #fafafa;
}