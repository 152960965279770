.message-content .attachment,
.tab-mentions-container .attachment
{
    background-color: white;
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 6px;
}

.tab-mentions-container .attachment{
    border: 1px solid var(--primary-lightgreen);
    margin: 0;
    width: fit-content;
}

.message-content .attachment .icon,
.tab-mentions-container .attachment .icon
{
    width: 16px;
    height: 16px;
    margin-right: 5px;
}


.message-content:first-of-type .attachment {
    margin-top: 15px;
}

.message-content .attachment a,
.tab-mentions-container .attachment a{
    color: var(--primary-lightgreen);
    cursor: pointer;
    font-family: Roboto,sans-serif;
    font-size: 12px;
}


.tab-mentions-container .attachment a:hover{
    text-decoration: none;
}