.orders-list{
    display: flex;
    background-color: white;
}

.orders-item__image{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 45px;
    border: 1px solid var(--primary-gray);
}

.item__image{
    height: 170px;
    width: 170px;
}

.orders-item__info{
    padding: 24px 35px;
    border: 1px solid var(--primary-gray);
}

.orders-info{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.orders-info__title{
    font-size: 13px;
    margin-right: 10px;
    color: #98a0af;
}

.orders-info__item{
    color: #656d79;
    font-size: 13px;
    font-family: Roboto,sans-serif;
}


.orders-item__description{
    display: flex;
    flex-grow: 1;
    align-items: center;
    border: 1px solid var(--primary-gray);
    padding: 0px 14px 32px 35px;
}

.orders-name{
    font-family: Roboto,sans-serif;
    font-size: 20px;
    margin-bottom: 16px;
    color: var(--primary-blue);
}

.treatment-instructions__wrap{
    background-color: #fff;
    /* padding: 20px 16px 5px; */
    border: 1px solid #eef1f6;
    border-top: none;
    padding: 40px 44px 70px;
}

.treatment-instructions__description{
    font-family: Roboto,sans-serif;
    font-size: 14px;
    line-height: 150%;
    color: #656d79;
}

.treatment-instructions__title{
    font-size: 24px;
    margin-bottom: 10px;
    font-family: Poppins,sans-serif;
    color: var(--primary-blue);
    font-weight: 700;
}

.account-order-card-link{
    color: var(--primary-lightgreen);
    font-size: 12px;
}

.treatment-instructions-links {
    display: flex;
    flex-direction: row;
    gap: 10px;

}

.orders-info__row{
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.order-products-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media (max-width:1200px){

    .orders-info__row{
        flex-direction: column;
    }

    .orders-list{
        flex-direction: column;
    }

    .orders-item__description{
        padding-top: 20px;
        padding-bottom: 24px;
    }

    .account-order-card-links{
        flex-direction: column;
        justify-content: flex-start;
    }

    .orders-item__info{
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding-top: 20px;
        padding-bottom: 24px;
    }

    .orders-info__col{
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
    }

    

    .treatment-instructions__wrap{
        padding:20px 35px 34px 35px
    }
    
   

}

@media (max-width:768px){ 
    .orders-item__description, .orders-item__info, .orders-item__image, .treatment-instructions__wrap{
        border-left: none;
        padding-left: 20px;
    }
}