@import url('../../../../core.css');

.products-containter {
  width: var(--desktop-col-5) !important;
  max-height: 500px;
  overflow-y: scroll;
}

.products-containter::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(17, 221, 172, 0.2);
  border-radius: 5px;
}

.products-containter::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #11ddac;
  color: #11ddac;
}

.product-item,
.product-item-selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: start !important;
  background-color: white;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  user-select: none;
  padding: 15px;
}

.out-of-stock > .product-item-text {
  text-decoration: line-through;
  color: #808080;
}

.out-of-stock .radio {
  background-color: rgb(240, 237, 237);
}

.product-item-selected {
  background-color: #e7fcf7;
  border: 1px solid #11ddac;
}

.product-item-text {
  display: flex;
  margin-left: 5%;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-blue);
}

.product-item-from-price {
  /* font-style: italic; */
  display: flex;
  align-items: center;
  /* margin-right: 10px; */
  font-weight: 500;
  color: #656d79;
}

.product-name-and-price {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.product-item-selected .product-item-from-price {
  color: var(--primary-blue) !important;
}

.from-text {
  font: 11px Poppins, sans-serif;
  margin-right: 5px;
}

.from-price {
  font: 14px Poppins, sans-serif;
  white-space: nowrap;
}

.radio {
  width: 20px;
  height: 20px;
  aspect-ratio: 1 !important;
  background-color: white;
  border: 1px solid gray;
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
}

.radio-selected {
  width: 20px;
  height: 20px;
  aspect-ratio: 1 !important;
  background-color: var(--primary-green);
  border: 2px solid white;
  outline: 1px solid var(--primary-green);
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 10px;
}

.test-panel-selected {
  width: 100%;
  display: none;
}

.test-panel {
  display: none;
  width: 100%;
  margin-left: 10px;
}

.single-item-container {
  width: 100%;
}

.single-item-container-selected {
  margin: 0;
  width: 100%;
  border-radius: 5px;
}

.default-product {
  margin-bottom: 5px;
}

@media (width < 1023px) {
  .product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    width: 100% !important;
    height: 55px !important;
    border-radius: 5px;
    margin: 0;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .product-item-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e7fcf7;
    border: none;
    margin: 0;
    width: 100% !important;
    height: 55px !important;
    cursor: pointer;
  }

  .products-containter {
    max-height: 100%;
    width: 100% !important;
    height: 100% !important;
  }

  .test-panel-selected {
    display: block;
    width: 100%;
    overflow: hidden;
    animation: scroll_down 8s forwards;
  }

  .single-item-container-selected {
    border: 1px solid #11ddac;
    margin-bottom: 10px;
    width: 100% !important;
    border-radius: 5px;
  }

  .single-item-container {
    width: 100% !important;
  }

  .products-containter::-webkit-scrollbar {
    display: none;
  }

  .products-containter::-webkit-scrollbar-thumb {
    visibility: none;
  }
}

@keyframes scroll_down {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 9999px;
  }
}
