@import url("../../core.css");

.binarySelect-locked-container,
.binarySelect-error-container,
.binarySelect-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 25px;
  padding: 1px;
  border-radius: 6px;
  border: 1px solid var(--sub-gray);
}
 
.binarySelect-error-container{
  border-color: var(--primary-danger);
}

.binarySelect-button,
.binarySelect-button-selected,
.binarySelect-button-locked,
.binarySelect-button-locked-selected
{
  width: 100%;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16.5px 0px;
  background-color :white;
  cursor: pointer;
  transition:all 0.25s ease-in-out, border 1ms;
}

.binarySelect-button-locked,
.binarySelect-button-locked-selected{
  padding: 16.5px 0px;
  cursor: not-allowed;
  user-select: none;
  font-weight: 500;
}

.binarySelect-button-locked-selected{
  background-color: var(--primary-gray) ;
}


.binarySelect-button-locked-selected .locked-icon{
  margin-right: 5px;
  width: 18px;
  height: 16px;
}



.binarySelect-button-selected {
  background-color: var(--primary-green);
}

.binarySelect-button-selected > span {
  color: var(--primary-lightgreen);
  font-size: 14px;
}

.binarySelect-button > span,
.binarySelect-button-locked > span,
.binarySelect-button-locked-selected > span
{
  color: var(--questionnaire-select-placeholder);
  font-size: 14px;
}
