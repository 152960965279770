.cms-header-container-modal-active,
.cms-header-container {
  padding: 25px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-white);
  z-index: 999;
  position: relative;
}

.cms-header-container-disabled{
  display: none;
}

.cms-header-container-modal-active {
  position: fixed;
  top: 0;
  background-color: transparent;
  transition: all 0.2s ease-out;
  width: 100%;
}

.doktorabc-logo-container {
  display: flex;
  justify-content: flex-start;
  max-width: 325px;
  flex: 1;
  cursor: pointer;
}

.doktorabc-logo {
  height: 28px;
  width: 171px;
}

.cms-header-links {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0px 25px;
  list-style-type: none;
}

.cms-header-links li {
  margin-right: 30px;
}

.cms-header-links a {
  list-style-type: none;
  color: var(--radiobutton-text-color);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  text-decoration: none;
}

.cms-header-links a:hover {
  color: var(--primary-lightgreen);
}

.cms-header-navigation,
.cms-header-navigation-mobile {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: space-between;
}

.cms-header-navigation li,
.cms-header-navigation-mobile li {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
}

.cms-header-navigation :first-child,
.cms-header-navigation-mobile :first-child {
  margin-left: 0px;
}

.cms-header-navigation .treatments-dropdown-modal-active,
.cms-header-navigation .treatments-dropdown,
.cms-header-navigation-mobile .treatments-dropdown-modal-active,
.cms-header-navigation-mobile .treatments-dropdown {
  padding: 10px 15px 10px 20px;
  border-radius: 30px;
  background-color: var(--primary-green);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cms-header-navigation .treatments-dropdown-modal-active,
.cms-header-navigation-mobile .treatments-dropdown-modal-active {
  transition: all 200ms ease-out;
  background-color: transparent;
}

.cms-header-navigation .treatments-dropdown:hover > span,
.cms-header-navigation-mobile .treatments-dropdown:hover > span {
  color: rgb(17, 247, 193);
  transition: all 250ms ease-in-out;
}

.cms-header-navigation .treatments-dropdown span,
.cms-header-navigation-mobile .treatments-dropdown span {
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-lightgreen);
}

.cms-header-navigation .treatments-dropdown-modal-active > span,
.cms-header-navigation-mobile .treatments-dropdown-modal-active > span {
  color: var(--primary-white);
  font-weight: 700;
  font-size: 14px;
  transition: all 200ms ease-out;
}

.cms-header-navigation .treatments-dropdown-modal-active > img,
.cms-header-navigation .treatments-dropdown img,
.cms-header-navigation-mobile .treatments-dropdown-modal-active > img,
.cms-header-navigation-mobile .treatments-dropdown img {
  height: 21px;
  width: 21px;
  margin-left: 6px;
}

.cms-header-navigation .nav-icon,
.cms-header-navigation .nav-icon-hovered,
.cms-header-navigation-mobile .nav-icon,
.cms-header-navigation-mobile .nav-icon-hovered {
  height: 24px;
  width: 24px;
  line-height: 1;
}

.nav-icon-container{
  cursor: pointer;
}

.cms-header-navigation .nav-icon-hovered,
.cms-header-navigation-mobile .nav-icon-hovered {
  position: absolute;
  z-index: 4;
}

.cms-header-navigation .nav-icon,
.cms-header-navigation-mobile .nav-icon {
  z-index: 5;
}

.cms-header-navigation .nav-icon:hover,
.cms-header-navigation-mobile .nav-icon:hover {
  opacity: 0;
  transition: all 250ms ease-in-out;
  cursor: pointer;
}

.cms-header-navigation .badge-notifications,
.cms-header-navigation-mobile .badge-notifications {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: -8px;
  right: -8px;
}

.cms-header-navigation .nav-icon-container,
.cms-header-navigation-mobile .nav-icon-container {
  position: relative;
}

.cms-header-navigation .user-logged::after,
.cms-header-navigation-mobile .user-logged::after {
  content: "";
  width: 9px;
  height: 9px;
  z-index: 10;
  border-radius: 50%;
  background-color: var(--primary-success);
  position: absolute;
  cursor: pointer;
  right: -2px;
  bottom: -3px;
}

.nav-hamburger-container {
  display: none !important;
}

.cms-header-hamburger:hover {
  cursor: pointer;
}

.cms-header-hamburger .line {
  width: 20px;
  height: 2px;
  background-color: #98a0af;
  display: block;
  margin: 4px auto;
}

@media (min-width: 764px) {
  .cms-header-navigation-mobile {
    display: none;
  }
}

@media (max-width: 1200px) {
  .nav-hamburger-container {
    display: inline-block !important;
  }

  .cms-header-links {
    display: none !important;
  }
}

@media (max-width: 764px) {
  .cms-header-navigation-mobile .treatments-dropdown,
  .cms-header-navigation .treatments-dropdown,
  .cms-header-navigation-mobile .treatments-dropdown-modal-active,
  .cms-header-navigation .treatments-dropdown-modal-active
  
  {
    display: none;
  }

  .cms-header-navigation-mobile .cms-header-hamburger,
  .cms-header-navigation-mobile .nav-search,
  .cms-header-navigation .nav-user,
  .cms-header-navigation .nav-shopping-bag {
    display: none;
  }

  .cms-header-navigation-mobile .nav-user {
    margin-left: 0;
  }

  .cms-header-container-modal-active,
  .cms-header-container {
    padding: 20px;
  }

  .doktorabc-logo {
    height: 21px;
    width: 128px;
  }

  .cms-header-navigation-mobile li,
  .cms-header-navigation li {
    margin-left: 10px;
  }

  .doktorabc-logo-container {
    display: flex;
    justify-content: center;
  }
}
