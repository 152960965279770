.ask-doctor-input-container {
    position: relative;
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 10px 53px;
    border-top: 1px solid #eef1f6 ;
    border-bottom: 1px solid #eef1f6 ;
}

.ask-doctor-input-container .attachment-label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.attachment-icon {
    background-color: var(--primary-green);
    width: 26px;
    width: 26px;
    border-radius: 100%;
    padding: 4px;
    position: absolute;
    left: 15px;
    color: var(--primary-lightgreen);
}



.ask-a-question-input {
    position: relative;
    overflow: hidden;
    overflow-wrap: break-word;
    width: 100%;
    font-family: Roboto,sans-serif;
    min-height: 30px;
    max-height: 200px;
    resize: none;
    border: none;
    padding-top: 2px;
    outline: none;
    font-size: inherit;
    color: var(--primary-blue);
}

.ask-a-question-input::placeholder{
    color:#97a0ae;
}

.send-icon {
    border-radius: 5px;
    width: 35px;
    height: 35px;
    padding: 5px;
    background-color: var(--primary-lightgreen);
    cursor: pointer;
    position: absolute;
    right: 8px;
}

.ask-doctor-input-container .disabled {
    filter: grayscale();
    cursor: auto;
}


.send-icon:hover {
    transition: all 250ms ease;
    background-color: #0ebc92;
}

.ask-doctor-input-container .disabled:hover {
    background-color: var(--primary-lightgreen);
}

.input-and-attachment {
    display: flex;
    gap: 5px;
    align-items: center;
}



.ask-doctor-input-selected-files .selected-file{
    background-color: var(--primary-gray);
    border-radius: 6px;
    padding: 5px 10px;
    margin: 15px;
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.ask-doctor-input-selected-files .selected-file span {
    font-size: 13px;
    color: var(--radiobutton-text-color);
    font-family: Roboto,sans-serif;
    margin-right: 10px ;
}

.ask-doctor-input-selected-files .selected-file img{
    width: 16px;
    height: 16px;
}


.ask-doctor-input-selected-files .selected-file-fadeout{
    animation: fadeout 0.3s ease-in-out;
}



@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
}






