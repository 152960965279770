.Subscription-plan-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #b4bfce;
  border-radius: 5px;
  margin: 20px 0 0 0;
  width: 100%;
  height: auto;
  cursor: pointer;
  background-color: white;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.12));
}

.best-price {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700 !important;
  font-variant-caps: all-small-caps;
  color: white;
  font: 11px Roboto, sans-serif;
  background-color: #4b91fa;
  height: 24px;
  width: 95.4px;
  border-radius: 50px;
  position: absolute;
  right: 15px;
  top: -10px;
}

.Subscription-plan-card-selected {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--primary-green);
  padding: 10px;
  border: 1px solid #11ddac;
  border-radius: 5px;
  margin: 20px 0 0 0;
  width: 100%;
  height: auto;
  cursor: pointer;
  filter: drop-shadow(0 5px 8px rgba(0, 0, 0, 0.12));
}

.Subscription-plan-card-selected .title-plan {
  color: #11ddac;
}

.title-plan {
  font-size: 16px;
  color: var(--primary-blue);
  font-family: Poppins, sans-serif;
  user-select: none;
  /* padding: 5px; */
}

.plan-description {
  max-width: 85%;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-family: Roboto, sans-serif;
  color: rgb(134, 132, 132);
  font-weight: 400;
  user-select: none;
}

.title-plan-radio {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 2px solid #b4bfce;
  border-radius: 50%;
}

.title-plan-radio-selected {
  width: 17px;
  height: 17px;
  margin-right: 8px;
  background-color: #11ddac;
  border: 3px solid white;
  outline: 2px solid #11ddac;
  border-radius: 50%;
  box-sizing: border-box;
}

.title-plan-container {
  display: flex;
  align-items: center;
  user-select: none;
  padding: 5px;
}

.saved-cost-description-container {
  display: flex;
  user-select: none;
  margin-bottom: 10px;
}

.saved-cost-description-container p {
  font-family: Roboto, sans-serif;
}

.check-cost {
  margin-top: 4px;
  margin-right: 5px;
  color: #0f9172;
  font-size: 13px;
  font-weight: 900;
}

.saved {
  background-color: rgb(235, 54, 54);
  border-radius: 3px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 0 5px 0 5px;
}

.saved-cost-description-container {
  color: var(--primary-blue);
  font-weight: 600;
  font-size: 14px;
  font-family: Roboto, sans-serif;
}

.error-card {
  border: 1px solid #ef4747;
}

.error-title-plan-radio {
  border: 1px solid #ef4747;
}

.error-title-plan {
  color: #ef4747;
}

.warning-title {
  color: #ef4747;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  margin-top: -15px;
  margin-top: 3px;
}
