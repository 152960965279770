/* Other Orders */
.account-other-orders{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--sub-warning);
    font-family: Roboto,sans-serif;
    box-shadow: 0 60px 90px rgb(0 0 0 / 6%);
    padding: 20px 30px;
    font-size: 13px;
    margin-bottom: 30px;

}

.other-orders-title{
    font-size: 15px;
    font-family: Roboto,sans-serif;
    color: var(--radiobutton-text-color);
    font-weight: 700;

}

.other-orders-description{
    font-size: 12px;
    font-family: Roboto,sans-serif;
    color: var(--radiobutton-text-color);
}

.other-orders-links{
    display: flex;
    gap: 7px;
}

.other-orders-links > a{
    color: var(--radiobutton-text-color);
    font-size: 12px;
    text-decoration: underline;
    font-family: Roboto,sans-serif;
}

.other-orders-links > a:hover{
    text-decoration: none;
}


/* End Of Other Orders */