.DrugInfoPanel-container {
  /* margin-top: 60px; */
}

.drug-basic-info {
  display: flex;
  justify-content: space-between;
  font-family: Roboto,sans-serif !important;
  margin-top: 30px;
}



.SelectTreatmentLandingPage-inner-container .drug-basic-info{
  margin-top: 0;
}

.drug-img {
  height: 100px;
  /* margin-left: 40px; */
  /* margin-right: 5px; */
}

.drug-title-and-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  margin-left: 30px;

}

.drug-main-title {
  color: var(--primary-blue);
  font-size: 18px;
  word-break:keep-all
}

.drug-info-price {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-blue);
}

.drug-info {
  font-family: Roboto,sans-serif;
  font-size: 15px;
}

.choose-a-pack {
  margin-top: 20px;
  font-size: 13px;
  color: #98a0af;
  font-family: Roboto;
  /* margin-left: 50px; */
}

.key-points {
  margin-top: 15px;
  font-size: 15px;
}





.product-body {
  /* background-color: purple; */
  /* margin-left: 50px; */
  font-family: Roboto;
  color : #656d79;
}
