@import url("../../core.css");

.text-area-v2,
.text-area-v2-disabled {
  margin: 15px 0px;
  position: relative;
  width: 100%;
}

.text-area-v2 > img,
.text-area-v2-disabled > img {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 6%;
  top: 20px;
}

.text-area-v2 .textarea-label-placeholder {
  top: 15px;
  position: relative;
}

.text-area-v2 .textarea-placeholder {
  font-size: 14px;
  cursor: default;
  pointer-events: none;
  display: inline;
  position: absolute;
  left: 20px; 
  z-index: 2;
  padding-right: 10px;
  margin: 0;
  line-height: 1;
  text-align: left;
  color: var(--questionnaire-select-placeholder);
  transition: 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
}

.text-area-v2 .textarea-placeholder-focused {
  font-size: 11px;
  cursor: default;
  pointer-events: none;
  display: inline;
  position: absolute;
  left: 0; /* Keep horizontal padding */
  z-index: 2;
  padding-left: 20px;
  padding-right: 10px;
  margin: 0;
  line-height: 1;
  text-align: left;
  color: var(--questionnaire-select-placeholder);
  transition: 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
}

.text-area-v2 .textarea-placeholder-animate {
  animation: textareaPlaceholderAnimation 0.3s ease-out forwards;
}

@keyframes textareaPlaceholderAnimation {
  from {
    font-size: 11px;
    bottom: 0;
  }
  to {
    font-size: 9px;
    bottom: 20px;
  }
}

.text-area-v2 .textarea-input,
.text-area-v2 .textarea-input-disabled {
  min-height: 125px;
  border-radius: 6px;
  border: 1px solid var(--sub-gray);
  background-color: #fff;
  padding: 30px 20px; /* Add padding to account for the placeholder */
  height: 120px; /* Adjusted for textarea */
  transition: all 0.25s ease-in-out;
  width: 100%;
  font-size: 14px;
  font-weight: 400; /* Changed to normal weight */
  resize: vertical; /* Allow resizing of the textarea */
}

.non-resizable{
  resize: none !important;
}

.text-area-v2 .textarea-input-disabled {
  background-color: var(--primary-gray);
  cursor: not-allowed !important;
  color: var(--questionnaire-select-placeholder);
}

.text-area-v2 .textarea-input:focus {
  outline: none;
}

.text-area-v2 .textarea-input-error {
  border-radius: 6px;
  border: 1px solid #dbe1eb;
  background-color: #fff;
  padding: 10px 20px;
  height: 120px; 
  transition: all 0.25s ease-in-out;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  border-color: var(--primary-danger);
  resize: vertical; 
}

.text-area-v2 .textarea-input-error:focus {
  outline: none;
  border: 1px solid var(--primary-danger);
}

.text-area-v2 .textarea-error-message {
  color: var(--primary-danger);
  font-size: 11px;
  font-weight: 500;
  font-size: 0.6875rem;
  margin: 5px 0 0;
}

.text-area-v2 .credential-input-mandatory-astrix {
  color: var(--primary-danger);
}