.newsletter-card{
    background-color: white;
    padding: 30px;
    border: 1px solid #eef1f6;
    display: flex;
    border-radius: 6px;
    justify-content: space-between;
}

.newsletter-disclaimer-card {
    max-height: 70px;
    overflow: auto;
    margin-top: 30px;
    background-color: white;
    padding: 5px 10px 5px 30px;
    border: 1px solid #eef1f6;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    color: #98a0af;
    font-size: 12px;
    font-family: Poppins, sans-serif;
}

.newsletter-disclaimer-card::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: var(--primary-lightgreen);
    color: var(--primary-lightgreen);
}

.newsletter-disclaimer-card::-webkit-scrollbar {
    width: 5px;
    background-color: rgba(17, 221, 172, 0.2);
    border-radius: 5px;
}

.newsletter-disclaimer-card a {
    font-size: 12px;
    color: var(--primary-lightgreen);
}

.newsletter-card{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.newsletter-card p{
    color: var(--radiobutton-text-color);
    margin-bottom: 10px;
    font-size: 15px;
    font-family: Roboto;
}

.newsletter-card h5{
    color: #0D2C54;
    font-size: 16px;
    font-weight: 700;
    font-family: Roboto;
}
.newsletter-card h6{
    color: #0D2C54;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: Roboto;
}


.newsletter-card > .submitButton-container{
    min-width: fit-content !important;
}

.newsletter-card-title{
    font-size: 13px;
    font-weight: 600;
    color: var(--questionnaire-select-placeholder);
}

.newsletter-card-content-green{
    color: var( --primary-success);
    font-family: Roboto,sans-serif;
}

.newsletter-card-content-red{
    color: var(--primary-danger);
    font-family: Roboto,sans-serif;
}


@media (max-width:1200px){
    .newsletter-card{
        padding: 20px;
        flex-direction: column;
    }

    .newsletter-card-content-red,
    .newsletter-card-content-green{
        margin-bottom: 30px;
    }

    .newsletter-disclaimer-card{
        max-height: 100px;
        padding: 5px;
        padding-left: 30px;
    }
}