@import url('../../../../core.css');

.questionnaire-photoDiagnose-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0px;
    gap: 30px;
    background-color: transparent;
}

.questionnaire-photoDiagnose-title{
    font-family: 15px;
    font-weight: 700;
    color: var(--primary-blue);
}



.questionnaire-question-photoDiagnose-helpblock-container{
    font-size: 15px;
    font-family: Roboto,sans-serif;
    max-width: 98%;
    color: var(--primary-blue);
}

.questionnaire-question-photoDiagnose-headline{
    color: var(--primary-blue);
    font-size: 24px;
    margin-bottom: 10px;
}

.questionnaire-photoDiagnose-image-container{
    box-shadow: 0 43px 45px rgb(152 149 169 / 15%);;
    border-radius: 6px;
    background-color: var(--primary-white);
    padding: 20px 36px 40px;
    text-align: center;
}

.questionnaire-photoDiagnose-image-container > p{
    color: var(--primary-blue);
    font-family: Roboto,sans-serif;
    font-size: 16px;
    margin-top: 40px;
}



.questionnaire-photoDiganose-draganddrop-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% ;
    min-height: 46px; 
    text-align: center;
}


.questionnaire-photoDiganose-draganddrop{
    display: flex;
    align-items: center;
    background-color: var(--primary-gray);
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px dashed var(--sub-gray);
   
}

.questionnaire-photoDiagnose-cameralogo{
    margin-bottom: 10px;
}

.questionnaire-photoDiagnose-subtext{
    font-size: 12px;
    font-family: Roboto,sans-serif;
    color: var(--questionnaire-select-placeholder);
    margin-bottom: 5px;
}

.questionnaire-photoDiagnose-uploaded-file-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-white);
    margin: 10px 0px 0px;
    padding: 0px 15px 0px 0px;
    border: 1px solid #eef1f6;
    border-radius: 6px;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    min-height: 64px;
}

.questionnaire-photoDiagnose-uploaded-image{
    width: 64px;
    border-top-left-radius: 5px;
    object-fit: cover;
}


.questionnaire-photoDiagnose-uploaded-filename{
    color: var(--primary-blue);
    margin: 0px 20px;
    font-size: 16px;
    max-width: 230px;
    word-break: break-all;
    text-overflow: ellipsis;
    font-weight: 400;
}

.questionnaire-photoDiagnose-uploaded-logos{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.questionnaire-photoDiagnose-uploaded-cancel{
        height: 25px;
        width: 25px;
        margin-right: -5px;
        cursor: pointer;
}

.questionnaire-photoDiagnose-uploaded-activelogo{
   margin-top: 6px;
   transform: scale(1.2);
}

