@import url('../../core.css');

.questionnaire-select-dropdown-container{
    position: relative;
}

.questionnaire-select-dropdown-container label{
    font-size: 11px;
    position: absolute;
    top: 3px;
    left: 0;
    padding-left: 20px;
    padding-right: 10px;
    margin: 0;
    text-align: left;
    color: var(--questionnaire-select-placeholder);
    white-space: nowrap;
    overflow: hidden;
}

.legend {
    position: absolute;
    top : 2px;
    left : 20px;
    font-size: 11px;
    color: var(--questionnaire-select-placeholder);
}

.questionnaire-select-error-container,
.questionnaire-select-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    padding: 0 20px 0 20px ;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    color: var(--questionnaire-select-placeholder);
}

.questionnaire-select-error-container{
    background-color: var(--sub-danger);
    border: 1px solid var(--primary-danger);
    color: var(--primary-danger);
}


.questionnaire-select-placeholder{
    font-size: 13px;
    outline: none;
}

.questionnaire-selected-item-placeholder{
    font-family: Poppins,sans-serif;
    font-size: 14px;
    color: var(--primary-blue);
    font-weight: 500;
    max-width: 95%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    
}

.questionnaire-select-logo-down{
    color: #11ddac;
    font-size: 17px;
    transition:  .25s ease-in-out;
    
}

.questionnaire-select-logo-up{
    color: #11ddac;
    font-size: 17px;
    transition:  .25s ease-in-out;
    transform: rotate(180deg);
}



.questionnaire-select-options-container{
    background-color: white;
    position: absolute;
    z-index: 100;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-height: 235px;
    overflow-y: scroll;
    margin-top: -1px;
    border-radius: 3px;
    border: 1px solid rgb(212, 212, 212);
}


.questionnaire-select-options-container::-webkit-scrollbar{
    width: 6px;
    height: 3px;
}


.questionnaire-select-options-container::-webkit-scrollbar-thumb {
    /* background: var(--radiobutton-text-color); */
    background-color: var(--primary-lightgreen);
    border-radius: 10px;
  }



.questionnaire-select-options-container:empty{
    display: none;
}

.questionnaire-select-option{
    padding: 17px 20px;
    width: 100%;
    border-bottom: 1px solid #eef1f6;
}

.questionnaire-select-option:hover{
    background-color: #e7fcf7;
    cursor: pointer;
}

.questionnaire-select-option:hover > .questionnaire-select-option-text{
    color: var(--primary-blue);
}

.questionnaire-select-option-text{
    font-size: 14px;
    color: #817f85;
    font-weight: 500;
    word-break: break-word;
    overflow-wrap:break-word;
}




