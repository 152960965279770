
@import url("../../core.css");

.radio-select,
.radio-select-disabled {
  width: 18px;
  height: 18px;
  aspect-ratio: 1 !important;
  background-color: white;
  outline: 3px solid #eef1f6;
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.radio-select-selected {
  width: 18px;
  height: 18px;
  aspect-ratio: 1 !important;
  background-color: var(--primary-lightgreen);
  border: 3px solid var(--primary-white);
  outline: 2px solid var(--primary-lightgreen);
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.25s, outline 0.25s;
}

.radio-select-disabled {
  background-color: #eef1f6;
  color: #98a0af;
  outline: #eef1f6;
  cursor: not-allowed;
}
