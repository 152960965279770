@import url("../../../core.css");

.loginModal-background {
  position: fixed;
  z-index: 900;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--primary-blue);
  opacity: 0.98;
  display: flex;
  align-items: center;
  justify-content: center;

}

.loginModal {
  position: relative;
  border-radius: 6px;
  padding: 60px;
  width: 460px;
  z-index: 1001;
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
  flex-direction: column;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
}

.loginModal-title {
  font-size: 24px;
  color: var(--primary-blue);
  font-weight: 700;
  margin-bottom: 15px;
}

.loginModal-logo{
  margin-bottom: 30px;
  width: 48px;
  height: 48px;
}

.loginModal > a {
  text-align: center;
}

.loginModal-x{
  width: 20px;
  height: 20px;
  font-size: 10px;
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
}

.loginquestion-forgot-password:hover{
  text-decoration: underline;
  
}
