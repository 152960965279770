@import url("../../core.css");

.checkout-register-body {
  background-color: var(--primary-gray);
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 90px;
}

.checkout-register-form-and-order-container {
  display: flex;
  width: var(--desktop-col-10);
  flex-direction: row;
  justify-content: space-between;
}

.checkout-register-form-container {
  width: var(--desktop-col-6);
}

.checkout-register-headers {
  margin-bottom: 30px;
  width: var(--desktop-col-6);
}

.checkout-register-mainTitle {
  color: var(--primary-blue);
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 5px;
  overflow-y: initial;
}




.checkout-register-loginTitle {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  color: var(--radiobutton-text-color);
  white-space: pre;
  text-align: left;
  word-break: break-all;
  padding-right: 15px;
}

.checkout-register-loginTitle-loginhere {
  color: var(--primary-lightgreen);
  transition: all 0.25s ease-in;
}

.checkout-register-loginTitle-loginhere:hover {
  cursor: pointer;
  text-decoration: underline;
}

.orderWindow-container-upper,
.orderWindow-container-bottom {
  width: 330px;
  margin-bottom: 30px;
}

.orderWindow-container-upper {
  display: none;
}

/* Mobile */
@media (max-width: 767px) {
    .checkout-register-body {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 60px 0px 90px;
      }
    
      .checkout-register-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: var(--mobile-container-padding);
      }
    
      .checkout-register-form-and-order-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    
      .registration-form {
        width: 100% !important;
      }

      .registration-agreement{
        max-width: fit-content !important;
      }
    
      .registration-form-row {
        flex-direction: column;
        margin: 10px 0px !important;
       
      }
    
      .registration-form-row > .inputfield-container{
        margin: 0px !important;
      }
    
      .orderWindow-container-upper {
        display: inline-block;
        width: 100%;
      }

      .orderWindow-container-upper > .orderWindow-container{
        width: 100%;
      }
    
      .orderWindow-container-bottom {
        display: none;
      }


      
    
      .checkout-register-headers{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      .checkout-register-mainTitle{
        font-size: 16px;
        text-align: center;
      }
      .checkout-register-mainTitle > span{
        font-size: 14px;
        text-align: center;
      }
}

/* ---------End of Mobile rules--------- */

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .checkout-register-body {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 60px 0px 90px;
  }

  .checkout-register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: var(--tablet-container-padding);
  }

  .checkout-register-form-and-order-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .registration-form {
    width: var(--desktop-col-8) !important;
  }

  .registration-form-row {
    flex-direction: column;
    margin: 10px 0px !important;
   
  }

  .registration-form-row > .inputfield-container{
    margin: 0px !important;
  }

  .orderWindow-container-upper {
    display: inline-block;
  }

  .orderWindow-container-bottom {
    display: none;
  }

  .checkout-register-headers{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .registration-agreement-checkbox-container .checkbox-label {
    padding: 0 !important;
  }
}