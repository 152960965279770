.page-content-container .change-treatment-link {
  margin-top: 15px;
  margin-bottom: 25px;
  color: var(--radiobutton-text-color);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration: underline;
}

.page-content-container .change-treatment-link:hover {
  text-decoration: none;
  cursor: pointer;
}

.page-content-container .change-treatment-link:visited {
  color: var(--radiobutton-text-color);
}

.page-content-container h1 {
  color: var(--primary-blue);
  margin-bottom: 10px;
}

.page-content-container .treatment-plan-disclaimer {
  color: var(--radiobutton-text-color);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.page-content-container .treatment-plans-container {
  width: var(--desktop-col-8);
  display: flex;
  flex-direction: column;
}

.page-content-container .DrugSubscription-title {
  display: none;
}

.page-content-container .DrugSubscription-container {
  padding-top: 0;
  margin: 0;
  border: none;
  box-shadow: none;
}

.page-content-container .learn-more {
  width: fit-content;
}

.page-content-container .outer-sub-container {
  border: none;
}

.page-content-container .reorder-submit {
  align-self: center !important;
  margin-top: 30px;
}

.page-content-container #choose-treatment-reorder-significance {
  width: var(--desktop-col-8);
}

@media (max-width: 764px) {
  .page-content-container {
    padding: 30px 15px;
  }

  .page-content-container .reorder-product-card,
  .page-content-container .treatment-plans-container,
  .page-content-container #choose-treatment-reorder-significance {
    width: 100% !important;
  }

  .page-content-container h1 {
    font-size: 24px;
    text-align: center !important;
  }

  .page-content-container .treatment-plan-disclaimer {
    font-size: 14px;
  }
}
