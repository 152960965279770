@import url("../../../../core.css");

.delivery-options {
  display: flex;
  flex-direction: column;
}

.delivery-form-personal-address {
  background-color: white;
  border: none;
}

.delivery-panel-outer-container {
  background-color: white;
  display: flex;
  /* padding: 0 20px 20px; */
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  width: 100%;
}

.selected-pm {
  border: 1px solid #11ddac;
}
.hideable-content {
  padding: 20px 25px;
  border-top: 1px solid #dbe1eb;
}

.hidden {
  max-height: 0px;
  padding: 0;
  overflow-y: scroll;
  border-top: 0;
}

.address-as-billing {
  display: flex;
  font-size: 15px;
  font-family: Roboto, sans-serif;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.option-description {
  font-size: 14px;
  padding: 15px;

  color: #656d79;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option-description p {
  font-family: Roboto, sans-serif;
}

.delivery-form-personal-address .radio .radio-selected {
  width: 25px;
  height: 25px;
}

.delivery-form-personal-address .radio-selected {
  background-color: black;
  /* box-sizing: border-box; */
  outline: 1px solid black;
}

.delivery-options .delivery-options-title:first-child{
  margin-bottom: 30px;
}

.delivery-options-title {
  text-align: left;
  margin: 10px 0;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  color: #98a0af;
  font-weight: 700;
}

.name-fields,
.location-fields {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.option-content {
  display: flex;
  align-items: center;
}
