.prescription-row-container{
    padding: 20px 30px;
    list-style-type: none;
    color: var(--primary-blue) !important;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #dbe3eb;
    font-size: 15px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}


.prescription-row-title-container{
    padding: 20px 30px;
    color: var(--primary-blue);
    font-size: 18px;
    font-weight: 700;
}

.prescription-row-title-container  .prescription-question{
    color: var(--primary-blue);
    font-family: Poppins,sans-serif !important;
    font-size: 18px;
    font-weight: 700;
}

.prescription-question{
    color: var(--primary-blue);
    font-size: 15px;
    font-family: Roboto,sans-serif !important;
    word-break:keep-all;
    
}

.prescription-question p{
    color: var(--questionnaire-select-placeholder);
    font-size: 16px;
    font-family: Roboto,sans-serif !important;
    margin-bottom: 16px;
}

.prescription-row-container .prescription-question > div{
    font-size: 15px !important;
    font-family: Roboto,sans-serif !important;
    margin-bottom: 16px;
}

.prescription-row-container .questionnaire-info p,
.prescription-row-container .prescription-question > div > p{
    color: var(--radiobutton-text-color) !important;
}

.prescription-question ul li{
    font-family: Roboto,sans-serif !important;
    color: var(--radiobutton-text-color);
    font-size: 14px;
    margin-left: 20px;

}


.prescription-question .questionnaire-info-scroll {
    width: 100% !important;
    background-color: transparent !important;
}



.prescription-question .questionnaire-info p{
    font-size: 13px;
    font-family: Roboto,sans-serif;

}

.prescription-question .questionnaire-info p a{
    font-size: 13px;
    font-family: Roboto,sans-serif;
    text-decoration: underline;
    color: var(--primary-blue);
}


.prescription-answer-container{
    font-size: 13px;
    padding: 10px 30px;
    border: 1px solid #dbe1eb;
    border-radius: 3px;
    color: var(--questionnaire-select-placeholder);
    cursor: not-allowed;
    user-select: none;
}

@media (max-width: 1200px){
   

    .prescription-row-title-container  .prescription-question{
        font-size: 16px;
    }

    .prescription-row-container{
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }
    
    .prescription-row-title-container{
        padding: 20px;
    }

    .prescription-answer-container{
        width: 100%;
        text-align: center;
    }
}
