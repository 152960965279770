.account-order-details{
    border-radius: 6px;
    border: 1px solid #eef1f6;
    
}

.account-order-detail-row{
    background-color: white;
    padding: 14px 20px;
    display: flex;
    border-bottom: 1px solid #eef1f6;
    border-radius: 6px;
}


.account-order-detail-row-title,.account-order-detail-value-field{
    font-size: 13px;
    margin-right: 20px;
    color: var(--questionnaire-select-placeholder);
    min-width: 200px;
}

#space-between-row{
    justify-content: space-between;
}

.account-order-detail-row-value,
.account-order-detail-value-fieldvalue{
    font-size: 13px;
    margin-right: 20px;
    color: var(--radiobutton-text-color);
    font-family: Roboto,sans-serif;
}

.account-order-detail-value-fieldvalue{
    font-family: Poppins,sans-serif;
}

.account-order-detail-row ul li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
}

.account-order-detail-row ul:has( > #account-significance) li{
    justify-content: start;
}

.account-order-detail-row ul #detail-row-pay-now{
    margin: 20px 0px;
}


.account-order-notes{
    margin-top: 50px;
}
.account-order-notes h3{
    margin-bottom:15px;
    color:#0d2c54;
    font-size: 28px;
}
.account-order-notes h3+p{
    color:#0d2c54;
    font-size:16px;
    font-size:1rem
}
.account-order-notes p{
    font-size:15px;
    font-size:.9375rem;
    margin-bottom:30px;
    color: var(--radiobutton-text-color);
    font-family: Roboto,sans-serif;
}
.account-order-notes p:last-of-type{
    margin-bottom:0
}
.account-order-notes p a{
    font-family:Roboto,sans-serif;
    color: var(--primary-lightgreen);
}
.account-order-notes b{
    font-weight:500;
    font-size:16px;
    font-size:1rem;
    display:block;
    color:#0d2c54;
    margin-bottom:15px
}
.account-order-notes b+br{
    display:none
}

.account-order-notes #print-button{
    align-self: flex-start;
}

.account-order-notes .cancel-order{
    user-select: none;
    color: var(--primary-lightgreen);
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;

}

.account-order-notes .cancel-order:hover{
    text-decoration: none;
}

.account-order-notes .checkbox-list-container {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
}


.account-order-notes .checkbox-list-container .checkbox-label{
    margin-bottom: 0px;
}


.account-order-notes .checkbox-list-container > span {
    color: var(--primary-blue);
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 20px;

}


.print-button-container{
    width: fit-content;
    margin: 30px 0px;
}

.new-pharmacy-by-customer-modal-trigger{
    user-select: none;
    color: var(--primary-lightgreen);
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;

}

.leave-feedback h2{
    margin-top: 50px;
    margin-bottom: 10px;
}

.leave-feedback .stars{
    margin-bottom: 15px;
}

.leave-feedback textarea{
    margin-bottom: 10px;
}

.thanks-for-feedback{
    margin-top: 50px;
}

.thanks-for-feedback h1{
    margin-bottom: 15px;
}

@media print{
    .account-order-details{
        transform: scale(0.9);
        margin-top: -20px;
    }
    .account-asidebar-container{
        display: none;
    }

    .account-order-notes{
        display: none;
    }

    .account-order-details .sumbitButton-container {
        display: none;
    }

    .footer-container{
        display: none;
    }


}

@media (max-width: 1200px){
    .account-order-detail-row{
        padding: 20px;
        flex-direction: column;
    }

    .print-button-container{
        width: 100%;
    }

    #space-between-row{
        gap:5px;
    }

} 


@media (max-width: 600px){
    .account-order-detail-row #object-detail li{
        flex-direction: column;
        align-items: start;
        margin-bottom: 5px !important;
    }
}

