@import url('../../../../core.css');

.radio-button-error-container,
.radio-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;/* Green */
    border: 1px solid #98a0af;
    padding: 10px 30px ;
    min-height: 42px;
    min-width: 85px;
    border-radius: 3px;
    text-decoration: none;
    transition: 0.25s linear;
    cursor: pointer;
    font-size: 12px;
    font-family: Roboto,sans-serif;
    margin-left: 10px;
  
}

.radio-button-error-container{
  background-color: var(--sub-danger);
  border-color: var(--primary-danger);
  
}


.radio-button-container:hover{
    border: 1px solid var(--primary-lightgreen);
    background-color: var(--primary-lightgreen);
}

.radio-button-container:hover > .radio-button-text{
    color: var(--primary-white);
}

.radio-button-input{
    display: none;
}

.radio-button-input-selected{
    display: none;
}


.radio-button-input-selected .radio-button-text{
    color: var(--primary-white);
}

.radio-button-input-selected + label{
    border: 1px solid var(--primary-lightgreen);
    background-color:var(--primary-lightgreen);
}

.radio-button-input-selected + label > span {
    color: white;
}



.radio-button-input:checked + label{
    border: 1px solid var(--primary-lightgreen);
    background-color: var(--primary-lightgreen);

}

.radio-button-input-selected + label > .radio-button-text
.radio-button-input:checked + label > .radio-button-text{
    color: var(--primary-white);
}

.radio-button-error-container > .radio-button-text{
    color: var(--primary-danger);
}

.radio-button-text{
    font-weight: 500;
    color: var(--radiobutton-text-color);
}


@media (max-width : 748px){
    .radio-button-error-container,
    .radio-button-container{
    width: max-content !important;
}
}


