@import url('../../../../core.css');


.significance-container{
    padding: 15px;
    width: 100%;
    /* align-self: center; */
    border-radius: 5px;
    position:relative;
    margin:25px 0;
}

.significance-container > #significance{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: .9375rem;
    font-family: Roboto,sans-serif;
}

#significance-icon-and-text{
    display: flex;
    font-size: .9375rem;
    align-items: center;
}


.significance-container > div > span{
    font-size: .9375rem;
    font-family: Roboto,sans-serif;
}

.significance-alert-icon{
    font-size: 18px ;
    margin-right: 10px;
    margin-top: 2px;
}

.significance-container a{
    color: var(--primary-lightgreen);
}

.significance-container a:hover{
    cursor: pointer;
}

#significance-show-clickable{
    position: absolute;
    right: 10px;
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
}


#significance-expanded-description{
    padding-left: 10px;
    margin-top: 5px;
    font-size: 14px;
    font-family: Roboto,sans-serif !important;

}

@media screen and (max-width: 768px) {
    .significance-container{
        padding: 10px;
    }

    #significance-show-clickable{
        font-size: 10px;
        margin-top: 5px;
    }

    #significance-text{
        font-size: 14px;
    }

    .significance-alert-icon{
        margin-right: 2px;
    }

}