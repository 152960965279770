.badge-notifications{
    background-color: var(--primary-danger);
    background: #ef4747;
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 100%;
    color: var(--primary-white) !important;
    font-family: Roboto,sans-serif;
    font-weight: 700;
    font-size: 12px !important;
    margin-left: 8px;
}