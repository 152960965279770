.questionnaire-defaults-container{
  padding: 0px 30px 30px;
}

/* OVERRIDE QUESTIONNAIRE PAGE STYLING */

.questionnaire-defaults-container .emojiblock-container,
.questionnaire-defaults-container .emojiblock-title,
.questionnaire-defaults-container .emojiblock-title,
.questionnaire-defaults-container .header-container,
.questionnaire-defaults-container .header-links,
.questionnaire-defaults-container .stepslist-container,
.questionnaire-defaults-container .stepslist-container-mobile,
.questionnaire-defaults-container .stepslist-progress-bar,
.questionnaire-defaults-container .questionnaire-title,
.questionnaire-defaults-container .footer-container,
.questionnaire-defaults-container .emojiblock-discount-notification{
    display: none;
}

.questionnaire-defaults-container .questionnaire-questions-group-headline{
    padding: 30px;
}

.questionnaire-defaults-container .questionnaire-body-container{
    padding: 0px;
    background-color: transparent;
}

.questionnaire-defaults-container .questionnaire-treatment-form-container{
    width: 100%;
    padding: 0;
}

.questionnaire-defaults-container .questionnaire-questions-group-headline{
    padding: 20px 0px;
    font-size: 18px;
}

.questionnaire-defaults-container .questionnaire-question-item{
    gap: 5px;
}

.questionnaire-defaults-container #submit-button{
    align-self: flex-end !important;
  }

/*END OVERRIDE QUESTIONNAIRE PAGE STYLING */

@media (max-width: 1200px){
  .questionnaire-defaults-container{
      padding: 0px
  }

  .questionnaire-defaults-container #submit-button{
    width: 100% !important;
  }

  .questionnaire-defaults-container .questionnaire-question-item{
    gap: 0px;
  }
  
}