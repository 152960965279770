.modal-background {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(24, 39, 59, 0.995);
    position: fixed;
    z-index: 400;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: var(--tablet-container-padding);
}



.modal-popup-container{
    display: flex;
    flex-direction: column;
    width: 460px;
    position: relative;
    padding: 60px;
    background-color: white;
    text-align: center;
    border-radius: 6px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.modal-popup-container .close-btn{
    position: absolute;
    right: 25px;
    top: 25px;
    width: 20px;
    cursor: pointer;
}

.modal-popup-container > h6 {
    font-size: 18px;
    color: var(--primary-blue);
}

.modal-popup-container > h4 {
    font-family: Roboto,sans-serif;
    font-size: 20px;
    color: var(--primary-blue);
}

.modal-popup-container > p{
    font-size: 15px;
    color: var(--radiobutton-text-color);
    font-family: Roboto,sans-serif;
    margin-bottom: 30px;
}

.modal-popup-container > span {
    font-size: 12px;
    color: var(--primary-lightgreen);
    cursor: pointer;
}

.modal-popup-container > span:hover{
    text-decoration: underline;
}

