@import url('../../../core.css');


.dark-loading-container{
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--primary-blue);
    opacity: 0.98;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .skip-questionnaire-card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 691px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 10px 80px 80px 80px;
  }

  .inner-card{
    display: flex;
    text-align: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 25px;
  }

  .skip-questionnaire-card h2{
    font-family: Roboto;
    font-size: 24px;
  }

  .buttons-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .outlined{
    background: rgba(0, 0, 0, 0);
    border: #98A0AF 1px solid;
    width: 100%;
  }

  .outlined:hover{
    border: none;
  }
  
  .outlined:hover .button-placeholder{
    color: white;
  }
  
  .outlined .button-placeholder{
    color: #98A0AF;
  }


  @media (max-width: 767px) {
    .skip-questionnaire-card {
      width: 385px;
      padding: 10px 20px 30px 20px;
    }
  }