.thanks-for-feedback{
  color: #0D2C54;
}
.thanks-for-feedback h2{
  margin-bottom: 24px;
}

.green-checkmark{
  width: 100%;
  height: 110px;
  background-color: #22B491;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.checkmark1{
  color: white;
  position: absolute;
}

.checkmark2{
  position: relative;
  color:  #26C29D;
  right: 10px;
}

.thanks-message{
  /* height: 120px; */
  position: relative;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 120px;
}
.thanks-message p{
  max-width: 530px;
  align-self: center;
  margin-top: 12px;
  color: #656D79;
}

.thanks-message a{
  margin-top: 12px;
  font-size: 12px;
  width: fit-content;
  align-self: center;
}