@import url('../../../core.css');

.SelectTreatmentLandingPage-inner-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--primary-gray);
  width: var(--desktop-column-12);
  min-height: 100vh;
}

.SelectTreatmentLandingPage-inner-container .right-panel {
  background-color: #fff;
  padding: 25px;
}

.SelectTreatmentLandingPage-inner-container  .products-containter::-webkit-scrollbar {
  display: none;
}

.SelectTreatmentLandingPage-inner-container  .DrugChoicePanel {
 margin-right: 5px;
}


.SelectTreatmentLandingPage-container {
  width: 100%;
  background-color:#f7f7f7; 
  padding: 60px 0px 90px;
  
}

.DrugChoicePanel {
  max-width: 100%;
  justify-self: center;
}

.SelectTreatmentLandingPage-inner-container .key-points{
  padding-left: 25px;
  margin-left: -5px;
}

.SelectTreatmentLandingPage-inner-container .key-points > li{
  font-family: Roboto,sans-serif !important;
  font-size: 15px;
 
}

.SelectTreatmentLandingPage-inner-container .choose-a-pack{
  margin-bottom: 10px;
}


.SelectTreatmentLandingPage-inner-container
.left-panel {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--desktop-col-6);
}

.treatment-options-title {
  margin-bottom: 15px;
  color: var(--primary-blue);
}


@media (max-width: 767px){
  .SelectTreatmentLandingPage-container .treatment-options-title{
    font-size: 24px !important;
    
    
  }

  .SelectTreatmentLandingPage-container .left-panel{
    padding: 0 15px;
  }

  .SelectTreatmentLandingPage-container .coupon{
    align-items: center !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .SelectTreatmentLandingPage-container .coupon{
    align-items: center;
  }

  .SelectTreatmentLandingPage-container 
  .left-panel {
    width: 100%;
    padding: 0 15px;
    margin: 0;
    width: var(--desktop-column-12) !important;
  }

  .DrugChoicePanel {
    max-width: 706px;
    
  }
}


