.DrugSubscription-title {
  color: var(--primary-blue);
  font-size: 14px;
  font-family: Poppins, sans-serif;
  /* margin-left : 12px; */
}

.outer-sub-container {
  width: 100%;
}

.DrugSubscription-container {
  box-shadow: inset 0 1px 0 rgb(0 0 0 / 8%);
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin: 15px 0px 0px 0px;
  padding-bottom: 10px;
}

.learn-more {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  color: #11ddac;
  margin-top: 20px;
  text-decoration: underline;
}

.learn-more:hover {
  cursor: pointer;
  text-decoration: none;
}
