.under-maintenance-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0D2C54;
    background-color: #fff;
    font-weight: 400;
    width: 100%;
    font-size: 1rem;
    line-height: 1.25;
    min-width: 320px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 50px 150px;
    overflow: hidden;
    height: 100vh;
  }

.under-maintenance-body img{
    align-self: flex-start;
}


.under-maintenance-body h1 {
    font-size: 50px;
    font-family: Arial, sans-serif !important;
    margin: 40px 0px;
}

.under-maintenance-body h2{
    font-family: Arial, sans-serif !important;
    font-size: 30px;
    margin-bottom: 25px;
}

.under-maintenance-body article {
    display: grid;
    text-align: left;
    vertical-align: middle;
    width: 650px;
    margin: 0 12px;
    line-height: 2rem;
    padding: 30px 0px;
  }


  .under-maintenance-body article p {
   font-size: 20px;
   font-family: Arial, sans-serif !important;
   margin: 20px 0px;
    
  }

.under-maintenance-body a {
    color: #11DDAC;
    text-decoration: underline;
    font-family: Arial, sans-serif !important;
  }

.under-maintenance-body a:hover {
    text-decoration: none;
    font-family: Arial, sans-serif !important;
  }

.under-maintenance-body .logo {
    width: 200px;
    display: block;
  }

.under-maintenance-body ul {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

.under-maintenance-body .footer--copyright {
    display: block;
    font-size: 0.875rem;
    color: #7f7f7f;
    font-family: 'Arial', sans-serif;
    -webkit-box-direction: normal;
    line-height: 1.25;
    margin: 200px auto;

}



@media (max-width:764px){
    .under-maintenance-body{
        padding: 50px 20px;
    }
    .under-maintenance-body article{
        width: 100%;
        transform: scale(0.9);
    }

    .footer--copyright{
        margin: 0 !important;
    }
}