@import url('../../core.css');

.searchbar-form{
    width: var(--desktop-col-12);
    background-color: white;
    position: relative;
    height: 80px;
    padding: 10px;
    display: flex;
    border-radius: 6px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.searchbar-button{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-lightgreen);
    width: 46px;
    height: 46px;
    border-radius: 6px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
}

.searchbar-button:hover {
    transition: all 250ms ease;
    background-color: #0ebc92;
}

.searchbar-input{
    width: 100%;
    outline: none;
    border: none;
    padding: 0px 90px 0px 20px;
    color: var(--primary-blue);
    font-weight: 500;
    font-size: 16px;
    caret-color: var(--primary-lightgreen);
}

.searchbar-input::placeholder{
    font-size: 18px;
    color: rgb(154, 154, 154);
    font-family: sans-serif;
}


@media (max-width: 1200px){
    .searchbar-form{
        width: 100%;
    }

    .searchbar-input{
        font-size: 14px;
    }

    .searchbar-input::placeholder{
        font-size: 16px;
    }
}