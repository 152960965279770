.account-content-header-title {
  font-size: 32px;
  color: var(--primary-blue);
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

.account-content-header-description {
  position: relative;
  color: var(--radiobutton-text-color);
  margin-bottom: 30px;
  font-size: 14px;
}

.account-content-header-description > div {
  display: inline-block;
  font-family: Roboto, sans-serif !important;
}

.account-content-header-description span {
  color: var(--primary-lightgreen);
  cursor: pointer;
}

.account-content-header-description span:hover {
  text-decoration: underline;
}

.account-content-header-description a {
  color: var(--primary-lightgreen);
}

/* Loader 2 */
.account-content-header .loader-2 {
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 5px;
  margin-left: 5px;
  -webkit-animation: loader-2-1 3s linear infinite;
  animation: loader-2-1 3s linear infinite;
}
@-webkit-keyframes loader-2-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.account-content-header .loader-2 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 18px;
  width: 18px;
  clip: rect(8px, 18px, 18px, 0);
  -webkit-animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-2-2 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.account-content-header .loader-2 span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 18px;
  width: 18px;
  border: 3px solid transparent;
  border-top: 3px solid #11ddac;
  border-radius: 50%;
  -webkit-animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-2-3 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.account-content-header .loader-2 span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 18px;
  width: 18px;
  border: 3px solid #11ddac;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .account-content-header-title {
    font-size: 24px;
  }
}
