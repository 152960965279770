@import url("../../core.css");

/* Cookie banner Preferences */
  .cookiePreferencesModal-background {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(24, 53, 90, 0.98);
    height: 100%;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: var(--tablet-container-padding);
  }

  .content {
    padding: 30px;
    overflow-y: scroll;
    max-height: 500px;
  }

  .cookiePreferencesModal-card {
    background-color: var(--primary-white) !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 30px;
    box-shadow: 0px 60px 120px rgba(0, 0, 0, 0.15);
    opacity: 1 !important;
    max-height: 700px;
    position: relative;
    width: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 30px;
  }

  #title {
    font-size: 24px;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    color: var(--primary-blue);
    align-self: flex-start;
    margin-bottom: 20px;
  }

  #close-button{
    font-size: 16px;
    color: #99a0af;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  #close-button:hover {
    cursor: pointer;
  }

  #text {
    font-size: 14px;
    color: var(--radiobutton-text-color);
    font-family: Roboto, sans-serif;
  }

  #link {
    font-size: 14px;
    color: var(--radiobutton-text-color);
    font-family: Roboto, sans-serif;
    text-decoration: underline;
  }

  #link:hover {
    text-decoration: none;
  }

  .frame-table {
    margin-top: 30px;
    border-collapse: collapse;
    border: 1px solid #e7eaf1;
    width: 100%;
    color: var(--radiobutton-text-color);
    font-size: 12px;
    text-align: left;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    overflow-x:auto;
  }
  
  .frame-table td, .frame-table th {
    border-bottom: 1px solid #e7eaf1;
    padding: 8px;
  }
  
  .frame-table th {
    border-top: 1px solid #e7eaf1;
    background-color: #f7f7f7;
    font-weight: 400;
  }

  .frame-table .explanation {
    font-weight: 400;
  }

  .frame-table .allow-cell {
    display: flex;
    justify-content: flex-start;
  }

  .frame-table td {
    color: var(--radiobutton-text-color);
    font-size: 12px;
    text-align: left;
    font-weight: 600;
    font-family: Roboto, sans-serif;
  }

  #footer-banner {
    background-color: #e9fcf7;
    height: 100px;
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content::-webkit-scrollbar{
    width: 6px;
    height: 3px;
}


.content::-webkit-scrollbar-thumb {
    background-color: var(--primary-lightgreen);
    border-radius: 10px;
  }


/* Cookie banner */

.cookieAcceptBar {
    position:sticky;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    background-color: var(--primary-white) !important;
    border-radius: 6px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0px 60px 120px rgba(0, 0, 0, 0.15);
    opacity: 1 !important;
    padding: 30px;
    margin: auto;
    max-width: 540px;
    min-height: 276px;
    top: 50%;
    bottom: auto !important;
    transform: translateY(-50%);
    z-index: 1000 !important;
  }

  .cookieAcceptBar.white {
    background-color: #fff !important;
    top: auto;
    transform: none;
    bottom: 98px !important;
    overflow: hidden;
    max-width: 878px;
    min-height: auto;
    overflow-y: hidden !important;
  }
  
  .cookieAcceptBar .row {
    flex-direction: column;
    display: flex;
  }
  
  .cookieAcceptBar .col-12 {
    padding: 0;
    max-width: 100%;
    flex: auto;
  }
  
  .cookieAcceptBar .agreement-info .title {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-blue);
    margin-bottom: 10px;
  }
  
  .cookieAcceptBar .agreement-info p {
    font-family: Roboto, sans-serif;
    font-size: 13px !important;
    line-height: 1.5 !important;
    color: var(--primary-white);
  }
  
  .cookieAcceptBar .agreement-info a {
    color: var(--radiobutton-text-color) !important;
    font-weight: normal !important;
    display: inline;
    text-decoration: underline;
    font-family: Roboto, sans-serif;
  }

  .cookieAcceptBar .agreement-info a:hover {
    text-decoration: none;
  }
  
  .cookieAcceptBar .buttons {
    display: flex;
  }
  
  .cookieAcceptBar .buttons>* {
    width: 100%;
    min-width: auto;
    max-width: inherit !important;
  }
  
  .cookieAcceptBar .manage-preferences {
    font-size: 13px;
    line-height: 40px;
    padding: 0 20px;
    min-width: 200px;
    text-decoration: underline;
    font-weight: 300;
    font-family: Roboto, sans-serif;
    align-self: center !important;
    color: var( --radiobutton-text-color);
    text-align: center;
    margin: 0 10px 0 0;
  }
  
  .cookieAcceptBar .manage-preferences.desktop {
    display: block;
  }
  
  .cookieAcceptBar .manage-preferences.mobile {
    display: none;
  }

  .cookieAcceptBar .manage-preferences:hover {
    text-decoration: none;
    cursor: pointer;
  }
  
  .cookieAcceptBar .btn-accept-cookies
  {
    margin: 0;
    min-width: 215px;
    font-size: 14px;
    line-height: 44px;
    padding: 0 20px;
    background-color: var(--primary-lightgreen);
    color: var(--primary-white);
    border-radius: 3px;
    border-style: none; 
    font-weight: 700;
    width: 100%;
    transition-duration: 0.4s;
    white-space: nowrap; 
  }

  .cookieAcceptBar .btn-accept-cookies:hover {
    background-color: #00ca94; 
    cursor: pointer;
  }
  
  .cookieAcceptBar .refuse-btn-container {
    margin-top: 15px;
  }
  
  .cookieAcceptBar .refuse-btn-container a {
    font-size: 13px;
    font-weight: 400 !important;
    color: var(--sub-gray) !important;
    text-align: center;
    text-decoration-line: underline;
  }
  
  .cookieAcceptBar.white .row {
    flex-direction: row;
    align-items: center;
  }
  
  .cookieAcceptBar.white .agreement-info {
    font-size: 14px !important;
    font-family: Roboto, sans-serif;
    line-height: 1.5 !important;
    color: var(--radiobutton-text-color) !important;
    padding-right: 50px;
    justify-self: end;
  }



  @media only screen and (max-width: 767px) {
    .cookieAcceptBar .row {
      flex-direction: column;
    }
    .cookieAcceptBar.white {
      max-width: 350px;
      box-shadow: 0px 30px 120px rgba(2, 2, 2, 0.27);
      max-height: 95vh;
      overflow-y: auto;
      align-items: flex-start;
    }
    .cookieAcceptBar.white .row {
      flex-direction: column;
    }
    .cookieAcceptBar.white .col-12 {
      flex: 0 0 100%;
    }
    .cookieAcceptBar.white .agreement-info {
      margin-bottom: 15px;
      margin-right: 0;
      text-shadow: none;
      font-size: 12px;
      /* line-height: 1.5; */
      padding-right: 0px;
    }
    .cookieAcceptBar.white .agreement-info .title {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 5px;
    }
  }
  
