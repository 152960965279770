#shipping_country{
    width: 100%;
}

.phone-icon {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.phone-icon-text {
    font-size: 13px;
    font-family: Roboto, sans-serif;
    max-width: 85%;
}


.phone-icon-and-field {
    display: flex;
    color: #98a0af;
    gap: 15px;
}

.telephone {
    font-size: 25px;
}

.address-as-billing-text {
    font-size: 14px;
    font-family: Roboto, sans-serif;
    color: var(--primary-blue);
    font-weight: 400;
}

.address-as-billing{
    margin: 10px 0px 20px 0px;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center !important;
}

.address-as-billing > p {
    margin-right: 5px;
}