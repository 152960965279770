.badge-new{
    padding: 2px 12px 0;
    min-height: 19px;
    background-color: #ef4747;
    border-radius: 20px;
    font-size: 11px;
    font-size: .6875rem;
    margin-left: 10px;
    font-family: Roboto,sans-serif;
    color: white;
    font-weight: 700;
}